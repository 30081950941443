import Axios from "axios";
import { saveAs } from "file-saver";
import { createAxios } from "@educhain/utils/src/services";

const baseURL = process.env.REACT_APP_API_URL;

const config = {
    baseURL
};

function download(response, prefix = 'credentials.') {
    let filename;
    const type = response.headers["content-type"];
    const disposition = response.headers["content-disposition"];

    if (disposition && disposition.indexOf("attachment") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);

        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
        }
    } else {
        filename = `${prefix}${type.split("/")[1]}`;
    }

    const blob = new Blob([response.data], {
        type: response.headers["content-type"]
    });

    saveAs(blob, filename);
}

const axios = createAxios(config, "tokens");

const service = {};

// auth
const login = data => axios.post("login_check", data);
const refreshToken = data => axios.post("token/refresh", data);

// user
const getUser = () => axios.get("users/profile");
const deleteImage = () => axios.delete("users/settings/picture");
const uploadImage = data => {
    const formData = new FormData();

    formData.append("logoFile", data.file);
    formData.append("logoFileCropBase64Data", data.base64);

    return axios.post("users/settings/picture", formData);
};
const changePassword = (
    currentPassword,
    newPassword,
    newPasswordConfirmation
) =>
    axios.post("users/password/change", {
        current_password: currentPassword,
        plainPassword: {
            first: newPassword,
            second: newPasswordConfirmation
        }
    });
const changeEmail = (
    newEmail,
    newEmailConfirmation,
    locale
) =>
    axios.post(`users/email/change?resetConfirmationUrl=${window.location.origin}/${locale}/email/reset/{token}`, {
        emailRequested: {
            first: newEmail,
            second: newEmailConfirmation
        }
    });

const switchAccount = (username) => axios.post(`users/switch/${username}`);

// notifications
const getNotifications = () => axios.get("notifications/");
const markNotificationAsViewed = notificationId =>
    axios.post(`notifications/${notificationId}/view`);

// credentials
const getCredentials = (category) => axios.get("credentials/", {
    params: { category }
});
const getCredentialBlob = credentialId =>
    axios.get(`credentials/${credentialId}/blob`);
const downloadCredentials = ids =>
    axios
        .get(`credentials/download?ids[]=${ids.join(",")}`, {
            ...config,
            responseType: "blob",
        })
        .then(response => {
            download(response);
        });

// media files
const getMediaFiles = (category) => axios.get("media-files/", { params: { category } });

// sharing
// non-public
const getSharings = params => axios.get("credentials/sharings", { params });
const createSharing = data => axios.post("credentials/sharing/create", data);
const deleteSharing = sharingId =>
    axios.delete(`credentials/sharings/${sharingId}`);
const getCreateSharingIsAllowed = () =>
    axios.get("credentials/sharing/create-is-allowed");


const forgotPassword = (username, locale) => {
    return Axios.post(
        "resetting/send-email",
        {
            username,
            reset_confirmation_url: `${window.location.origin}/${locale}/resetting/reset/{token}`,
        },
        config
    ).then(res => {
        return res;
    });
};

const checkPasswordResettingToken = (token) => {
    return Axios.get(
        `resetting/reset/${token}`,
        {
            ...config,
            data: {},
            headers: {
                'Content-Type': 'application/json',
            }
        }
    ).then(response => {
        return response.status === 200;
    });
};

const resetEmail = (token) => {
    return Axios.post(
        `users/email/reset/${token}`,
        {},
        config
    ).then(response => {
        return response.data;
    });
};

const resetPassword = (token, newPassword, newPasswordConfirmation) => {
    return Axios.post(
        `resetting/reset/${token}`,
      {
            plainPassword: {
                first: newPassword,
                second: newPasswordConfirmation,
            },
        },
        config
    ).then(response => response);
};

service.login = login;
service.refreshToken = refreshToken;
service.getCredentials = getCredentials;
service.getCredentialBlob = getCredentialBlob;
service.getUser = getUser;
service.getNotifications = getNotifications;

export {
    login,
    getCredentials,
    getMediaFiles,
    getUser,
    getNotifications,
    markNotificationAsViewed,
    getCredentialBlob,
    getSharings,
    createSharing,
    deleteSharing,
    downloadCredentials,
    uploadImage,
    deleteImage,
    changePassword,
    changeEmail,
    refreshToken,
    getCreateSharingIsAllowed,
    forgotPassword,
    checkPasswordResettingToken,
    resetPassword,
    resetEmail,
    switchAccount
};

export default service;
