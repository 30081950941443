import { ALERT_PREFIX, ALERT_CLEAR } from './constants';

const initialState = {
    type: undefined,
    message: undefined
};
export const alertReducer = (state = initialState, { type, message }) => {
    if (type.includes(ALERT_PREFIX)) {
        return { ...state, type: type.replace(ALERT_PREFIX, ''), message };
    }

    switch (type) {
        case ALERT_CLEAR:
            return {};
        default:
            return state;
    }
};
