import React, { Component } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Form } from "antd";
import "@ant-design/compatible/assets/index.css";
import { message, Button, Input, Spin } from "antd";
import { get } from "lodash";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";

const ButtonWrapper = styled.div`
    margin-bottom: 15px;

    button {
        + button {
            margin-left: 15px;
        }
    }
`;

const InlineButtonWrapper = styled.div`
    display: inline-block;

    + div {
        margin-left: 15px;
    }
`;

class ChangePassword extends Component {
    state = {
        showForm: false
    };

    componentDidUpdate(prevProps) {
        const { passwordChanged } = this.props;
        //TODO: AR translation
        if (prevProps.passwordChanged !== passwordChanged && passwordChanged) {
            message.success("Password successfully changed");

            this.setState({
                showForm: false
            });
        }
    }

    toggleForm = () => {
        this.setState(({ showForm }) => {
            return {
                showForm: !showForm
            };
        });
    };

    render() {
        const {
            intl,
            passwordChanging,
            passwordChangingError,
            currentPassword,
            newPassword,
            newPasswordConfirmation,
            onChange,
            onSubmit
        } = this.props;

        const { showForm } = this.state;
        const errors = passwordChangingError
            ? get(passwordChangingError, "response.data.errors")
            : [];

        return (
            <>
                {showForm && (
                    <Form
                        layout="vertical"
                        style={{ marginTop: 24 }}
                        initialValues={{
                            currentPassword,
                            newPassword,
                            newPasswordConfirmation
                        }}
                        onFinish={onSubmit}
                        onValuesChange={(fields) => {
                            onChange(fields);
                        }}
                    >
                        <Form.Item
                            name="currentPassword"
                            hasFeedback
                            label={
                                <FormattedMessage id="profile.oldPassword" />
                            }
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: 'profile.oldPasswordRequired' })
                                }
                            ]}
                        >
                            <Input
                                type="password"
                                autoComplete="new-password"
                                disabled={passwordChanging}
                            />
                        </Form.Item>
                        <Form.Item
                            name="newPassword"
                            hasFeedback
                            label={
                                <FormattedMessage id="profile.newPassword" />
                            }
                            rules={[
                                () => ({
                                    validator(_, value) {
                                        const isValid = /^(?=.*[A-z])(?=.*[0-9]).{8,16}$/.test(
                                            value
                                        );

                                        if (value && !isValid) {
                                            return Promise.reject(new Error(
                                                intl.formatMessage({ id: 'shared.passwordRequirement' })
                                            ));
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: 'profile.newPasswordRequired' }),
                                }
                            ]}
                        >
                            <Input
                                type="password"
                                autoComplete="new-password"
                                disabled={passwordChanging}
                            />
                        </Form.Item>
                        <Form.Item
                            name="newPasswordConfirmation"
                            dependencies={["newPassword"]}
                            hasFeedback
                            label={
                                <FormattedMessage id="profile.confirmNewPassword" />
                            }
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error(
                                            intl.formatMessage({ id: 'profile.newPasswordMatch' })
                                        ));
                                    },
                                }),
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: 'profile.newPasswordMatch' })
                                }
                            ]}
                        >
                            <Input
                                type="password"
                                autoComplete="new-password"
                                disabled={passwordChanging}
                            />
                        </Form.Item>
                        <Form.Item>
                            <ButtonWrapper>
                                {errors && Object.keys(errors).length > 0 && (
                                    <div className="ant-row ant-form-item has-error">
                                        {Object.keys(errors).map(field => {
                                            const fieldErrors = errors[field];

                                            return fieldErrors.map(error => {
                                                return (
                                                    <div
                                                        key={`${field}${error}`}
                                                        className="ant-typography ant-typography-danger"
                                                    >
                                                        {error}
                                                    </div>
                                                );
                                            });
                                        })}
                                    </div>
                                )}

                                <Button
                                    onClick={this.toggleForm}
                                    disabled={passwordChanging}
                                >
                                    <FormattedMessage id="shared.cancel" />
                                </Button>

                                <Button
                                    htmlType="submit"
                                    disabled={passwordChanging}
                                >
                                    <FormattedMessage id="shared.apply" />
                                    &nbsp;
                                    {passwordChanging && (
                                        <Spin
                                            indicator={
                                                <LoadingOutlined
                                                    style={{ fontSize: 14 }}
                                                    spin
                                                />
                                            }
                                        />
                                    )}
                                </Button>
                            </ButtonWrapper>
                        </Form.Item>
                    </Form>
                )}
                {!showForm && (
                    <InlineButtonWrapper>
                        <Button
                            onClick={this.toggleForm}
                            disabled={passwordChanging}
                        >
                            <FormattedMessage id="shared.changePassword" />
                        </Button>
                    </InlineButtonWrapper>
                )}
            </>
        );
    }
}

export default injectIntl(ChangePassword);
