import React from "react";
import styled from "styled-components";
import { getImagePath } from "@educhain/utils/theme/getImagePath";

const Logo = styled.div`
    height: 100px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;

    img {
        margin: auto;
        height: 30px;
        width: auto;
    }

    img:first-child {
        padding-right: 25px;
    }

    .signature {
        height: 73px;
        width: auto;
        @media only screen and (max-width: 1150px) {
            display: none;
        }
    }
`;

const Nav = styled.nav`
    position: fixed;
    width: 100%;
    background: white;
    top: 0px;
    z-index: 10;

    @media only screen and (max-width: 1020px) {
        position: relative;
        margin-bottom: 0;
    }

    .nav-wrapper {
        // max-width: 80%;
        margin: auto;
        width: 1404px;
        max-width: 92.6%;
        padding-left: 15px;
        padding-right: 15px;

        @media only screen and (min-width: 1025px) {
            width: 1360px;
            max-width: 97%;
        }
    }
`;

const NavContent = styled.div`
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    background: white;
`;

const PageHeader = styled.h1`
    color: rgb(237, 28, 36) !important;
    text-align: right;
    font-size: 2em !important;
    text-transform: uppercase;
    font-family: Oswald, Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    line-height: 1.1;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0px;
`;

const RedBar = styled.div`
    width: 100%;
    background: ${({ theme: { colors } }) => colors.primaryDark};
    height: 50px;
    letter-spacing: 0.05em;

    @media only screen and (max-width: 1020px) {
        height: 0;
    }
`;
//TODO: fix theme provider here and switch out strings
const Navbar = ({ header = null }) => (
    <Nav>
        <div className="nav-wrapper">
            <NavContent>
                <a href="https://uaeu.ac.ae/en/">
                    <Logo>
                        <img src={getImagePath("uaeu.png")} alt="" />
                    </Logo>
                </a>
                <Logo>
                    <img
                        className="signature"
                        src={getImagePath("uaeu_signature.png")}
                        alt=""
                    />
                </Logo>
                {header && <PageHeader>CREDENTIAL PORTAL</PageHeader>}
            </NavContent>
        </div>
        <RedBar />
    </Nav>
);

export default Navbar;
