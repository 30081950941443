export function isHasPlainLogin() {
    return !process.env.REACT_APP_PLAIN_LOGIN || process.env.REACT_APP_PLAIN_LOGIN.toLowerCase() === 'true';
}

export function getSSOLoginUrl() {
    if (!process.env.REACT_APP_SSO_LOGIN || process.env.REACT_APP_SSO_LOGIN.toLowerCase() === 'false') {
        return null;
    }

    return process.env.REACT_APP_SSO_LOGIN;
}
