import React from "react";
import { injectIntl } from "react-intl";
import styled, { withTheme } from "styled-components";
import PublicLayout from "../../components/PublicLayout";
import { getImagePath } from "@educhain/utils/theme/getImagePath";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import { Spin } from "antd";

const Logo = styled.div`
    text-align: center;
    margin: 15px;
    img {
        height: 60px;

        @media (min-width: 768px) {
            height: 75px;
        }
    }
`;

const LoginWrapper = styled.div`
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    margin: auto;

    h1 {
        font-size: 2.15em;
    }
`;

const StyledLoginForm = styled.div`
    padding: 20px 24px;
    .ant-form-item-control-input-content {
        text-align: center;
    }

    .login-form-button {
        width: 150px;
        margin-top: 10px;
    }

    .ant-input {
        border: none;
        border-bottom: 1px solid #d9d9d9;
        border-radius: 0px;
        width: 300px;
    }

    .ant-input:hover {
        border-color: #5baaf5;
        border-right-width: 1px !important;
    }

    .ant-input:focus,
    .ant-input-focused {
        border-color: #5baaf5;
        border-right-width: 1px !important;
        outline: 0;
        box-shadow: 0 4px 2px -2px rgba(49, 138, 233, 0.2);
    }
`;
const StyledMessage = styled.div`
    margin: 50px 0;
`;

class ForgotPasswordView extends React.Component {
    state = { loading: false };

    handleLoading = value => {
        this.setState({ loading: value });
    };

    render() {
        const { theme, intl } = this.props;
        const { loading } = this.state;

        const content = !!theme.ldap ? (
            <StyledMessage>
                {intl.formatMessage({ id: "forgotPassword.pleaseContact" })}
            </StyledMessage>
        ) : (
            <Spin spinning={loading}>
                <StyledLoginForm>
                    <ForgotPasswordForm onLoading={this.handleLoading} />
                </StyledLoginForm>
            </Spin>
        );

        return (
            <PublicLayout>
                <LoginWrapper>
                    <Logo>
                        <img
                            src={getImagePath(theme.assets.logo)}
                            alt="branded logo"
                        />
                    </Logo>
                    {content}
                </LoginWrapper>
            </PublicLayout>
        );
    }
}

export default withTheme(injectIntl(ForgotPasswordView));
