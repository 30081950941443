import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input } from "antd";
import styled from "styled-components";
import { injectIntl } from "react-intl";

const SearchBar = styled.div`
    .ant-input-group {
        @media (min-width: 768px) {
            min-width: 350px;
        }
        @media (min-width: 1200px) {
            min-width: 450px;
        }
        @media (min-width: 1600px) {
            min-width: 550px;
        }
        @media (min-width: 1900px) {
            min-width: 600px;
        }

        .ant-btn-primary {
            background: ${({ theme: { colors } }) => colors.primary};
            border-color: ${({ theme: { colors } }) => colors.primary};
        }
    }
`;

class CredentialSearch extends Component {
    static propTypes = {
        search: PropTypes.string,
        sort: PropTypes.string,
        onOrderDirectionChange: PropTypes.func,
        onSortChange: PropTypes.func,
        onSearchChange: PropTypes.func
    };

    static defaultProps = {
        search: "",
        sort: "created_at"
    };

    render() {
        const { search, onSearchChange, onSearch, intl } = this.props;
        return (
            <SearchBar>
                <Input.Search
                    enterButton
                    placeholder={intl.formatMessage({
                        id: "myc.searchCredentials"
                    })}
                    onChange={onSearchChange}
                    value={search}
                    onSearch={onSearch}
                />
            </SearchBar>
        );
    }
}

export default injectIntl(CredentialSearch);
