import React from "react";
import { Alert, Row, Col } from "antd";
import styled from "styled-components";
import LoginWrapper from "./ui/LoginWrapper";
import Block from "./ui/Block";
import Content from "./Content";
import { UAEUFooter } from "../components/UAEUFooter";
import Navbar from "../../../../passport/src/components/uaeu/Navbar";

const BodyWrapper = styled.div`
    letter-spacing: 0.05em;
    font-size: 16px;
    background: white;
`;

const AlertWrapper = styled.div`
    min-height: 40px;
`;

const NavSpacing = styled.div`
    margin-bottom: 150px;
    @media only screen and (max-width: 1020px) {
        margin-bottom: 0;
    }
`;

const StyledLoginForm = styled.div`
    padding: 20px 24px;

    .ant-legacy-form-item {
        margin-bottom: 0;
    }

    .login-form-button {
        height: auto;
        border-radius: 0;
        width: 100%;
        padding: 0.8em 10px 10px 0.8em;
        background-color: #105580;
        border: none;
        color: #fff;
        cursor: pointer;
        text-transform: uppercase;
        &:disabled {
            cursor: not-allowed;
        }
        &:hover {
            color: #fff;
            background-color: #2c82b7;
        }
    }

    .ant-input {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        color: inherit;
        font-family: inherit;
        padding: 0.8em 0 10px 0.8em;
        border: 1px solid #cfcfcf;
        outline: 0;
        display: inline-block;
        margin: 0 0 0.8em 0;
        padding-right: 2em;
        width: 100%;
        height: auto;
        border-color: #cfcfcf;
        border-radius: 0;
        &:focus {
            box-shadow: none;
        }
    }
`;

export const UAEULoginView = ({ alert, children }) => {
    return (
        <BodyWrapper>
            <LoginWrapper>
                <Navbar />
                <NavSpacing />
                <Row type="flex">
                    <Col
                        xs={24}
                        sm={12}
                        className="red-overlay left-background section"
                    >
                        <Block>
                            <div className="login-form-wrap p-34-50">
                                <div className="text-center">
                                    <h4> Sign In to your UAEU account </h4>
                                </div>
                                <AlertWrapper>
                                    {" "}
                                    {alert && (
                                        <Alert message={alert} type="error" />
                                    )}
                                </AlertWrapper>
                                <StyledLoginForm>{children}</StyledLoginForm>
                            </div>
                        </Block>
                    </Col>
                    <Col
                        xs={24}
                        sm={12}
                        className="blue-overlay right-background section"
                    >
                        <Block>
                            <div className="p-100-70">
                                <h2>Welcome to your UAEU Passport!</h2>
                            </div>
                        </Block>
                    </Col>
                </Row>
                <Content />
                <UAEUFooter className="footer" />
            </LoginWrapper>
        </BodyWrapper>
    );
};
