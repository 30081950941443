export const setLastVisitedUrl = () => {
    if (isPathContains('oauth') ||
        isPathContains('forgot_password') ||
        isPathContains('resetting') ||
        isPathContains('email')
    ) {
        return;
    }

    localStorage.setItem('lastVisitedUrl', window.location.pathname + window.location.search);
}

export const getLastVisitedUrl = () => {
    return localStorage.getItem('lastVisitedUrl');
}

export const removeLastVisitedUrl = () => {
    localStorage.removeItem('lastVisitedUrl');
}

const isPathContains = (path) => {
    const currentPath = window.location.pathname;

    return currentPath.toLowerCase().indexOf(path) !== -1
}
