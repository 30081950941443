import Layout from "./components/Layout";
import withAuthentication from "./helpers/withAuthentication";
import { getTheme } from "@educhain/themes/src/";
import {
    CredentialView,
    DashboardView,
    HistoryView,
    ShareView,
    ProfileView,
    FaqView,
    ErrorView,
    OauthController,
    ResetEmailView,
    MediaFileView,
} from "./views/index";
import { LoginView, ForgotPasswordView, ResetPasswordView } from "./views";

const theme = getTheme();

const ROUTE_NAME_LOGIN = "login";
const ROUTE_NAME_OAUTH = "ouath";
const ROUTE_NAME_HOME = "home";
const ROUTE_NAME_CATEGORY = "category";
const ROUTE_NAME_CREDENTIALS = "credentials";
const ROUTE_NAME_PROFILE = "profile";
const ROUTE_NAME_HISTORY = "history";
const ROUTE_NAME_FAQ = "faq";
const ROUTE_NAME_SHARE = "share";
const ROUTE_NAME_MEDIA_FILES = "media_files";
const ROUTE_NAME_FORGOT_PASSWORD = "forgot_password";
const ROUTE_NAME_RESET_PASSWORD = "reset_password";
const ROUTE_NAME_RESET_EMAIL = "reset_email";

export const NAMED_ROUTES = {
    [ROUTE_NAME_LOGIN]: "/:locale/login",
    [ROUTE_NAME_OAUTH]: "/:locale/oauth",
    [ROUTE_NAME_HOME]: "/:locale/",
    [ROUTE_NAME_CATEGORY]: "/:locale/category/:category",
    [ROUTE_NAME_CREDENTIALS]: "/:locale/credentials/:category?",
    [ROUTE_NAME_PROFILE]: "/:locale/profile",
    [ROUTE_NAME_HISTORY]: "/:locale/history",
    [ROUTE_NAME_FAQ]: "/:locale/faq",
    [ROUTE_NAME_SHARE]: "/:locale/share",
    [ROUTE_NAME_MEDIA_FILES]: "/:locale/media-files/:category?",
    [ROUTE_NAME_FORGOT_PASSWORD]: "/:locale/forgot_password",
    [ROUTE_NAME_RESET_PASSWORD]: "/:locale/resetting/reset/:token",
    [ROUTE_NAME_RESET_EMAIL]: "/:locale/email/reset/:token",
};

export default [
    {
        path: NAMED_ROUTES[ROUTE_NAME_LOGIN],
        exact: true,
        component: LoginView
    },
    {
        path: NAMED_ROUTES[ROUTE_NAME_FORGOT_PASSWORD],
        exact: true,
        component: ForgotPasswordView
    },
    {
        path: NAMED_ROUTES[ROUTE_NAME_RESET_PASSWORD],
        exact: true,
        component: ResetPasswordView
    },
    {
        path: NAMED_ROUTES[ROUTE_NAME_RESET_EMAIL],
        exact: true,
        component: ResetEmailView
    },
    {
        path: NAMED_ROUTES[ROUTE_NAME_OAUTH],
        exact: true,
        component: OauthController
    },
    {
        path: NAMED_ROUTES[ROUTE_NAME_HOME],
        component: withAuthentication(Layout),
        routes: [
            {
                path: NAMED_ROUTES[ROUTE_NAME_HOME],
                exact: true,
                component: !!theme.dashboard ? DashboardView : CredentialView,
            },
            {
                path: NAMED_ROUTES[ROUTE_NAME_CATEGORY],
                exact: true,
                component: DashboardView,
            },
            {
                path: NAMED_ROUTES[ROUTE_NAME_CREDENTIALS],
                exact: true,
                component: CredentialView,
            },
            {
                path: NAMED_ROUTES[ROUTE_NAME_MEDIA_FILES],
                exact: true,
                component: MediaFileView
            },
            {
                path: NAMED_ROUTES[ROUTE_NAME_FAQ],
                exact: true,
                component: FaqView
            },
            {
                path: NAMED_ROUTES[ROUTE_NAME_HISTORY],
                exact: true,
                component: HistoryView
            },
            {
                path: NAMED_ROUTES[ROUTE_NAME_SHARE],
                exact: true,
                component: ShareView
            },
            {
                path: NAMED_ROUTES[ROUTE_NAME_PROFILE],
                exact: true,
                component: ProfileView
            },
            {
                component: ErrorView
            }
        ]
    },
    {
        component: ErrorView
    }
];
