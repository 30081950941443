import React, { Component } from "react";
import PropTypes from "prop-types";
import { Collapse, Pagination } from "antd";
import HistoryItem from "./HistoryItem";
import styled from "styled-components";
import PanelHeader from "./PanelHeader";
import { withSize } from "@educhain/ui/src/hoc/withSize";
import { FormattedMessage } from "react-intl";

const { Panel } = Collapse;

const HistoryPagination = styled.div`
    margin-top: 25px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    justify-content: center;
`;

const Empty = styled.div`
    text-align: center;
    padding: 25px;
`;

class HistoryResults extends Component {
    static propTypes = {
        rows: PropTypes.array,
        total: PropTypes.number
    };

    static defaultProps = {
        rows: [],
        total: 0
    };

    render() {
        const { rows, total, onPageChange, page, size, sm } = this.props;
        return rows.length ? (
            <span>
                <Collapse bordered={false}>
                    {rows.map(row => (
                        <Panel
                            header={
                                <PanelHeader
                                    id="historyItem"
                                    {...row}
                                    {...this.props}
                                />
                            }
                            key={row.id}
                        >
                            <HistoryItem {...row} {...this.props} />
                        </Panel>
                    ))}
                </Collapse>
                <HistoryPagination>
                    <Pagination
                        simple={sm}
                        current={page}
                        pageSize={size}
                        onChange={onPageChange}
                        total={total}
                    />
                </HistoryPagination>
            </span>
        ) : (
            <Empty>
                <h4>
                    <FormattedMessage id="history.noSearchResults1" />
                </h4>
                <p>
                    <FormattedMessage id="history.noSearchResults2" />
                </p>
            </Empty>
        );
    }
}

export default withSize(HistoryResults);
