import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Input } from "antd";
import { withTheme } from "styled-components";
import { Captcha } from "../../utils/captcha";
import { FormattedMessage, injectIntl } from "react-intl";
import { InputWrapper } from "@educhain/ui/src/form/InputWrapper";
import { Link } from "@educhain/utils/lang";
import { isHasPlainLogin, getSSOLoginUrl } from '@educhain/utils/src/services/login';
import { setCurrentUsername } from '../../services/storage';

// TODO ANT_FORM update to use the new ant design form style with hooks
const BaseLoginForm = Form.create({
    name: "login_form",
    onFieldsChange(props, changedFields) {
        props.onChange(changedFields);
    },
    mapPropsToFields(props) {
        return {
            username: Form.createFormField({
                ...props.username,
                value: props.username.value
            }),
            password: Form.createFormField({
                ...props.password,
                value: props.password.value
            }),
            remember: Form.createFormField({
                ...props.value,
                value: props.remember.value
            })
        };
    }
})(props => {
    const {
        onSubmit,
        form,
        showCaptcha,
        onCaptchaChange,
        loading,
        intl,
        theme
    } = props;
    const { getFieldDecorator } = form;
    const forgotPasswordButton = !!theme.ldap ? null : (
        <Form.Item>
            <div>
                <Link to="/forgot_password">
                    {intl.formatMessage({
                        id: "forgotPassword.forgotPassword"
                    })}
                </Link>
            </div>
        </Form.Item>
    );

    const onSSOLoginClick = () => {
        setCurrentUsername('_' + Math.random().toString(36).substring(2, 9));
        window.location.href = getSSOLoginUrl();
    }

    return (
        <Form onSubmit={onSubmit} className="login-form">
            {
                isHasPlainLogin() &&
                <>
                    <Form.Item>
                        <InputWrapper>
                            {getFieldDecorator("username")(
                                <Input
                                    type="username"
                                    required={true}
                                    placeholder={intl.formatMessage({
                                        id: "login.emailOrUsername"
                                    })}
                                />
                            )}
                        </InputWrapper>
                    </Form.Item>
                    <Form.Item>
                        <InputWrapper>
                            {getFieldDecorator("password")(
                                <Input
                                    required={true}
                                    type="password"
                                    placeholder={intl.formatMessage({
                                        id: "login.password"
                                    })}
                                />
                            )}
                        </InputWrapper>
                    </Form.Item>
                    <Form.Item>
                        <Captcha active={showCaptcha} onChange={onCaptchaChange} />
                        <Button
                            id="submitButton"
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            loading={loading}
                            disabled={showCaptcha}
                        >
                            <FormattedMessage id="login.login" />
                        </Button>
                    </Form.Item>
                </>
            }
            {
                getSSOLoginUrl() &&
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="button"
                        className="login-form-button"
                        onClick={onSSOLoginClick}
                    >
                        {isHasPlainLogin() ? <FormattedMessage id="login.sso_login" /> : <FormattedMessage id="login.login" />}
                    </Button>
                </Form.Item>
            }
            {isHasPlainLogin() && forgotPasswordButton}
        </Form>
    );
});

export const LoginForm = injectIntl(withTheme(BaseLoginForm));
