import { API_REQUEST } from "../constants";
import { getCredentials as getCredentialsRequest } from "../../services/api";
import { CREDENTIALS_PREFIX } from "./constants";

export const loadCredentials = (category) => ({
    type: CREDENTIALS_PREFIX + API_REQUEST,
    filter: API_REQUEST,
    meta: {
        prefix: CREDENTIALS_PREFIX,
        request: () => getCredentialsRequest(category)
    }
});
