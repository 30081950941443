import { createSelector } from "reselect";

export const exists = createSelector(
    state => state.mediaFiles.count,
    count => typeof count === "undefined"
);

export const isFetching = createSelector(
    state => state.mediaFiles.isFetching,
    isFetching => isFetching
);

export const all = createSelector(
    state => state.mediaFiles.data,
    data => data
);

export default {
    exists,
    all,
    isFetching,
};
