import { createSelector } from "reselect";
import { groupBy } from "../../helpers/index";

export const DEFAULT_CREDENTIAL_TYPES = [
    "Diploma",
    "Transcript",
    "Letter",
    "Other"
];

export const DEFAULT_CREDENTIAL_TYPES_MAP = DEFAULT_CREDENTIAL_TYPES.reduce(
    (acc, obj) => {
        acc[obj] = [];
        return acc;
    },
    {}
);

export const exists = createSelector(
    state => state.credentials.count,
    count => typeof count === "undefined"
);

export const isFetching = createSelector(
    state => state.credentials.isFetching,
    isFetching => isFetching
);

export const all = createSelector(
    state => state.credentials.data,
    data => data
);

// DO NO USE, memoizing error.
export const groupedByDiplomaType = createSelector(
    state => all(state),
    data => groupBy(data, "type", DEFAULT_CREDENTIAL_TYPES_MAP) //
);

export default {
    exists,
    all,
    isFetching,
    groupedByDiplomaType
};
