import { getNamedPath } from "../services/path";
import { isRTLEnabled } from "@educhain/utils/lang";
import { AR_LOCALE, EN_LOCALE, ES_LOCALE } from "@educhain/utils";

export const isRTLEnabledAtPath = () => {
    return isRTLEnabled(getNamedPath());
};

export const getAvailableLocale = locale => {
    const availableLanguages = [AR_LOCALE, EN_LOCALE, ES_LOCALE];

    return isRTLEnabledAtPath() && availableLanguages.includes(locale)
        ? locale
        : EN_LOCALE;
};
