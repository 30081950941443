import React from "react";
import PropTypes from "prop-types";
import getErrorsFromResponse from "../../helpers/getErrorsFromResponse";
import { Redirect } from "react-router-dom";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import authSelectors from "../../store/auth/selectors";
import { clearErrors, login } from "../../store/auth/actions";
import { alertClear } from "../../store/alerts/actions";
import { connect } from "react-redux";
import { LoginForm } from "./LoginForm";
import { get } from "lodash";
import { injectIntl } from "react-intl";
import { THEME_UAEU, ThemeComponentSwitcher } from "@educhain/themes/src";
import DefaultLoginView from "./DefaultLoginView";
import { UAEULoginView } from "@educhain/themes/src/uaeu/login";
import { getLastVisitedUrl, removeLastVisitedUrl } from '../../helpers/lastVisitedUrl';

const components = {
    [THEME_UAEU]: UAEULoginView
};

class LoginController extends React.Component {
    static propTypes = {
        isAuthenticated: PropTypes.bool,
        error: PropTypes.object,
        loggingIn: PropTypes.bool,
        alert: PropTypes.string,
        login: PropTypes.func,
        clearErrors: PropTypes.func,
        clearAlerts: PropTypes.func
    };

    static defaultProps = {
        error: {}
    };

    default = {
        fields: {
            username: {
                value: null
            },
            password: {
                value: null
            },
            remember: {
                value: true
            }
        }
    };

    state = {
        disabled: true,
        captcha: null,
        ...this.default
    };

    handleFormChange = changedFields => {
        const { fields } = this.state;
        const { clearErrors, clearAlerts } = this.props;
        this.setState({
            fields: {
                ...fields,
                ...changedFields
            }
        });
        clearErrors();
        clearAlerts();
    };

    handleCaptcha = captcha => {
        const { clearErrors } = this.props;
        this.setState({ captcha });
        clearErrors();
    };

    handleSubmit = event => {
        event.preventDefault();
        const {
            username: { value: username },
            password: { value: password },
            captcha
        } = this.state.fields;

        this.props.login({
            username,
            password,
            "g-recaptcha-response": captcha
        });
    };

    render() {
        const {
            isAuthenticated,
            loading,
            error,
            alert,
            match: {
                params: { locale }
            },
            intl
        } = this.props;
        const { fields } = this.state;
        const errors = getErrorsFromResponse(error);

        if (isAuthenticated) {
            let redirectUrl = `/${locale}`;

            if (getLastVisitedUrl()) {
                redirectUrl = getLastVisitedUrl();
                removeLastVisitedUrl();
            }

            return <Redirect to={redirectUrl} />;
        }

        const showCaptcha = !!get(errors, "recaptcha");

        return (
            <ThemeComponentSwitcher
                components={components}
                defaultComponent={DefaultLoginView}
                childProps={{
                    loading,
                    alert,
                    intl,
                    children: (
                        <LoginForm
                            {...fields}
                            onSubmit={this.handleSubmit}
                            onChange={this.handleFormChange}
                            onCaptchaChange={this.handleCaptcha}
                            showCaptcha={showCaptcha}
                        />
                    )
                }}
            />
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: authSelectors.isAuthenticated(state),
    error: state.auth.error,
    loading: state.auth.isFetching || state.user.isFetching,
    alert: state.alert.message
});

const mapDispatchToProps = dispatch => ({
    login: data => dispatch(login(data)),
    clearErrors: () => dispatch(clearErrors()),
    clearAlerts: () => dispatch(alertClear())
});

export default injectIntl(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginController))
);
