import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "./polyfill";
import React from "react";
import { render } from "react-dom";
import "axios-progress-bar/dist/nprogress.css";
import "./styling/index.less";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import App from "./App";

if (!global.Intl) {
    global.Intl = require("intl");
}

render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
