import React from "react";
import DefaultFAQ from "../../default/components/FAQ";
import { withTheme } from "styled-components";
import { FormattedMessage, injectIntl } from 'react-intl';

const reptonTabs = [
    {
        name: <FormattedMessage id="faq.managing_accounts" />,
        component: <>
            <div className="content">
                <h2>How can I change login credentials for my account?</h2>
                By clicking “Profile” in the menu, you can change email and password for your account.
            </div>
            <div className="content">
                <h2>How can I see my children's credentials?</h2>
                By clicking a dropdown icon in the right
                corner. You can switch between your children’s account and view their credentials and media files. You need
                to switch back to your account before to choose another children’s account.
            </div>
            <div className="content">
                <h2>Can I pass an account to my children?</h2>
                Yes, you can update the email in the Profile page. If you want to pass access to a child, please switch to the
                account and change the email. You will receive an email with confirmation to your account for the action
                confirmation.
            </div>
        </>,
    }
];

const FAQ = ({ theme, intl }) => {
    return (
        <DefaultFAQ intl={intl} theme={theme} tabs={reptonTabs} />
    );
};

export default withTheme(injectIntl(FAQ));
