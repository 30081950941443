import {
    SHARED_PREFIX, SHARED_DELETE, SHARED_CREATE, SHARED_CREATE_IS_ALLOWED
} from "./constants";
import { API_ERROR, API_REQUEST, API_SUCCESS } from '../constants';

const initialState = {
    data: [],
    isFetching: true,
    hash: null,
    isAllowed: true
};

export const sharingReducer = (
    state = initialState,
    { type, payload, meta }
) => {
    switch (type) {
        case SHARED_PREFIX + API_REQUEST:
            return { ...state, isFetching: true };
        case SHARED_PREFIX + API_SUCCESS:
            return {
                ...state,
                ...payload.data,
                isFetching: false
            };
        case SHARED_PREFIX + API_ERROR:
            return { ...state, error: payload.data, isFetching: false };
        case SHARED_DELETE + API_REQUEST:
            return { ...state, isFetching: true };
        case SHARED_DELETE + API_SUCCESS:
            return {
                ...state,
                data: state.data.map(obj =>
                    obj.id === meta.sharingId
                        ? {
                              ...obj,
                              deletedAt: payload.data.deletedAt,
                          }
                        : obj
                ),
                isFetching: false
            };
        case SHARED_DELETE + API_ERROR:
            return { ...state, error: payload.data, isFetching: false };
        case SHARED_CREATE + API_REQUEST:
            return { ...state, isFetching: true };
        case SHARED_CREATE + API_SUCCESS:
            return {...state,hash: payload.data.hash, isFetching: false}
        case SHARED_CREATE + API_ERROR:
            return { ...state, error: payload.data, isFetching: false };
        case SHARED_CREATE_IS_ALLOWED + API_REQUEST:
            return { ...state, isFetching: true };
        case SHARED_CREATE_IS_ALLOWED + API_SUCCESS:
            return {...state, isAllowed: true, isFetching: false}
        case SHARED_CREATE_IS_ALLOWED + API_ERROR:
            return { ...state, isAllowed: false, isFetching: false };
        default:
            return state;
    }
};
