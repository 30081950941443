import React, { Component } from "react";
import { connect } from "react-redux";
import PageContent from "../../components/PageContent";
import CategoriesList from "./CategoriesList";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { FormattedMessage } from "react-intl";
import { withTheme } from "styled-components";
import { Redirect } from 'react-router-dom';

class DashboardView extends Component {
    state = {};

    componentDidMount() {}

    render() {
        const { theme, match } = this.props;

        if (!theme.dashboard && match.url.includes('/category')) {
            return <Redirect to="/" />;
        }

        return (
            <>
                <PageContent
                    title={<FormattedMessage id={theme.dashboard.title} />}
                >
                    <CategoriesList />
                </PageContent>
            </>
        );
    }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(withTheme(withRouter(DashboardView)));
