import React, { Component } from "react";
import { CloseOutlined } from "@ant-design/icons";
import styled from "styled-components";
import StyledLink from "../../components/StyledLink";
import ResponsivePDFViewer from "../../components/pdf/ResponsivePDFViewer";

const ModalBackdrop = styled.div`
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    padding: 20px; /* Location of the box */
    padding-top: 50px;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
`;

const Content = styled.div`
    margin: auto;
    display: block;
    max-width: 1600px;
    height: 100%;
`;

const CloseButton = styled.div`
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    padding-top: 50px; /* Location of the box */
    right: 0;
    top: 0;
    padding: 15px;
    color: white;
    cursor: pointer;
    .anticon {
        width: 20px;
        height: 20px;
        font-size: 20px;
    }
`;

class CredentialPDFPreview extends Component {
    wrapperRef = null;

    setWrapperRef = node => {
        this.wrapperRef = node;
    };

    handleClick = event => {
        const { onClose, isFetching } = this.props;
        if (
            this.wrapperRef &&
            this.wrapperRef === event.target &&
            !isFetching
        ) {
            onClose();
        }
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    render() {
        const { onClose, blob, visible, onDownload } = this.props;

        return visible ? (
            <ModalBackdrop ref={this.setWrapperRef}>
                <Content id="credentialPdfModal">
                    <ResponsivePDFViewer
                        files={[blob]}
                        onDownload={onDownload}
                        fileIndex={0}
                    />
                    <StyledLink onClick={onClose}>
                        <CloseButton id="credentialPdfModalCloseButton">
                            <CloseOutlined />
                        </CloseButton>
                    </StyledLink>
                </Content>
            </ModalBackdrop>
        ) : (
            <span />
        );
    }
}

export default CredentialPDFPreview;
