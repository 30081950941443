import { AR_LOCALE } from "../index";

export { default as Link } from "./Link";

export const arabicToLeft = (locale, reverse = false) => {
    if (reverse) {
        return locale.toLowerCase() === AR_LOCALE ? "right" : "left";
    } else {
        return locale.toLowerCase() === AR_LOCALE ? "left" : "right";
    }
};

export const isRTLEnabled = currPath => {
    return (
        process.env.REACT_APP_USE_RTL.toLowerCase() === "true" ||
        process.env.REACT_APP_USE_RTL.toLowerCase()
            .split(",")
            .filter(s => currPath === s).length > 0
    );
};
