import styled from "styled-components";

const Block = styled.div`
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    h2,
    h4 {
        color: white;
    }

    h2 {
        font-weight: 700;
        font-size: 2em;
        @media only screen and (max-width: 660px) {
            font-size: 1.8em;
            font-weight: 600;
        }
    }

    h4 {
        font-size: 1.45em;
        font-weight: 600;
    }

    .p-34-50 {
        padding: 34px 50px;
        @media only screen and (max-width: 660px) {
            padding: 25px 15px;
        }
    }

    .p-100-70 {
        padding: 100px 70px;
        @media only screen and (max-width: 660px) {
            padding: 50px 22px;
        }
    }
`;

export default Block;
