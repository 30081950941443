import React, { useState, useEffect } from "react";
import { List, Card, Spin, message } from "antd";
import styled from "styled-components";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { compose } from "redux";
import ShareAltOutlined from "@ant-design/icons/lib/icons/ShareAltOutlined";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import { useHover } from "../../hooks/hover";
import { injectIntl, FormattedMessage } from "react-intl";
import { translateType } from "../../helpers/translateType";
import { getCredentialParameterByName, convertDate } from '../../helpers/credential';
import SocialButtons from '../../components/SocialButtons';
import { createSharing } from '../../services/api';

const CredentialImage = styled.div`
    width: 100%;
    height: 240px;
    cursor: pointer;
    text-align: center;
    display: flex;
    padding: 5px;
    img {
        object-fit: contain;
        margin: auto;
        margin-top: 0;
        width: 100%;
        height: 100%;
    }
`;
const Table = styled.table``;
const CredentialCover = styled.div`
    border: 2px solid
        ${({ selected, theme: { colors } }) =>
            selected ? colors.primary : "#f0f0f0"};
    border-bottom: none;
    transition: all 0.3s ease-in-out;

    &:hover {
        border: 2px solid ${({ theme: { colors } }) => colors.primary};
        border-bottom: none;
    }

    border-radius: 15px 15px 0 0;
    background: rgba(79, 79, 79, 0.25);

    .controls {
        position: absolute;
        height: 40px;
        font-size: 25px;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .anticon {
            transition: all 0.3s ease-in-out;
            padding: 0 5px;
            cursor: pointer;
            display: ${({ selected }) => (selected ? "block" : "none")};
            color: ${({ theme }) => theme.colors.primary};

            &:hover {
                color: ${({ theme }) => theme.colors.primaryLight};
            }

            svg {
                filter:
                    drop-shadow(-0.5px -0.5px 0.5px white)
                    drop-shadow(0.5px -0.5px 0.5px white)
                    drop-shadow(0.5px 0.5px 0.5px white)
                    drop-shadow(-0.5px 0.5px 0.5px white);
            }
        }
    }
`;

const StyledCard = styled(Card)`
    border-radius: 15px;
    cursor: pointer;
    border: 2px solid
        ${({ selected, theme: { colors } }) =>
            selected ? colors.primary : "#f0f0f0"};
    transition: all 0.3s ease-in-out;

    &:hover {
        border: 2px solid ${({ theme: { colors } }) => colors.primary};
    }

    .ant-card-cover {
        margin: -2px;
    }

    .ant-card-body {
        border-top: 2px solid #f0f0f0;
        padding: 15px 10px 10px;

        .ant-card-meta-detail > div:not(:last-child) {
            margin-bottom: 0px;
        }
    }
`;

const Credential = ({
    credential,
    navigate,
    onView,
    onDownload,
    selected,
    setSelected,
    match: {
        params: { locale }
    },
    location: { search },
    intl
}) => {
    const [hoverRef, isHovered] = useHover();
    const [fetching, setFetching] = useState(false);
    const [socialButtonsRefs, setSocialButtonsRefs] = useState({});
    const [sharedByUrl, setSharedByUrl] = useState(false);
    const {
        id,
        docHash,
        blobPreview,
        type,
        credentialParameters,
        credentialTitles
    } = credential;

    const issuer = getCredentialParameterByName(credentialParameters, 'issuer', locale);
    const issueDate = getCredentialParameterByName(credentialParameters,'issueDate', locale);
    const expiryDate = getCredentialParameterByName(credentialParameters, 'expiryDate', locale);
    const sharingName = `${credentialTitles[locale]} ${intl.formatMessage({ id: 'menu.share' })}`;
    const onRefInit = (refs) => {
        setSocialButtonsRefs(refs);
    };

    const shareUrl = () => {
        const messageDuration = 10;
        setFetching(true);

        return createSharing({
                name: sharingName,
                credentials: [id]
            })
            .then(({ data }) => {
                if (!data.isExists) {
                    message.success(intl.formatMessage(
                        { id: 'portal.credentialWasShared' },
                        { name: credentialTitles[locale] }
                    ), messageDuration)
                }

                return `${window.location.origin}/${locale}/credentials/shared/${data.hash}`
            })
            .catch((error) => {
                if (error?.response?.data?.message) {
                    message.error(error.response.data.message, messageDuration);
                }

                throw error;
            })
            .finally(() => {
                setFetching(false);
            });
    };

    const searchParams = new URLSearchParams(search);
    const shareDocHash = searchParams.get('cid');
    const bySocial = searchParams.get('s');

    useEffect(() => {
        if (!sharedByUrl
            && docHash === shareDocHash
            && Object.keys(socialButtonsRefs).length > 0
            && bySocial
            && socialButtonsRefs[bySocial]
            && !fetching
        ) {
            socialButtonsRefs[bySocial].click();
            setSharedByUrl(true);
            navigate(`/${locale}`);
        }
    }, [sharedByUrl, shareDocHash, bySocial, socialButtonsRefs, fetching, docHash]);

    return (
        <Spin spinning={fetching}>
            <List.Item>
                <span
                    ref={hoverRef}
                    onClick={() => setSelected(selected ? null : id)}
                >
                    <StyledCard
                        key={id}
                        selected={selected || isHovered}
                        style={{ minHeight: 400 }}
                        cover={
                            <CredentialCover selected={selected || isHovered}>
                                <div className="controls">
                                    <EyeOutlined onClick={() => onView(id)} />
                                    <ShareAltOutlined
                                        onClick={() =>
                                            navigate(`/${locale}/share`)
                                        }
                                    />
                                    <DownloadOutlined
                                        onClick={() => onDownload(id)}
                                    />
                                </div>
                                <CredentialImage
                                    className="credential-collapse"
                                    onClick={() => selected && onView(id)}
                                >
                                    <img src={blobPreview} alt="" />
                                </CredentialImage>
                            </CredentialCover>
                        }
                    >
                        <Card.Meta
                            title={credentialTitles[locale]}
                            description={
                                <>
                                    <Table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <FormattedMessage
                                                        id={
                                                            "shared.components.SharedTabInfo.type"
                                                        }
                                                    />
                                                    {/* The colons here are used ad-hoc because these translations are used elsewhere without colons. Also prettier... */}
                                                    :
                                                </td>
                                                <td>
                                                    <FormattedMessage
                                                        id={translateType(type)}
                                                    />
                                                </td>
                                            </tr>
                                            {!!issuer.value && (
                                                <tr>
                                                    <td>{issuer.label}:</td>
                                                    <td>{issuer.value}</td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td>{issueDate.label}:</td>
                                                <td>
                                                    {convertDate(intl, issueDate.value)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{expiryDate.label}:</td>
                                                <td>
                                                    {convertDate(intl, expiryDate.value)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <SocialButtons
                                                        onRefInit={onRefInit}
                                                        url={shareUrl}
                                                        imageUrl={blobPreview}
                                                        title={sharingName}
                                                        width={32}
                                                        height={32}
                                                        round
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </>
                            }
                        />
                    </StyledCard>
                </span>
            </List.Item>
        </Spin>
    );
};

export default compose(injectIntl, withRouter)(Credential);
