import React from "react";
import { Tabs } from "antd";
import styled, { withTheme } from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";

const StyledContainer = styled.div`
    h2 {
        color: ${({ theme: { colors } }) => colors.primaryLight};
        font-size: 16px;
    }
    .content {
        margin-bottom: 15px;
    }
`;

const TabPane = Tabs.TabPane;

const FAQ = ({ theme, intl, tabs }) => {
    const phoneNumber = !!theme.phoneName
        ? intl.formatMessage({ id: theme.phoneName })
        : theme.phone;

    return (
        <StyledContainer>
            <Tabs>
                <TabPane tab={<FormattedMessage id="faq.about" />} key="1">
                    <div className="content">
                        <h2>
                            <FormattedMessage id="faq.about.title1" />
                        </h2>
                        <FormattedMessage id="faq.about.text1" />
                    </div>
                    <div className="content">
                        <h2>
                            <FormattedMessage id="faq.about.title2" />
                        </h2>
                        <FormattedMessage id="faq.about.text2" />
                        <ol>
                            <li>
                                <FormattedMessage id="faq.about.text2.li1" />
                            </li>
                            <li>
                                <FormattedMessage id="faq.about.text2.li2" />
                            </li>
                            <li>
                                <FormattedMessage id="faq.about.text2.li3" />
                            </li>
                        </ol>
                    </div>
                    <div className="content">
                        <h2>
                            <FormattedMessage id="faq.about.title3" />
                        </h2>
                        <FormattedMessage id="faq.about.text3.1" />{" "}
                        <FormattedMessage id="faq.about.text3.2" />
                        <br />
                    </div>
                </TabPane>
                <TabPane tab={<FormattedMessage id="faq.sharing" />} key="2">
                    <div className="content">
                        <h2>
                            <FormattedMessage id="faq.sharing.title1" />
                        </h2>
                        <FormattedMessage id="faq.sharing.text1" />
                    </div>
                    <div className="content">
                        <h2>
                            <FormattedMessage id="faq.sharing.title2" />
                        </h2>
                        <FormattedMessage id="faq.sharing.text2" />
                    </div>
                    <div className="content">
                        <h2>
                            <FormattedMessage id="faq.sharing.title3" />
                        </h2>
                        <FormattedMessage id="faq.sharing.text3" />
                    </div>
                    <div className="content">
                        <h2>
                            <FormattedMessage id="faq.sharing.title4" />
                        </h2>
                        <FormattedMessage id="faq.sharing.text4" />
                    </div>
                </TabPane>
                <TabPane tab={<FormattedMessage id="faq.download" />} key="3">
                    <div className="content">
                        <h2>
                            <FormattedMessage id="faq.download.title1" />
                        </h2>
                        <FormattedMessage id="faq.download.text1" />
                    </div>
                    <div className="content">
                        <h2>
                            <FormattedMessage id="faq.download.title2" />
                        </h2>
                        <FormattedMessage id="faq.download.text2" />
                    </div>
                    <div className="content">
                        <h2>
                            <FormattedMessage id="faq.download.title3" />
                        </h2>
                        <FormattedMessage id="faq.download.text3" />
                    </div>
                    <div className="content">
                        <h2>
                            <FormattedMessage id="faq.download.title4" />
                        </h2>
                        <FormattedMessage id="faq.download.text4" />
                        <a
                            href={theme.verifyURL}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {" "}
                            <FormattedMessage id="faq.download.text4.link" />
                        </a>
                    </div>
                </TabPane>
                {!!tabs && tabs.length > 0 && tabs.map(({ name, component }, index) => {
                    return (
                        <TabPane tab={name} key={index + 5}>
                            {component}
                        </TabPane>
                    );
                })}
                <TabPane tab={<FormattedMessage id="shared.support" />} key="4">
                    <div className="content">
                        <h2>
                            <FormattedMessage id="faq.support.title" />
                        </h2>
                        <FormattedMessage id="faq.support.text" />
                        <ul>
                            {
                                phoneNumber && (
                                    <li>
                                        <FormattedMessage id="profile.phone" />{" "}
                                        {phoneNumber}
                                    </li>
                                )
                            }
                            <li>
                                <FormattedMessage id="profile.email" />{" "}
                                <a href={`mailto:${theme.email}`}>
                                    {theme.email}
                                </a>
                            </li>
                        </ul>
                    </div>
                </TabPane>
            </Tabs>
        </StyledContainer>
    );
};

export default withTheme(injectIntl(FAQ));
