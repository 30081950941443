import React, { Component } from "react";

import {
    ClockCircleOutlined,
    FileTextOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
    ShareAltOutlined,
    UserOutlined,
    VideoCameraOutlined,
    DownCircleFilled
} from "@ant-design/icons";

import { Menu, Drawer, Badge, Dropdown, Space } from 'antd';
import { connect } from "react-redux";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { Link } from "@educhain/utils/lang";
import { logout, changeAccount } from "../store/auth/actions";
import { alertInfo } from "../store/alerts/actions";
import HambugerMenu from "./HambugerMenu";
import styled, { withTheme } from "styled-components";
import notificationSelectors from "../store/notifications/selectors";
import { FormattedMessage, injectIntl } from "react-intl";
import { getImagePath } from "@educhain/utils/theme/getImagePath";
import { withSize } from "@educhain/ui/src/hoc/withSize";
import ProfileMenu from './ProfileMenu';
import { canSwitchAccount } from '../helpers/user';

const Logo = styled.div`
    cursor: pointer;
    img {
        height: ${({ theme: { logo } }) => logo && logo.width ? 'auto' : '48px' };
        width: ${({ theme: { logo } }) => logo && logo.width ? logo.width : 'auto' };
    }

    @media (max-width: 992px) {
        padding: 15px;

        img {
            height: 30px;
            width: auto;
        }
    }
`;

const Container = styled.div`
    background: ${({ theme: { colors } }) => colors.background};
    padding-top: 15px;
    padding-bottom: 15px;

    @media (min-width: 992px) {
        border-bottom: 2px solid ${({ theme: { colors } }) => colors.primary};
        padding-top: 50px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 25px;
        margin-bottom: 25px;
    }

    .ant-drawer-body {
        overflow-y: scroll;
        height: 100%;
    }
`;

const NavWrapper = styled.div`
    justify-content: space-between;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    align-items: center;
    min-height: 60px;

    }
`;

const UserWrapper = styled.div`
    text-align: right;
    font-size: 12px;

    .user-title {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .user-image {
        cursor: pointer;
        @media (max-width: 768px) {
            padding-right: 15px;
        }

        img {
            border: 2px solid ${({ theme: { colors } }) => colors.border};
            height: 30px;
            width: 30px;
            object-fit: cover;
            border-radius: 50%;
            background: ${({ theme: { colors } }) => colors.border};

            @media (min-width: 992px) {
                height: 48px;
                width: 48px;
            }
        }
    }
`;

// margin-bottom for mobile landscape support DO NOT REMOVE
const StyledMenu = styled.div`
    margin-bottom: 50px;

    .ant-menu-vertical {
        border: none !important;
    }
`;

const StyledBadge = styled.div`
    display: inline;
    position: absolute;
    ${({ theme: { rtl } }) => (rtl ? "left: 10px; " : "right: 10px;")};

    .ant-badge-count {
        font-weight: bold;
    }
    .ant-badge-dot {
        width: 10px;
        height: 10px;
    }
`;

const ProfileBadge = styled.div`
    .ant-badge-dot {
        width: 10px;
        height: 10px;
    }
`;

const HeaderText = styled.div`
    font-family: ${({ theme: { assets } }) => assets.headingFont};
    color: ${({ theme: { colors } }) => colors.primaryLight};
    font-weight: 600;
    font-size: 30px;
    padding: 0 15px;
`;

class Navbar extends Component {
    state = { drawer: false };

    showDrawer = () => {
        this.setState({ drawer: true });
    };

    hideDrawer = () => {
        this.setState({ drawer: false });
    };

    handleOrientationChange = () => {};

    render() {
        const {
            location,
            user,
            onLogout,
            unseen,
            theme,
            intl,
            sm,
            changeAccount
        } = this.props;
        const { drawer } = this.state;
        const drawerTitle = !!theme.menuTitle
            ? intl.formatMessage({ id: theme.menuTitle })
            : intl.formatMessage({ id: "menu.academicPassport" });

        return (
            <Container>
                <NavWrapper>
                    <Logo onClick={this.hideDrawer}>
                        <Link to="/">
                            <img src={getImagePath(theme.assets.logo)} alt="" />
                        </Link>
                    </Logo>

                    <HambugerMenu onClick={this.showDrawer} />
                    {!sm && !!theme.headerMessage && (
                        <HeaderText>
                            {intl.formatMessage({
                                id: theme.headerMessage
                            })}
                        </HeaderText>
                    )}
                    <UserWrapper>
                        <div className="user-image" onClick={this.hideDrawer}>
                            <Dropdown
                                overlay={<ProfileMenu user={user} theme={theme} onChangeAccount={changeAccount} />}
                                placement="bottomRight"
                            >
                                <Space>
                                    <Link to="/profile">
                                        <ProfileBadge>
                                            <Badge dot={unseen}>
                                                <img
                                                    src={
                                                        user.logoBlob ||
                                                        getImagePath(theme.assets.user)
                                                    }
                                                    alt=""
                                                />
                                            </Badge>
                                        </ProfileBadge>
                                    </Link>
                                    {canSwitchAccount(user) && <DownCircleFilled />}
                                </Space>
                            </Dropdown>
                        </div>
                    </UserWrapper>
                </NavWrapper>

                <Drawer
                    title={drawerTitle}
                    width={300}
                    style={{ overflow: "hidden" }}
                    placement={theme.rtl ? "right" : "left"}
                    closable={true}
                    onClose={this.hideDrawer}
                    visible={drawer}
                    bodyStyle={{ overflow: "auto" }}
                >
                    <StyledMenu>
                        <Menu
                            mode="vertical"
                            onSelect={this.hideDrawer}
                            selectedKeys={[location.pathname]}
                        >
                            <Menu.Item key="/">
                                <Link to={{ pathname: "/" }}>
                                    <FileTextOutlined />{" "}
                                    <FormattedMessage id={!!theme.dashboard ? theme.dashboard.title : 'menu.myCredentials'} />
                                </Link>
                            </Menu.Item>
                            {
                                !theme.dashboard && !!theme.media &&
                                <Menu.Item key="/media-files">
                                    <Link to={{ pathname: "/media-files" }}>
                                        <VideoCameraOutlined />{" "}
                                        <FormattedMessage id="menu.mediaFiles" />
                                    </Link>
                                </Menu.Item>
                            }
                            <Menu.Item key="/share">
                                <Link to={{ pathname: "/share" }}>
                                    <ShareAltOutlined />{" "}
                                    <FormattedMessage id="menu.share" />
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/profile">
                                <Link to={{ pathname: "/profile" }}>
                                    <UserOutlined />{" "}
                                    <FormattedMessage id="menu.profile" />
                                    <StyledBadge>
                                        <Badge dot={unseen} />
                                    </StyledBadge>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/history">
                                <Link to={{ pathname: "/history" }}>
                                    <ClockCircleOutlined />{" "}
                                    <FormattedMessage id="menu.history" />
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/faq">
                                <Link to={{ pathname: "/faq" }}>
                                    <QuestionCircleOutlined />{" "}
                                    <FormattedMessage id="menu.FAQ" />
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="/logout">
                                <Link
                                    id="logOutButton"
                                    onClick={onLogout}
                                    to={{ pathname: "/login" }}
                                >
                                    <LogoutOutlined />{" "}
                                    <FormattedMessage id="menu.logout" />
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </StyledMenu>
                </Drawer>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user.data,
    unseen: notificationSelectors.unviewed(state).length
});

const mapDispatchToProps = dispatch => ({
    onLogout: () => {
        dispatch(alertInfo(<FormattedMessage id="shared.youAreLoggedOut" />));
        dispatch(logout());
    },
    changeAccount: (username) => dispatch(changeAccount(username))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withSize(withTheme(injectIntl(props => <Navbar {...props} />)))));
