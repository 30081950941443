import { AUTH_CLEAR_ERRORS, AUTH_LOGOUT, AUTH_PREFIX, AUTH_SWITCH } from './constants';
import { API_ERROR, API_REQUEST, API_SUCCESS } from '../constants';
import { setLastVisitedUrl } from '../../helpers/lastVisitedUrl';

const initialState = {
    isAuthenticated: false,
    authCheckDone: false,
    error: null,
    isFetching: false
};

export const authReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case AUTH_PREFIX + API_REQUEST:
            return { ...state, isFetching: true };
        case AUTH_PREFIX + API_SUCCESS:
            return {
                ...state,
                isAuthenticated: !!(payload.data || {}).token,
                isFetching: false,
                authCheckDone: true
            };
        case AUTH_PREFIX + API_ERROR:
            setLastVisitedUrl();

            return {
                ...state,
                error: payload.data,
                isAuthenticated: false,
                isFetching: false,
                authCheckDone: true
            };
        case AUTH_SWITCH + API_REQUEST:
            return { ...state, isFetching: true };
        case AUTH_SWITCH + API_SUCCESS:
            return {
                ...state,
                isAuthenticated: !!(payload.data || {}).token,
                isFetching: false,
                authCheckDone: true
            };
        case AUTH_SWITCH + API_ERROR:
            setLastVisitedUrl();

            return {
                ...state,
                error: payload.data,
                isAuthenticated: false,
                isFetching: false,
                authCheckDone: true
            };
        case AUTH_LOGOUT:
            return { ...state, isAuthenticated: false };
        case AUTH_CLEAR_ERRORS:
            return { ...state, error: null };
        default:
            return state;
    }
};
