import React, { useRef, useEffect } from 'react';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    EmailShareButton,
    EmailIcon
} from 'react-share';

const SocialButtons = ({ url, title, round, width, height, onRefInit, imageUrl }) => {
    const facebookRef = useRef(null);
    const twitterRef = useRef(null);
    const linkedinRef = useRef(null);
    const emailRef = useRef(null);
    const image = imageUrl ? `<img src="${imageUrl}"  alt="${title}"/>` : '';

    useEffect(() => {
        if (onRefInit) {
            onRefInit({
                facebook: facebookRef?.current,
                twitter: twitterRef?.current,
                linkedin: linkedinRef?.current,
                email: emailRef?.current,
            });
        }
    }, [facebookRef, twitterRef, linkedinRef, emailRef]);

    return (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <FacebookShareButton url={url} quote={title} ref={facebookRef}>
                <FacebookIcon width={width} height={height} round={round} />
            </FacebookShareButton>
            <TwitterShareButton url={url} title={title} ref={twitterRef}>
                <TwitterIcon width={width} height={height} round={round} />
            </TwitterShareButton>
            <LinkedinShareButton url={url} title={title} ref={linkedinRef}>
                <LinkedinIcon width={width} height={height} round={round} />
            </LinkedinShareButton>
            <EmailShareButton url={url} subject={title} ref={emailRef}>
                <EmailIcon width={width} height={height} round={round} />
            </EmailShareButton>
        </div>
    )
}

export default SocialButtons;
