import styled from 'styled-components';

export default styled.span`
    color: ${({theme: {colors}}) => colors.primary};
    transition: opacity 0.7s;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`;
