export const getCategory = (category, match) => {
    if (!category || !match) {
        return null;
    }

    const url = getUrl(match);

    if (category.url === url) {
        return category;
    }

    let existCategory = null;
    (category.categories || []).map((category) => {
        if (existCategory === null) {
            existCategory = getCategory(category, match);
        }
    });

    return existCategory;
}

export const getUrl = (match) => {
    const { params: { locale } } = match;
    const url = match.url.replace(`/${locale}`, '');

    return url ? url : '/';
}
