import moment from 'moment';

export const groupBy = (objectArray, property) => {
    const array = [...objectArray];
    return array.reduce((acc, obj) => {
        var key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
};

export const momentToString = (data, format = "HH:mm on MMMM Do YYYY") =>
    data.format(format);

export const hasExpired = expiredAt => {
    return moment().diff(moment(expiredAt)) > 0;
};
