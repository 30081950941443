import { API_ERROR, API_REQUEST, API_SUCCESS } from '../constants';
import { BLOB_PREFIX } from './constants';

const initialState = {
    data: {},
    isFetching: false
};

export const blobReducer = (state = initialState, { type, payload, meta }) => {
    switch (type) {
        // single credential blob
        case BLOB_PREFIX + API_REQUEST:
            return { ...state, isFetching: meta.credentialId };
        case BLOB_PREFIX + API_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    [meta.credentialId]: {
                        ...state.data[meta.credentialId],
                        ...payload.data
                    }
                },
                isFetching: false
            };
        case BLOB_PREFIX + API_ERROR:
            return {
                ...state,
                error: payload.error,
                isFetching: false,
                count: 0
            };
        default:
            return state;
    }
};

export default blobReducer;
