import {
    DesktopOutlined,
    MailOutlined,
    PhoneOutlined
} from "@ant-design/icons";
import { Col, Row } from "antd";
import ContentUi from "./ui/Content";
import React from "react";

const Content = () => {
    return (
        <Row>
            <Col span={20} offset={2}>
                <ContentUi>
                    <h2>What is the UAEU Passport?</h2>
                    <div className="content-text-wrapper">
                        <p>
                            The UAEU Passport is a digital wallet that introduce
                            a new way for students and alumni to receive,
                            manage, and share their digital documents (i.e.
                            Diploma, Transcripts ...etc.).
                        </p>
                        <p>
                            You can request your digital credentials via
                            Documents Service on UAEU mobile app, or through
                            Online Services for{" "}
                            <a
                                href="https://my.uaeu.ac.ae/en/Pages/signin.aspx?appID=eservices"
                                title="Student"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Student
                            </a>
                            ,{" "}
                            <a
                                href="https://jspapps.uaeu.ac.ae/alumniweb/index.jsp"
                                title="Alumni"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Alumni
                            </a>
                            . Once the request is processed, you will be
                            receiving an email with the document(s), also you
                            can login to UAEU Passport to view and manage all
                            your approved digital documents.
                        </p>
                        <p>
                            <strong>In case you have question about</strong>{" "}
                            this service you can contact UAEU Admission and
                            Registration Department via:
                        </p>
                        <ul className="contact-list">
                            <li>
                                <PhoneOutlined /> +971 3 7134299 | +971 3
                                7136776
                            </li>
                            <li>
                                <MailOutlined />{" "}
                                <a
                                    href="mailto:Registration_department@uaeu.ac.ae"
                                    rel="noopener noreferrer"
                                >
                                    Registration_department@uaeu.ac.ae
                                </a>
                            </li>
                        </ul>
                        <p>
                            I
                            <strong>
                                f you have any technical difficulties
                            </strong>
                            , you can contact UAEU IT Helpdesk via:
                        </p>
                        <ul className="contact-list">
                            <li>
                                <PhoneOutlined /> +971-3-713 6111
                            </li>
                            <li>
                                <MailOutlined />{" "}
                                <a
                                    href="mailto:Helpdesk@uaeu.ac.ae"
                                    rel="noopener noreferrer"
                                >
                                    Helpdesk@uaeu.ac.ae
                                </a>
                            </li>
                            <li>
                                <DesktopOutlined />{" "}
                                <a
                                    href="https://doit.uaeu.ac.ae/"
                                    title="Online Support"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Online Support
                                </a>
                            </li>
                        </ul>
                    </div>
                </ContentUi>
            </Col>
        </Row>
    );
};

export default Content;
