import React, { Component } from "react";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import styled, { withTheme } from "styled-components";
import { getCategory } from "../../helpers/category";
import { List, Skeleton, Spin } from "antd";
import Category from './Category';

const StyledSkeleton = styled(Skeleton)`
    min-height: 210px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    align-items: center;
`;

class CategoriesList extends Component {
    state = {
        categories: [],
    };

    componentDidMount() {
        this.getCategories();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.url !== this.props.match.url) {
            this.getCategories();
        }
    }

    getCategories() {
        const { theme, match } = this.props;

        if (!theme.dashboard) {
            return [];
        }

        const category = getCategory(theme.dashboard, match);

        this.setState({
            categories: category ? category.categories : [],
        });
    }

    render() {
        const { theme } = this.props;
        const { categories } = this.state;

        if (!theme.dashboard) {
            return null;
        }

        if (categories.length === 0) {
            return null;
        }

        return (
            <div className="categories-wrapper">
                <Spin spinning={false}>
                    <StyledSkeleton
                        loading={false}
                        paragraph={{ rows: 6 }}
                        title={false}
                        avatar={false}
                        active
                    >
                        <List
                            grid={{
                                gutter: 16,
                                column: 4,
                                xl: 4,
                                lg: 3,
                                md: 2,
                                sm: 2,
                                xs: 1
                            }}
                            loading={false}
                            dataSource={categories}
                            renderItem={category => (
                                <Category category={category} />
                            )}
                        />
                    </StyledSkeleton>
                </Spin>
            </div>
        );
    }
}

export default withTheme(withRouter(CategoriesList));
