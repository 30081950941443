import React from 'react';
import { get } from 'lodash';
import { API_REQUEST } from '../constants';
import { apiRequestDeferred, apiResponse } from '../actions';
import { logout } from '../auth/actions';
import { camelizeKeys } from 'humps';
import { alertError } from '../alerts/actions';
import { AUTH_PREFIX } from '../auth/constants';

const api = ({ dispatch }) => next => action => {
    next(action);

    if (action.filter && action.filter === API_REQUEST) {
        const { request } = action.meta;

        request()
            .then(({ data }) => {
                dispatch(

                    apiResponse(camelizeKeys(data), {
                        ...action.meta,
                        success: true
                    })
                );
            })
            .catch(error => {
                if (error.response && error.response.status === 401 && action.type !== AUTH_PREFIX + API_REQUEST) {
                    dispatch(logout());
                }

                const message = get(error, 'data.message')
                    || get(error, 'response.data.message')
                    || error.message;

                // if token is expired error.
                if (message === 'Expired JWT Token') {
                    dispatch(apiRequestDeferred(action, action.meta));
                } else {
                    dispatch(
                        apiResponse(error, { ...action.meta, success: false })
                    );

                    dispatch(alertError(message));
                }
            });
    }
};

export default api;
