import React from "react";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { getTheme } from "@educhain/themes/src/";
import Main from "./Main";

const store = configureStore();
const theme = getTheme();

// expose store when run in Cypress
if (window.Cypress) {
    window.store = store;
}

const App = () => {
    return (
        <>
            <Helmet>
                <title> {theme.brand} | {theme.passport.pageSubtitle ? theme.passport.pageSubtitle : 'Passport'} </title>
            </Helmet>
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route exact path="/" component={Main} />
                        <Route path={`/:locale`} component={Main} />
                    </Switch>
                </Router>
            </Provider>
        </>
    );
};

export default App;
