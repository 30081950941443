import React, { useState } from "react";
import { List, Skeleton, Spin } from "antd";
import Credential from "./Credential";
import styled, { withTheme } from "styled-components";
import { FormattedMessage } from "react-intl";

const StyledSkeleton = styled(Skeleton)`
    min-height: 210px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    align-items: center;
`;

const NoCredentials = styled.div`
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    justify-content: center;
    min-height: 210px;

    .no-credentials {
        text-align: center;
        display: inline-block;
        max-width: 500px;
        margin: 50px;
    }
`;

const CredentialList = ({
    credentials,
    onView,
    onDownload,
    isFetching,
    theme
}) => {
    const [credentialId, setCredentialId] = useState(null);
    return (
        <div className="credentials-wrapper">
            {(credentials && credentials.length !== 0) || isFetching ? (
                <Spin spinning={isFetching}>
                    <StyledSkeleton
                        loading={isFetching}
                        paragraph={{ rows: 6 }}
                        title={false}
                        avatar={false}
                        active
                    >
                        <List
                            grid={{
                                gutter: 16,
                                column: 4,
                                xl: 4,
                                lg: 3,
                                md: 2,
                                sm: 2,
                                xs: 1
                            }}
                            loading={isFetching}
                            dataSource={credentials}
                            renderItem={credential => (
                                <Credential
                                    key={credential.id}
                                    selected={credential.id === credentialId}
                                    setSelected={() =>
                                        setCredentialId(credential.id)
                                    }
                                    credential={credential}
                                    onView={onView}
                                    onDownload={onDownload}
                                />
                            )}
                        />
                    </StyledSkeleton>
                </Spin>
            ) : (
                <NoCredentials>
                    <span className="no-credentials">
                        <p>
                            {!!theme.brand ? (
                                <FormattedMessage
                                    id="myc.params.missing.brand"
                                    values={{ institution: theme.brand }}
                                />
                            ) : (
                                <FormattedMessage
                                    id={theme.missingCredential}
                                />
                            )}
                        </p>
                    </span>
                </NoCredentials>
            )}
        </div>
    );
};
export default withTheme(CredentialList);
