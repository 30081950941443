import { API_ERROR, API_REQUEST, API_SUCCESS } from '../constants';
import { CREDENTIALS_PREFIX } from './constants';

const initialState = {
    data: [],
    isFetching: false,
    count: undefined
};

export const credentialReducer = (
    state = initialState,
    { type, payload, meta }
) => {
    switch (type) {
        // all credentials reducers
        case CREDENTIALS_PREFIX + API_REQUEST:
            return { ...state, isFetching: true };
        case CREDENTIALS_PREFIX + API_SUCCESS:
            return {
                ...state,
                data: payload.data,
                isFetching: false,
                count: payload.data.length
            };
        case CREDENTIALS_PREFIX + API_ERROR:
            return {
                ...state,
                error: payload.error,
                isFetching: false,
                count: 0
            };
        default:
            return state;
    }
};

export default credentialReducer;
