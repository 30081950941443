import React from "react";
import PropTypes from "prop-types";
import { hasExpired } from "../../helpers";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Link } from "@educhain/utils/lang";
import { CopyOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Modal, message } from "antd";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from "styled-components";
import {
    FormattedDate,
    FormattedMessage,
    FormattedTime,
    injectIntl
} from "react-intl";

const { confirm } = Modal;

const HistoryActionWrapper = styled.div`
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;

    @media screen and (max-width: 576px) {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        justify-content: center;
    }
`;

const HistoryAction = styled.span`
    button {
        width: 100%;
        @media screen and (min-width: 576px) {
            width: 100px;
        }
    }

    &:not(:last-child) {
        @media screen and (min-width: 576px) {
        ${({ theme: { rtl } }) =>
            rtl ? "margin-left: 25px; " : "margin-right: 25px;"};
                }
        }
        margin-bottom: 10px;
    }
`;

const HistoryItem = ({
    id,
    hash,
    isProtected,
    expiredAt,
    deletedAt,
    onDelete,
    intl
}) => {
    let { locale } = useParams();

    const showDeleteConfirm = deleteShared => {
        confirm({
            title: intl.formatMessage({ id: "history.deleteConfirmation" }),
            content: (
                <span>
                    {intl.formatMessage({ id: "history.deleteWarning" })}
                    <br />
                    <br />

                    {intl.formatMessage({
                        id: "history.deleteContinueConfirmation"
                    })}
                </span>
            ),
            okText: intl.formatMessage({ id: "shared.yes" }),
            okType: "danger",
            cancelText: intl.formatMessage({ id: "shared.no" }),
            onOk() {
                deleteShared();
            }
        });
    };
    return (
        <div id="history-item">
            {!deletedAt && !hasExpired(expiredAt) && (
                <span>
                    <p>
                        {/*TODO: refactor using intl hook*/}
                        {!isProtected ? (
                            <FormattedMessage id="share.notProtected" />
                        ) : (
                            <FormattedMessage id="share.protected" />
                        )}
                        {
                            expiredAt && (
                                <FormattedMessage
                                    id="share.expiresAt"
                                    values={{
                                        time: (
                                            <FormattedTime
                                                hour12={false}
                                                value={moment
                                                    .utc(expiredAt)
                                                    .local()}
                                            />
                                        ),
                                        date: (
                                            <FormattedDate
                                                value={expiredAt}
                                                year="numeric"
                                                month="long"
                                                day="numeric"
                                            />
                                        )
                                    }}
                                />
                            )
                        }
                    </p>
                    <HistoryActionWrapper>
                        <HistoryAction>
                            <Link
                                to={`/credentials/shared/${hash}`}
                                target="_blank"
                            >
                                <Button>
                                    <EyeOutlined />{" "}
                                    <FormattedMessage id="shared.view" />
                                </Button>
                            </Link>
                        </HistoryAction>
                        <HistoryAction>
                            <Button
                                onClick={() =>
                                    showDeleteConfirm(() => onDelete(id))
                                }
                            >
                                <DeleteOutlined />{" "}
                                <FormattedMessage id="shared.delete" />
                            </Button>
                        </HistoryAction>
                        <HistoryAction>
                            <CopyToClipboard
                                text={`${window.location.origin}/${locale}/credentials/shared/${hash}`}
                                onCopy={() => {
                                    message.success(intl.formatMessage({
                                        id: "shared.linkCopied"
                                    }));
                                }}
                            >
                                <Button
                                    className="history-action"
                                >
                                    <CopyOutlined />{" "}
                                    <FormattedMessage id="shared.copy" />
                                </Button>
                            </CopyToClipboard>
                        </HistoryAction>
                    </HistoryActionWrapper>
                </span>
            )}
            {/*TODO: replace text or dropdown*/}
            {/*{!deletedAt && hasExpired(expiredAt) && ""}*/}
            {/*{deletedAt && ""}*/}
        </div>
    );
};

HistoryItem.propTypes = {
    id: PropTypes.number,
    hash: PropTypes.string,
    isProtected: PropTypes.bool,
    expiredAt: PropTypes.string,
    createdAt: PropTypes.string,
    deletedAt: PropTypes.string,
    onDelete: PropTypes.func
};

HistoryItem.defaultProps = {
    id: 0,
    hash: "",
    isProtected: false,
    expiredAt: "",
    createdAt: "",
    deletedAt: "",
    onDelete: () => null
};

export default injectIntl(HistoryItem);
