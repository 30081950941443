import React, { Component } from "react";
import { connect } from "react-redux";
import { downloadCredentials } from "../../services/api";
import { loadCredentials } from "../../store/credential/actions";
import { loadCredentialBlob } from "../../store/blobs/actions";
import blobSelectors from "../../store/blobs/selectors";
import credentialSelectors from "../../store/credential/selectors";
import CredentialList from "./CredentialList";
import CredentialPDFPreview from "./CredentialPDFViewer";
import PageContent from "../../components/PageContent";
import { FormattedMessage } from "react-intl";
import CredentialSearch from "./CredentialSearch";
import { Redirect } from 'react-router-dom';
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { getAvailableLocale } from "../../helpers/getRTLAndNamedPath";
import { get } from "lodash";
import { withTheme } from "styled-components";
import CategoriesList from "../dashboard/CategoriesList";
import { getCategory } from "../../helpers/category";
import MediaFileViewer from '../mediaFiles/MediaFileViewer';

class CredentialsView extends Component {
    state = {
        selectedBlob: null,
        activeKey: "0",
        search: "",
        sort: "createdAt",
        order: true,
        credentials: null
    };
    handleSearchChange = e => {
        this.setState({ search: e.target.value });
    };

    handleSortChange = value => {
        this.setState({ sort: value, page: 1 });
    };

    handleOnSearch = () => {
        // console.log(this.state.credentials);
        // console.log(this.state.search);
    };

    handleOrderChange = value => {
        this.setState({ order: !!value, page: 1 });
    };

    handleFilterChange = e => {
        this.setState({ sort: e.target.value });
    };

    //TODO: look at above from historyview
    componentDidMount() {
        this.loadCredentials();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.url !== this.props.match.url) {
            this.loadCredentials();
        }
    }

    loadCredentials() {
        const { dispatch, match, theme } = this.props;
        const category = getCategory(theme.dashboard, match);

        dispatch(loadCredentials(category ? category.name : null));
    }

    getBlob = credentialId => {
        const { dispatch } = this.props;
        typeof this.props.blobs[credentialId] === "undefined" &&
            dispatch(loadCredentialBlob(credentialId));
    };

    handleView = credentialId => {
        document.body.style.overflow = "hidden";
        this.getBlob(credentialId);
        this.setState({ selectedBlob: credentialId });
    };

    handleDownload = credentialId => {
        downloadCredentials([credentialId]);
    };

    handleClose = () => {
        document.body.style.overflow = "auto";
        this.setState({ selectedBlob: null });
    };

    render() {
        const {
            allCredentials,
            isFetching,
            fetchingBlob,
            blobs,
            match,
            theme
        } = this.props;

        if (!theme.dashboard && match.url.includes('/credentials')) {
            return <Redirect to="/" />;
        }

        const { params: { locale } } = match;
        const { selectedBlob, search, sort, order } = this.state;
        const selectedCredential = allCredentials.find((credential) => credential.id === selectedBlob);
        const filteredCredentials = allCredentials.filter(cred => {
            let availableLocale = getAvailableLocale(locale);
            let loweredSearch = this.state.search.toLowerCase();
            let type = cred.type;
            const titles = get(cred, "credentialTitles", {})[availableLocale];
            //TODO: add this back in once the API returns default params (title included)
            let title = titles && titles[0] ? titles[0] : "";
            return (
                type.toLowerCase().includes(loweredSearch) ||
                title.toLowerCase().includes(loweredSearch)
            );
        });

        return (
            <>
                <PageContent
                    title={<FormattedMessage id="menu.myCredentials" />}
                    search={
                        <CredentialSearch
                            search={search}
                            sort={sort}
                            order={order}
                            onOrderDirectionChange={this.handleOrderChange}
                            onSortChange={this.handleSortChange}
                            onSearchChange={this.handleSearchChange}
                            onSearch={this.handleOnSearch}
                        />
                    }
                >
                    <CategoriesList />
                    <CredentialList
                        isFetching={isFetching}
                        fetchingBlob={fetchingBlob}
                        credentials={filteredCredentials}
                        onView={this.handleView}
                        onDownload={this.handleDownload}
                    />
                    {!!selectedCredential && selectedCredential.type.toLowerCase() === 'badge'
                        ? <MediaFileViewer
                            onClose={this.handleClose}
                            mediaFile={!!blobs[selectedBlob] && blobs[selectedBlob]}
                            onDownload={() => this.handleDownload(selectedBlob)}
                            visible={!!blobs[selectedBlob] && !fetchingBlob}
                          />
                        : <CredentialPDFPreview
                            onClose={this.handleClose}
                            blob={!!blobs[selectedBlob] && blobs[selectedBlob].blob}
                            onDownload={() => this.handleDownload(selectedBlob)}
                            visible={!!blobs[selectedBlob] && !fetchingBlob}
                          />
                    }
                </PageContent>
            </>
        );
    }
}

const mapStateToProps = state => ({
    allCredentials: credentialSelectors.all(state),
    isFetching: credentialSelectors.isFetching(state),
    isCredentials: credentialSelectors.exists(state),
    blobs: blobSelectors.blobs(state),
    fetchingBlob: blobSelectors.isFetching(state)
});

export default connect(mapStateToProps)(withTheme(withRouter(CredentialsView)));
