import { API_REQUEST } from "../constants";
import {
    getUser as getUserRequest,
    uploadImage as uploadImageRequest,
    deleteImage as deleteImageRequest,
    changePassword as changePasswordRequest,
    changeEmail as changeEmailRequest
} from "../../services/api";
import {
    USER_PREFIX,
    USER_IMAGE,
    USER_IMAGE_DELETE,
    USER_PASSWORD_CHANGE,
    USER_EMAIL_CHANGE
} from "./constants";

export const loadUser = () => ({
    type: USER_PREFIX + API_REQUEST,
    filter: API_REQUEST,
    meta: {
        prefix: USER_PREFIX,
        request: () => getUserRequest()
    }
});

export const uploadImage = data => ({
    type: USER_IMAGE + API_REQUEST,
    filter: API_REQUEST,
    meta: {
        prefix: USER_IMAGE,
        request: () => uploadImageRequest(data),
        image: data.base64
    }
});

export const deleteImage = () => ({
    type: USER_IMAGE_DELETE + API_REQUEST,
    filter: API_REQUEST,
    meta: {
        prefix: USER_IMAGE_DELETE,
        request: () => deleteImageRequest()
    }
});

export const changePassword = (
    currentPassword,
    newPassword,
    newPasswordConfirmation
) => ({
    type: USER_PASSWORD_CHANGE + API_REQUEST,
    filter: API_REQUEST,
    meta: {
        prefix: USER_PASSWORD_CHANGE,
        request: () =>
            changePasswordRequest(
                currentPassword,
                newPassword,
                newPasswordConfirmation
            )
    }
});

export const changeEmail = (
    newEmail,
    newEmailConfirmation,
    locale
) => ({
    type: USER_EMAIL_CHANGE + API_REQUEST,
    filter: API_REQUEST,
    meta: {
        prefix: USER_EMAIL_CHANGE,
        request: () =>
            changeEmailRequest(
                newEmail,
                newEmailConfirmation,
                locale
            )
    }
});
