import React, { Component } from "react";
import renderRoutes from "@educhain/utils/src/helpers/renderRoutes";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";

import { getTheme } from "@educhain/themes/src/";

import routes from "./routes";
import { authCheck } from "./store/auth/actions";
import { Switch, Redirect } from "react-router-dom";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { loadUser } from "./store/user/actions";
import { loadNotifications } from "./store/notifications/actions";

import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/ar";
import "@formatjs/intl-relativetimeformat/locale-data/es";
import messagesEn from "@educhain/utils/lang/en.json";
import messagesAr from "@educhain/utils/lang/ar.json";
import messagesEs from "@educhain/utils/lang/es.json";
import { ConfigProvider } from "antd";
import {
    AR_LOCALE,
    EN_LOCALE,
    ES_LOCALE,
    INTL_LTR,
    INTL_RTL
} from "@educhain/utils/index.js";
import { getBrowserLanguage } from "@educhain/utils/lang/getBrowserLanguage";
import { isRTLEnabledAtPath } from "./helpers/getRTLAndNamedPath";
import GlobalStyle from '@educhain/ui/src/GlobalStyle';

const messages = {
    ar: messagesAr,
    en: messagesEn,
    es: messagesEs,
};

const theme = getTheme();

class Main extends Component {
    componentDidMount() {
        const { authCheck } = this.props;
        authCheck(); // normally you would check if the token is valid by contacting the server
    }

    componentDidUpdate(prevProps) {
        const { isAuth, loadUser, loadNotifications } = this.props;
        // fetch items when user has logged in and token is available
        if (isAuth && prevProps.isAuth !== this.props.isAuth) {
            loadUser();
            loadNotifications();
        }
    }

    render() {
        const {
            authCheckDone,
            match: {
                params: { locale }
            }
        } = this.props;

        const useRTL = isRTLEnabledAtPath();

        const availableLanguages = useRTL
            ? [AR_LOCALE, EN_LOCALE, ES_LOCALE]
            : [EN_LOCALE];

        const language = getBrowserLanguage(availableLanguages);

        // language defaults to english
        const languageWithoutRegionCode = !language
            ? EN_LOCALE
            : language.toLowerCase().split(/[_-]+/)[0];

        if (!locale || !availableLanguages.includes(locale)) {
            const location = window.location.pathname || "";
            if (useRTL) {
                return (
                    <Redirect to={`/${languageWithoutRegionCode}${location}`} />
                );
            } else {
                return (
                    <Redirect
                        to={`${location.replace(location.split("/")[1], "en")}`}
                    />
                );
            }
        }

        const direction =
            locale.toLowerCase() === AR_LOCALE ? INTL_RTL : INTL_LTR;

        return (
            <ConfigProvider direction={direction}>
                <ThemeProvider
                    theme={{
                        ...theme,
                        rtl: locale.toLowerCase() === AR_LOCALE
                    }}
                >
                    <>
                        <GlobalStyle />
                        <IntlProvider
                            locale={locale.toLowerCase()}
                            messages={messages[locale.toLowerCase()]}
                        >
                            {authCheckDone ? (
                                <Switch> {renderRoutes(routes)} </Switch>
                            ) : (
                                <div>loading</div>
                            )}
                        </IntlProvider>
                    </>
                </ThemeProvider>
            </ConfigProvider>
        );
    }
}

const mapStateToProps = state => ({
    authCheckDone: state.auth.authCheckDone,
    isAuth: state.auth.isAuthenticated
});

const mapDispatchToProps = dispatch => ({
    authCheck: () => dispatch(authCheck()),
    loadUser: () => dispatch(loadUser()),
    loadNotifications: () => dispatch(loadNotifications())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
