import React, { Component } from "react";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { CloseOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import { FormattedMessage } from "react-intl";
import {
    Control,
    HelpTable,
    PDFDocumentWrapper,
    PDFMenu,
    PDFWrapper,
    Spinner,
    Wrapper,
    ModalTitleWrapper,
    PDFPageControls
} from "./styled";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { arabicToLeft } from "@educhain/utils/lang";
import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import { ComponentRTLSwitcher } from "../../helpers/componentRTLSwitcher";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFControl = ({ onClick, type }) => (
    <Control onClick={onClick}>
        <LegacyIcon type={type} />
    </Control>
);

const DEFAULT_SCALE = 0.8;

class PDFViewer extends Component {
    state = {
        numPages: null,
        pageNumber: 1,
        menubar: false,
        scale: DEFAULT_SCALE,
        width: null,
        rotate: 0,
        page: {},
        help: false
    };

    wrapperRef = null;

    componentDidUpdate(prevProps) {
        const { files } = this.props;
        if (prevProps.files && prevProps.files !== files) {
            this.setState({ pageNumber: 1 });
        }
    }

    setWrapperRef = node => {
        this.wrapperRef = node;
    };

    onDocumentLoadSuccess = ({ numPages }) => {
        this.handleReset();
        this.setState({
            numPages
        });
    };

    nextPage = () => {
        const { numPages, pageNumber } = this.state;
        this.setState({
            pageNumber: pageNumber === numPages ? 1 : pageNumber + 1
        });
    };

    prevPage = () => {
        const { numPages, pageNumber } = this.state;
        this.setState({
            pageNumber: pageNumber === 1 ? numPages : pageNumber - 1
        });
    };

    setRotation = rotate => {
        this.setState({ rotate: rotate >= 0 ? rotate % 360 : 270 });
    };

    setScale = scale => {
        if (scale >= 0.1 && scale <= 3) {
            this.setState({ scale });
        }
    };

    handleLoad = page => {
        this.setFitToScreen();
        this.setState({
            page
        });
    };

    handleCancel = e => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ help: false });
    };

    handleHelp = () => {
        this.setState({ help: true });
    };

    // TODO we can probably deprecate this
    handleReset = () => {
        this.setState({
            scale: DEFAULT_SCALE,
            rotate: 0,
            fit: false,
            pageNumber: 1
        });
    };

    // TODO we can probably deprecate this
    setFitToScreen = () => {
        this.setState({
            fit: !this.state.fit,
            scale: DEFAULT_SCALE,
            rotate: 0
        });
    };

    getPdfDimensions = () => {
        const { width, height } = this.props;
        if (!width || !height) {
            return null;
        }
        return height / width > 1 ? { width } : { height };
    };

    render() {
        const {
            onDownload,
            width,
            height,
            files,
            onPageChange,
            fileIndex,
            match: {
                params: { locale }
            }
        } = this.props;
        const { pageNumber, numPages, scale, rotate, help } = this.state;

        const modalTitle = (
            <ModalTitleWrapper>
                <FormattedMessage id="pdf.modal.title" />{" "}
                <CloseOutlined onClick={this.handleCancel} />
            </ModalTitleWrapper>
        );

        const isPagination = files && files.length > 1;
        const dimensions = this.getPdfDimensions(); // set width or height to the minimum of the two
        return (
            <Wrapper>
                {dimensions && (
                    <PDFWrapper
                        ref={this.setWrapperRef}
                        style={{ height, width }}
                    >
                        <PDFDocumentWrapper>
                            <Document
                                file={files[fileIndex]}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                                rotate={rotate}
                                loading={
                                    <Spinner>
                                        <SyncOutlined spin />
                                    </Spinner>
                                }
                            >
                                <Page
                                    pageNumber={pageNumber}
                                    scale={scale}
                                    // width={width}
                                    height={height - 100}
                                    {...dimensions}
                                    onLoadSuccess={this.handleLoad}
                                    loading={null}
                                    renderAnnotationLayer={false}
                                />
                            </Document>
                        </PDFDocumentWrapper>
                    </PDFWrapper>
                )}
                <PDFMenu>
                    {isPagination && (
                        <PDFControl
                            onClick={() => onPageChange(fileIndex - 1)}
                            type={arabicToLeft(locale, true)}
                        />
                    )}
                    <PDFControl
                        onClick={() => this.setRotation(rotate + 90)}
                        type="reload"
                    />
                    <PDFControl
                        onClick={() => this.setScale(scale + 0.1)}
                        type="zoom-in"
                    />
                    <PDFControl
                        onClick={() => this.setScale(scale - 0.1)}
                        type="zoom-out"
                    />
                    <PDFControl onClick={onDownload} type="download" />
                    {/* TODO deprecate */}
                    {/*<PDFControl*/}
                    {/*    onClick={this.handleReset}*/}
                    {/*    type={*/}
                    {/*        scale > DEFAULT_SCALE*/}
                    {/*            ? "fullscreen-exit"*/}
                    {/*            : "fullscreen"*/}
                    {/*    }*/}
                    {/*/>*/}
                    {/* TODO probably need to move this somewhere else RE mocks, or just remove it if we can use */}
                    {/*<PDFControl onClick={this.handleHelp} type="question" />*/}
                    {isPagination && (
                        <PDFControl
                            onClick={() => onPageChange(fileIndex + 1)}
                            type={arabicToLeft(locale)}
                        />
                    )}
                </PDFMenu>
                {numPages > 1 && (
                    <PDFPageControls>
                        <ComponentRTLSwitcher
                            comp1={<LeftOutlined onClick={this.prevPage} />}
                            comp2={<RightOutlined onClick={this.nextPage} />}
                            children={`${pageNumber}  /  ${numPages}`}
                        />
                    </PDFPageControls>
                )}
                <Modal
                    closable={false}
                    title={modalTitle}
                    visible={help}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            <FormattedMessage id="pdf.modal.gotIt" />
                        </Button>
                    ]}
                >
                    <HelpTable>
                        <tbody>
                            <tr>
                                <td>
                                    <PDFControl type="reload" />
                                </td>
                                <td>
                                    <FormattedMessage id="pdf.modal.rotate" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <PDFControl type="zoom-in" />
                                    <span className="divider">/</span>
                                    <PDFControl type="zoom-out" />
                                </td>
                                <td>
                                    <FormattedMessage id="pdf.modal.zoom" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <PDFControl type="download" />
                                </td>
                                <td>
                                    <FormattedMessage id="pdf.modal.download" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <PDFControl
                                        type={
                                            scale > DEFAULT_SCALE
                                                ? "fullscreen-exit"
                                                : "fullscreen"
                                        }
                                    />
                                </td>
                                <td>
                                    <FormattedMessage id="pdf.modal.original" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <PDFControl type="left" />
                                    <span className="divider">/</span>
                                    <PDFControl type="right" />
                                </td>
                                <td>
                                    <FormattedMessage id="pdf.modal.page" />
                                </td>
                            </tr>
                        </tbody>
                    </HelpTable>
                </Modal>
            </Wrapper>
        );
    }
}

export default withRouter(PDFViewer);
