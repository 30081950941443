import React from "react";
import PageContent from "../../components/PageContent";
import FAQ from "@educhain/themes/src/default/components/FAQ";
import ReptonFAQ from "@educhain/themes/src/repton_dxb/components/FAQ";
import FAQContent from '@educhain/ui/src/faq/FAQContent';
import { FormattedMessage, injectIntl } from 'react-intl';
import { THEME_REPTON_AD, THEME_REPTON_DXB, THEME_REPTON_BARSHA, ThemeComponentSwitcher } from '@educhain/themes/src';
import { withTheme } from 'styled-components';
import { withRouter } from '@educhain/utils/src/helpers/withRouter';

const components = {
    [THEME_REPTON_AD]: ReptonFAQ,
    [THEME_REPTON_DXB]: ReptonFAQ,
    [THEME_REPTON_BARSHA]: ReptonFAQ,
};

const FaqComponent = ({ theme, intl }) => {
    return (
        <ThemeComponentSwitcher
            components={components}
            defaultComponent={FAQ}
            childProps={{
                theme,
                intl
            }}
        />
    );
}

const Faq = ({ theme, intl, match: { params: { locale } } }) => {
    return (
        <PageContent title={<FormattedMessage id="faq.faq" />}>
            {
                !!theme.passport.faq &&
                <FAQContent
                    intl={intl}
                    locale={locale}
                    faq={theme.passport.faq}
                    faqComponent={<FaqComponent intl={intl} theme={theme}/>}
                />
            }
        </PageContent>
    );
};

export default withRouter(withTheme(injectIntl(Faq)));
