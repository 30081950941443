import React, { Component } from "react";

import {
    DeleteOutlined,
    EditOutlined,
    HomeOutlined,
    LockOutlined,
    NotificationOutlined,
    SyncOutlined
} from "@ant-design/icons";

import { Row, Col, Card, Upload, Button, Modal, message } from "antd";
import { connect } from "react-redux";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import {
    uploadImage,
    deleteImage,
    changePassword,
    changeEmail
} from "../../store/user/actions";
import PageContent from "../../components/PageContent";
import styled, { withTheme } from "styled-components";
import notificationSelectors from "../../store/notifications/selectors";
import { markNotificationAsViewed } from "../../store/notifications/actions";
import NotificationList from "./NotificationList";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ChangePasswordForm from "./ChangePasswordForm";
import ChangeEmailForm from "./ChangeEmailForm";
import { FormattedMessage, injectIntl } from "react-intl";
import { getImagePath } from "@educhain/utils/theme/getImagePath";

const confirm = Modal.confirm;

// TODO Student ID, Birth date and address
const ProfileImage = styled.div`
    padding: 30px;

    .profile-image {
        position: relative;
        transition: display 0.7s;

        img {
            border: 2px solid ${({ theme: { colors } }) => colors.background};
            background: ${({ theme: { colors } }) => colors.background};
            object-fit: cover;
            width: 100%;
            border-radius: 50%;
        }

        .profile-image-edit {
            margin: 10px;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flexbox;
            display: -o-flex;
            display: flex;
            justify-content: center;

            @media (max-width: 996px) {
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
            }

            .profile-img-btn {
                margin: 10px;
            }
        }
    }
`;

const ProfileTitle = styled.span`
    font-size: 14px;
    opacity: 0.7;
`;

const ProfileInfoWrapper = styled.div`
    .ant-row {
        padding-bottom: 10px;
    }
`;

const CircularCrop = styled.div`
    .ReactCrop__crop-selection {
        border-radius: 50%;
    }
`;

const Wrapper = styled.div`
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
`;

const Spinner = styled.div`
    padding: 50px;
    font-size: 40px;
    .anticon {
        color: #393838;
    }
`;

const CropWrapper = styled.div`
    .ReactCrop__image {
        max-height: 100%;
    }
`;

const Fetching = () => (
    <Wrapper>
        <h3>
            <FormattedMessage id="profile.processing" />
        </h3>
        <Spinner>
            <SyncOutlined spin />
        </Spinner>
    </Wrapper>
);

const ImageCropper = ({
    visible,
    uploading,
    src,
    crop,
    onImageLoaded,
    onCropComplete,
    onChange,
    onSubmit,
    onCancel,
    intl
}) => (
    <Modal
        title={intl.formatMessage({ id: "profile.picture" })}
        visible={visible}
        onCancel={onCancel}
        closable={!uploading}
        maskClosable={false}
        footer={[
            <Button key="back" onClick={onCancel} disabled={uploading}>
                <FormattedMessage id="shared.cancel" />
            </Button>,
            <Button
                key="submit"
                type="primary"
                onClick={onSubmit}
                disabled={uploading}
            >
                <FormattedMessage id="profile.change" />
            </Button>
        ]}
    >
        <Row>
            <Col span={24}>
                {!uploading ? (
                    <CircularCrop>
                        <p>
                            <FormattedMessage id="profile.crop" />
                        </p>
                        {src && (
                            <CropWrapper>
                                <ReactCrop
                                    src={src}
                                    crop={crop}
                                    onImageLoaded={onImageLoaded}
                                    onComplete={onCropComplete}
                                    onChange={onChange}
                                    minWidth={20}
                                    minHeight={20}
                                    keepSelection={true}
                                />
                            </CropWrapper>
                        )}
                    </CircularCrop>
                ) : (
                    <Fetching />
                )}
            </Col>
        </Row>
    </Modal>
);

const defaultCrop = {
    aspect: 1,
    width: 30,
    unit: '%',
};

class ProfileView extends Component {
    state = {
        height: null,
        crop: defaultCrop,
        percentCrop: null,
        editing: false,
        file: null,
        img: null,
        visible: false,
        showAll: false,
        passwordFields: {
            currentPassword: null,
            newPassword: null,
            newPasswordConfirmation: null,
        },
        emailFields: {
            newEmail: null,
            newEmailConfirmation: null,
        }
    };

    imageRef = null;
    cropImageRef = null;

    handleResize = () => {
        this.setState({ height: this.imageRef.getBoundingClientRect().width });
    };

    componentDidMount() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    }

    componentDidUpdate(prevProps) {
        const { error, passwordChanged, emailChanged } = this.props;

        if (error && error !== prevProps.error) {
            this.showValidationError(error);
        }

        if (passwordChanged && passwordChanged !== prevProps.passwordChanged) {
            this.handlePasswordFormChange({
                currentPassword: null,
                newPassword: null,
                newPasswordConfirmation: null,
            });
        }

        if (emailChanged && emailChanged !== prevProps.emailChanged) {
            this.handleEmailFormChange({
                newEmail: null,
                newEmailConfirmation: null,
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    showValidationError(error) {
        const data = error.response.data;

        if (!data.errors) {
            return message.error(error.message);
        }

        Object.keys(data.errors).map(field => {
            const errors = data.errors[field];

            return errors.map(fieldError => message.error(fieldError));
        });
    }

    handleCrop = (crop, percentCrop) => {
        this.setState({ crop, percentCrop });
    };

    handleFileUpload = info => {
        const { intl } = this.props;
        const { file } = info;
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error(intl.formatMessage({ id: "profile.imageSize" }));
        } else {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    this.setState({
                        file,
                        img: reader.result,
                        visible: true
                    });
                    resolve();
                };
            });
        }
    };

    handleImageLoaded = (image) => {
        this.cropImageRef = image;
    }

    handleFileSumbit = () => {
        const { upload } = this.props;
        const { crop } = this.state;
        const filename = "cropped";

        if (this.cropImageRef && crop.width && crop.height) {
            this.getCroppedImg(this.cropImageRef, crop).then(base64 => {
                upload({
                    base64,
                    file: this.base64StringtoFile(base64, filename),
                    filename
                });
                this.setState({ file: null, visible: false, crop: defaultCrop });
            });
        }
    };

    handleCancel = () => {
        this.setState({ file: null, visible: false, crop: defaultCrop });
    };

    handleShowAll = () => {
        this.setState({ showAll: !this.state.showAll });
    };

    handlePasswordFormChange = changedFields => {
        this.setState(({ passwordFields }) => ({
            passwordFields: { ...passwordFields, ...changedFields }
        }));
    };

    handlePasswordFormSubmit = () => {
        const { changePassword } = this.props;
        const {
            currentPassword,
            newPassword,
            newPasswordConfirmation
        } = this.state.passwordFields;

        changePassword(
            currentPassword,
            newPassword,
            newPasswordConfirmation
        );
    };

    handleEmailFormChange = changedFields => {
        this.setState(({ emailFields }) => ({
            emailFields: { ...emailFields, ...changedFields }
        }));
    };

    handleEmailFormSubmit = () => {
        const { changeEmail, match } = this.props;
        const {
            newEmail,
            newEmailConfirmation
        } = this.state.emailFields;
        const { locale } = match.params;

        changeEmail(
            newEmail,
            newEmailConfirmation,
            locale
        );
    };

    base64StringtoFile = (base64String, filename) => {
        var arr = base64String.split(",");
        var mime = arr[0].match(/:(.*?);/)[1];
        var bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        try {
            return new File([u8arr], filename, { type: mime });
        } catch (err) {
            return new Blob([u8arr], { type: mime });
        }
    };

    getCroppedImg = (image, crop) => {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            const pixelRatio = window.devicePixelRatio;
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;

            canvas.width = crop.width * pixelRatio * scaleX;
            canvas.height = crop.height * pixelRatio * scaleY;

            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width * scaleX,
                crop.height * scaleY
            );

            resolve(canvas.toDataURL("image/jpeg"));
        });
    };

    render() {
        const {
            user,
            uploading,
            deleteImage,
            unviewed,
            markAsSeen,
            passwordChanged,
            passwordChanging,
            passwordChangingError,
            emailChanged,
            emailChanging,
            emailChangingError,
            intl,
            match: {
                params: { locale }
            },
            theme
        } = this.props;

        const { height, crop, img, visible, showAll, passwordFields, emailFields } = this.state;

        const showDeleteConfirm = onDelete => {
            confirm({
                title: intl.formatMessage({ id: "profile.removalConfirm" }),
                content: intl.formatMessage({ id: "profile.picture.confirm" }),
                okText: intl.formatMessage({ id: "shared.yes" }),
                okType: "danger",
                cancelText: intl.formatMessage({ id: "shared.no" }),
                onOk() {
                    onDelete();
                }
            });
        };
        return (
            <PageContent
                title={intl.formatMessage({ id: "profile.myProfile" })}
            >
                <ImageCropper
                    visible={visible}
                    uploading={uploading}
                    src={img}
                    crop={crop}
                    onImageLoaded={this.handleImageLoaded}
                    onComplete={this.handleCropComplete}
                    onChange={this.handleCrop}
                    onCancel={this.handleCancel}
                    onSubmit={this.handleFileSumbit}
                    intl={intl}
                />
                <Row>
                    <Col xs={24} sm={24} md={8}>
                        <ProfileImage>
                            <div className="profile-image">
                                <img
                                    ref={node => (this.imageRef = node)}
                                    style={{ height: height }}
                                    src={
                                        user.logoBlob ||
                                        getImagePath(theme.assets.user)
                                    }
                                    alt=""
                                />
                                <div className="profile-image-edit text-center">
                                    <Upload
                                        beforeUpload={() => false}
                                        onChange={this.handleFileUpload}
                                        showUploadList={false}
                                        accept=".png,.jpg,.jpeg"
                                        className="profile-img-btn"
                                    >
                                        <Button>
                                            <EditOutlined />{" "}
                                            <FormattedMessage id="profile.change" />
                                        </Button>
                                    </Upload>
                                    <div className="profile-img-btn">
                                        <Button
                                            onClick={() =>
                                                showDeleteConfirm(deleteImage)
                                            }
                                        >
                                            <DeleteOutlined />{" "}
                                            <FormattedMessage id="shared.delete" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </ProfileImage>
                    </Col>
                    <Col xs={24} sm={24} md={16}>
                        <Card
                            title={
                                <ProfileTitle>
                                    <NotificationOutlined />{" "}
                                    <FormattedMessage id="profile.notifications" />
                                </ProfileTitle>
                            }
                            bordered={false}
                            style={{ width: "100%" }}
                        >
                            <NotificationList
                                notifications={unviewed}
                                showAll={showAll}
                                onShowAll={this.handleShowAll}
                                markAsSeen={markAsSeen}
                                locale={locale}
                            />
                        </Card>
                        <Card
                            title={
                                <ProfileTitle>
                                    <HomeOutlined />{" "}
                                    <FormattedMessage id="profile.contactInfo" />
                                </ProfileTitle>
                            }
                            bordered={false}
                            style={{ width: "100%" }}
                        >
                            <ProfileInfoWrapper>
                                <Row>
                                    <Col xs={10} sm={6}>
                                        <FormattedMessage id="profile.email" />
                                    </Col>
                                    <Col xs={14} sm={18}>
                                        {user.email}
                                    </Col>
                                </Row>
                            </ProfileInfoWrapper>
                        </Card>

                        <Card
                            title={
                                <ProfileTitle>
                                    <LockOutlined />{" "}
                                    <FormattedMessage id="shared.security" />
                                </ProfileTitle>
                            }
                            bordered={false}
                            style={{ width: "100%" }}
                        >
                            {user.passwordChangeAllowed && (
                                <ChangePasswordForm
                                    {...passwordFields}
                                    passwordChanged={passwordChanged}
                                    passwordChanging={passwordChanging}
                                    passwordChangingError={
                                        passwordChangingError
                                    }
                                    onChange={this.handlePasswordFormChange}
                                    onSubmit={this.handlePasswordFormSubmit}
                                />
                            )}
                            {theme.value !== 'uaeu' && (
                                <ChangeEmailForm
                                    {...emailFields}
                                    emailChanged={emailChanged}
                                    emailChanging={emailChanging}
                                    emailChangingError={
                                        emailChangingError
                                    }
                                    user={user}
                                    onChange={this.handleEmailFormChange}
                                    onSubmit={this.handleEmailFormSubmit}
                                />
                            )}
                        </Card>
                    </Col>
                </Row>
            </PageContent>
        );
    }
}

const mapStateToProps = state => {
    const { user } = state;

    return {
        user: user.data,
        uploading: user.uploading,
        uploaded: user.uploaded,
        error: user.error,
        passwordChanged: user.passwordChanged,
        passwordChanging: user.passwordChanging,
        passwordChangingError: user.passwordChangingError,
        emailChanged: user.emailChanged,
        emailChanging: user.emailChanging,
        emailChangingError: user.emailChangingError,
        unviewed: notificationSelectors.unviewed(state)
    };
};

const mapDispatchToProps = dispatch => ({
    upload: data => dispatch(uploadImage(data)),
    deleteImage: data => dispatch(deleteImage()),
    changePassword: (currentPassword, newPassword, newPasswordConfirmation) =>
        dispatch(
            changePassword(
                currentPassword,
                newPassword,
                newPasswordConfirmation
            )
        ),
    changeEmail: (newEmail, newEmailConfirmation, locale) =>
        dispatch(
            changeEmail(
                newEmail,
                newEmailConfirmation,
                locale
            )
        ),
    markAsSeen: notificationId =>
        dispatch(markNotificationAsViewed(notificationId))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTheme(injectIntl(ProfileView))));
