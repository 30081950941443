export const getCredentialParameterByName = (credentialParameters, parameterName, locale) => {
    for (let obj of credentialParameters[locale] || []) {
        if (obj.name === parameterName && obj.type !== "hidden") {
            return { label: obj.label, value: obj.value };
        }
    }

    return { label: parameterName, value: '' };
};

export const convertDate = (intl, date) => {
    return date
        ? intl.formatDate(date, {
            year: "numeric",
            month: "long",
            day: "numeric"
        })
        : intl.formatMessage({ id: "myc.None" });
};
