import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
    margin: auto;
    background-color: #f5f5f5;
`;

export const ModalTitleWrapper = styled.div`
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const PDFWrapper = styled.div`
    overflow: auto;
   // background-color: ${({ theme: { colors } }) => colors.background};
    background-color: #C4C4C4;
    border: 2px solid ${({ theme: { colors } }) => colors.background};
`;

export const PDFMenu = styled.div`
    background: transparent;
    position: absolute;
    bottom: -20px;
    left: 50%;
    width: 300px;
    z-index: 1;
    color: white;
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transform: translateX(-50%);
`;

export const PDFDocumentWrapper = styled.div`
    direction: ltr;
    height: 100%;

    .react-pdf__Document {
        height: 100%;
    }

    .react-pdf__Page {
        height: 100%;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -o-flex;
        display: flex;
    }

    .react-pdf__Page__canvas {
        margin: auto;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
            0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
`;

export const PDFPageControls = styled.div`
    position: absolute;
    z-index: 1;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);

    height: 15px;
    width: 125px;
    padding: 0px 10px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 40px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    color: white;
    cursor: pointer;
    transition: all 0.7s;
    user-select: none;
    margin-bottom: 5px;
    align-items: center;
    margin-left: auto;
`;

export const Control = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme: { colors } }) => colors.secondary};
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    color: white;
    cursor: pointer;
    transition: all 0.7s;
    user-select: none; /* Standard syntax */
    margin-bottom: 5px;

    &:hover {
        background: rgba(0, 0, 0, 0.8);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2),
            0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    }
    .anticon {
        margin: auto;
        font-size: ${({ size = 16 }) => size}px;
    }
`;

export const HelpTable = styled.table`
    tbody > tr > td {
        line-height: 30px;
        padding-bottom: 15px;
        &:first-child {
            width: 100px;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flexbox;
            display: -o-flex;
            display: flex;
        }
    }

    .divider {
        padding-left: 5px;
        padding-right: 5px;
    }
`;

export const Spinner = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .anticon {
        font-size: 30px;
    }
`;
