import React from "react";
import videojs from "video.js";
import { getCurrentUserToken } from '../services/storage';

videojs.Vhs.xhr.beforeRequest = (options) => {
    options.headers = options.headers || {};
    options.headers.Authorization = "Bearer " + getCurrentUserToken('tokens');
    options.withCredentials = true;

    return options;
}

export default class VideoPlayer extends React.Component {
    componentDidMount() {
        this.player = videojs(this.videoNode, { ...this.props }, function onPlayerReady() {
            //console.log("onPlayerReady", this);
        });

        if (this.videoNode) {
            this.videoNode.setAttribute("webkit-playsinline", true);
            this.videoNode.setAttribute("playsinline", true);
        }
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }

    render() {
        return (
            <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                <video
                    ref={node => (this.videoNode = node)}
                    className="video-js"
                    style={{ width: '100%', height: '100%' }}
                />
            </div>
        );
    }
}
