import React from "react";
import { Alert, Spin } from "antd";
import styled, { withTheme } from "styled-components";
import PublicLayout from "../../components/PublicLayout";
import { getImagePath } from "@educhain/utils/theme/getImagePath";

const Logo = styled.div`
    margin: 15px;
    img {
        height: 60px;

        @media (min-width: 768px) {
            height: 75px;
        }
    }
`;

const Spacing = styled.div`
    margin-bottom: 25px;
`;

const LoginWrapper = styled.div`
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    margin: auto;

    h1 {
        font-size: 2.15em;
    }

    p {
        text-align: center;
    }
`;

const StyledLoginForm = styled.div`
    padding: 20px 24px;
    .ant-form-item {
        margin-bottom: 0px;
    }

    .ant-legacy-form-item-control {
        text-align: center;
    }

    .login-form-button {
        width: 150px;
    }

    
`;

class DefaultLoginView extends React.Component {
    render() {
        const { loading, alert, theme } = this.props;

        return (
            <PublicLayout>
                <LoginWrapper>
                    <Logo>
                        <img src={getImagePath(theme.assets.logo)} alt="" />
                    </Logo>
                    {alert && (
                        <Spacing>
                            <Alert message={alert} type="error" />
                        </Spacing>
                    )}
                    <Spin spinning={loading}>
                        <StyledLoginForm>{this.props.children}</StyledLoginForm>
                    </Spin>
                </LoginWrapper>
            </PublicLayout>
        );
    }
}

export default withTheme(DefaultLoginView);
