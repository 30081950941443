import styled from "styled-components";

const LoginWrapper = styled.div`
    font-size: 100%;

    @media only screen and (max-width: 660px) {
        font-size: 80%;
    }

    .red-overlay,
    .blue-overlay {
        position: relative;
    }

    .red-overlay::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 91, 91, 0.8);
    }

    .blue-overlay::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(37, 61, 85, 0.9);
    }

    .left-background {
        background: url(https://www.uaeu.ac.ae/images/uaeu_cresent.jpg) center
            center no-repeat;
        background-size: cover;
    }

    .right-background {
        background: url(https://www.uaeu.ac.ae/images/mypassport_login.png)
            center center no-repeat;
        background-size: cover;
    }

    .section {
        padding-right: 15px;
        padding-left: 15px;
        @media only screen and (max-width: 990px) {
            width: 100%;
            min-height: 50vh;
        }
    }
`;

export default LoginWrapper;
