import { createSelector } from 'reselect';

export const blobs = createSelector(
    state => state.blobs.data,
    data => data
);

export const isFetching = createSelector(
    state => state.blobs.isFetching,
    data => data
);

export default {
    blobs,
    isFetching
};
