import styled from "styled-components";

export const InputWrapper = styled.div`
    .ant-input {
        border: none;
        border-bottom: 1px solid #d9d9d9;
        border-radius: 0px;
        width: 300px;
    }

    .ant-input:hover {
        border-color: #5baaf5;
        border-right-width: 1px !important;
    }

    .ant-input:focus,
    .ant-input-focused {
        border-color: #5baaf5;
        border-right-width: 1px !important;
        outline: 0;
        box-shadow: 0 4px 2px -2px rgba(49, 138, 233, 0.2);
    }
`;
