import React from 'react';
import { Alert as AntAlert } from 'antd';
import { connect } from 'react-redux';
import { alertClear } from '../store/alerts/actions';
import styled from 'styled-components';

const AlertWrapper = styled.div`
    margin: 20px 0;
`;

const Alert = ({ type, message, dismiss }) =>
    message && type ? (
        <AlertWrapper>
            <AntAlert
                message={message}
                type={type}
                closable
                onClose={dismiss}
            />
        </AlertWrapper>
    ) : (
        <span />
    );

const mapStateToProps = state => ({
    type: state.alert.type,
    message: state.alert.message
});
const mapDispatchToProps = dispatch => ({
    dismiss: () => dispatch(alertClear())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Alert);
