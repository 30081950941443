import { API_REQUEST } from "../constants";
import {
    login as loginRequest,
    switchAccount as switchAccountRequest
} from '../../services/api';
import {
    AUTH_CHECK,
    AUTH_LOGOUT,
    AUTH_PREFIX,
    AUTH_REFRESH_TOKEN,
    AUTH_CLEAR_ERRORS,
    AUTH_SWITCH,
    AUTH_CHANGE
} from "./constants";
import { removeToken, removeCurrentUsername } from "../../services/storage";

export const login = data => {
    return {
        type: AUTH_PREFIX + API_REQUEST,
        filter: API_REQUEST,
        meta: {
            prefix: AUTH_PREFIX,
            request: () => loginRequest(data)
        }
    };
};

export const authCheck = () => {
    return { type: AUTH_CHECK };
};

export const refreshToken = () => {
    return { type: AUTH_REFRESH_TOKEN };
};

export const logout = () => {
    removeToken("tokens");
    removeToken("refreshTokens");
    removeCurrentUsername();

    return { type: AUTH_LOGOUT };
};

export const clearErrors = () => {
    return { type: AUTH_CLEAR_ERRORS };
};

export const switchAccount = (username) => ({
    type: AUTH_SWITCH + API_REQUEST,
    filter: API_REQUEST,
    meta: {
        prefix: AUTH_SWITCH,
        request: () => switchAccountRequest(username)
    }
});

export const changeAccount = (username) => ({
    type: AUTH_CHANGE,
    payload: username,
});
