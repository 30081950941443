import React from "react"
import { isString } from "lodash"
import { Link as RouterLink } from "react-router-dom"
import { withRouter } from '../src/helpers/withRouter';

const Link = ({to: toProp, match, location, staticContext, children, ...props}) => {
    const {params: {locale}} = match
    const prefix = "/" + locale
    let to
    if(isString(toProp)){
        to = toProp.startsWith(prefix) ? toProp : `${prefix}${toProp}`
    } else {
        to = {...toProp, pathname: toProp.pathname.startsWith(prefix) ? toProp.pathname :`${prefix}${toProp.pathname}`}
    }
    return (
        <RouterLink to={to} {...props}> {children} </RouterLink>
    )
}

export default withRouter(Link)
