import { createSelector } from 'reselect';

export const all = createSelector(
    state => state.sharing,
    sharing => sharing
);

export const isFetching = createSelector(
    state => state.sharing.isFetching,
    isFetching => isFetching
);

export const getHash = createSelector(
    state => state.sharing.hash,
    hash => hash
);

export const isAllowed = createSelector(
    state => state.sharing.isAllowed,
    isAllowed => isAllowed
);

export default {
    all,
    isFetching,
    getHash,
    isAllowed
};
