import { getToken } from '../services/storage';

export const isParentUserAuthenticated = (user) => {
    return !!user.parentUser && getToken('tokens', user.parentUser.username);
}

export const canSwitchAccount = (user) => {
    const hasChildrenAccounts = user.childrenUsers && user.childrenUsers.length > 0;

    return isParentUserAuthenticated(user) || hasChildrenAccounts;
}
