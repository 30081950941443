import { API_SUCCESS } from '../constants';
import { AUTH_CHECK, AUTH_PREFIX, AUTH_CHANGE, AUTH_SWITCH } from '../auth/constants';
import { switchAccount } from '../auth/actions';
import { getCurrentUserToken, setCurrentUsername, getToken, setToken } from '../../services/storage';
import { apiResponse } from '../actions';

const auth = ({ dispatch }) => next => action => {
    next(action); // pass action to next middleware / all reducers

    const { type, payload } = action;
    const isAuthSuccess = type === AUTH_PREFIX + API_SUCCESS || type === AUTH_SWITCH + API_SUCCESS;

    if (isAuthSuccess && payload.data) {
        // set token to local storage if auth success action gets dispatched
        const {
            data: { token, refreshToken, username }
        } = payload;

        if (username) {
            setCurrentUsername(username);
        }

        if (token) {
            setToken('tokens', username, token);
        }

        if (refreshToken) {
            setToken('refreshTokens', username, refreshToken);
        }

        if (type === AUTH_SWITCH + API_SUCCESS) {
            window.location.reload();
        }
    }

    if (type === AUTH_CHECK) {
        const token = getCurrentUserToken('tokens');

        if (!token) {
            dispatch(apiResponse({ token }, { prefix: AUTH_PREFIX, success: false }));

            return;
        }

        dispatch(apiResponse({ token }, { prefix: AUTH_PREFIX, success: true }));
    }

    if (type === AUTH_CHANGE) {
        if (getToken('tokens', payload)) {
            setCurrentUsername(payload);

            window.location.reload();

            return;
        }

        dispatch(switchAccount(payload));
    }
};

export default auth;
