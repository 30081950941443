import React from "react";
import { Layout, Row, Col } from "antd";
import styled from "styled-components";
const { Content } = Layout;

const Header = styled.div`
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.primary};
    min-height: 60px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0 25px 0;

    @media (max-width: 571px) {
        flex-wrap: wrap;
    }
`;
const Title = styled.h3`
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 0px;
`;
const Wrapper = styled.div`
    padding: 0 20px 0 20px;
`;

export default props => (
    <Row id="pageContent">
        <Col span={24} offset={0}>
            <Content className="fill">
                {props.title && (
                    <Wrapper>
                        <Header>
                            <Title>{props.title}</Title>

                            {props.search && <>{props.search}</>}
                        </Header>
                    </Wrapper>
                )}
                <div className="content-wrapper">{props.children}</div>
            </Content>
        </Col>
    </Row>
);
