import React, { useState } from "react";
import { List, Card, Spin } from "antd";
import styled from "styled-components";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { compose } from "redux";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import { useHover } from "../../hooks/hover";
import { injectIntl } from "react-intl";
import VideoPlayer from '../../components/VideoPlayer';

const VIDEO_MIME_TYPE = 'video/mp4';

const MediaFileImage = styled.div`
    width: 100%;
    height: 240px;
    cursor: pointer;
    text-align: center;
    display: flex;
    padding: 5px;
    img {
        object-fit: contain;
        margin: auto;
        margin-top: 0;
        width: 100%;
        height: 100%;
    }
`;
const Table = styled.table``;
const MediaFileCover = styled.div`
    border: 2px solid
        ${({ selected, theme: { colors } }) =>
            selected ? colors.primary : "#f0f0f0"};
    border-bottom: none;
    transition: all 0.3s ease-in-out;

    &:hover {
        border: 2px solid ${({ theme: { colors } }) => colors.primary};
        border-bottom: none;
    }

    border-radius: 15px 15px 0 0;
    background: rgba(79, 79, 79, 0.25);

    .controls {
        position: absolute;
        height: 40px;
        font-size: 25px;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .anticon {
            transition: all 0.3s ease-in-out;
            padding: 0 5px;
            cursor: pointer;
            display: ${({ selected }) => (selected ? "block" : "none")};
            color: ${({ theme }) => theme.colors.primary};

            &:hover {
                color: ${({ theme }) => theme.colors.primaryLight};
            }

            svg {
                filter:
                    drop-shadow(-0.5px -0.5px 0.5px white)
                    drop-shadow(0.5px -0.5px 0.5px white)
                    drop-shadow(0.5px 0.5px 0.5px white)
                    drop-shadow(-0.5px 0.5px 0.5px white);
            }
        }
    }
`;

const StyledCard = styled(Card)`
    border-radius: 15px;
    cursor: pointer;
    border: 2px solid
        ${({ selected, theme: { colors } }) =>
            selected ? colors.primary : "#f0f0f0"};
    transition: all 0.3s ease-in-out;

    &:hover {
        border: 2px solid ${({ theme: { colors } }) => colors.primary};
    }

    .ant-card-cover {
        margin: -2px;
    }

    .ant-card-body {
        border-top: 2px solid #f0f0f0;
        padding: 15px 10px 10px;

        .ant-card-meta-detail > div:not(:last-child) {
            margin-bottom: 0px;
        }
    }

    .video-js {
        .vjs-big-play-button {
            top: 50%;
            left: 50%;
            margin-left: -1.5em;
            margin-top: -0.815em;
        }
    }
`;

const MediaFile = ({
    mediaFile,
    navigate,
    onView,
    onDownload,
    selected,
    setSelected,
    match: {
        params: { locale }
    },
    location: { search },
    intl
}) => {
    const [hoverRef, isHovered] = useHover();
    const [fetching, setFetching] = useState(false);
    const {
        id,
        blob,
        title,
        mimeType
    } = mediaFile;

    const videoJsOptions = {
        autoplay: false,
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        width: 720,
        height: 300,
        controls: true,
        sources: [
            {
                src: blob,
                type: 'video/mp4',
            },
        ],
    };

    return (
        <Spin spinning={fetching}>
            <List.Item>
                <span
                    ref={hoverRef}
                    onClick={() => setSelected(selected ? null : id)}
                >
                    <StyledCard
                        key={id}
                        selected={selected || isHovered}
                        style={{ minHeight: 300 }}
                        cover={
                            <MediaFileCover selected={selected || isHovered}>
                                <div className="controls" style={{ zIndex: '10' }}>
                                    {mimeType !== VIDEO_MIME_TYPE && <EyeOutlined onClick={() => onView(id)} />}
                                    <DownloadOutlined
                                        onClick={() => onDownload(id)}
                                    />
                                </div>
                                <MediaFileImage
                                    className="credential-collapse"
                                    onClick={() => selected && mimeType !== VIDEO_MIME_TYPE && onView(id)}
                                >
                                    {mimeType === VIDEO_MIME_TYPE
                                        ? <VideoPlayer {...videoJsOptions} />
                                        : <img src={blob} alt={title} style={{ width: '100%' }} />}
                                </MediaFileImage>
                            </MediaFileCover>
                        }
                    >
                        <Card.Meta
                            title={title}
                        />
                    </StyledCard>
                </span>
            </List.Item>
        </Spin>
    );
};

export default compose(injectIntl, withRouter)(MediaFile);
