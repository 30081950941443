import React, { Component } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Form } from "antd";
import "@ant-design/compatible/assets/index.css";
import { message, Button, Input, Spin } from "antd";
import { get } from "lodash";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";

const ButtonWrapper = styled.div`
    margin-bottom: 15px;

    button {
        + button {
            margin-left: 15px;
        }
    }
`;

const InlineButtonWrapper = styled.div`
    display: inline-block;

    + div {
        margin-left: 15px;
    }
`;

class ChangeEmailForm extends Component {
    state = {
        showForm: false,
        parentUserMessage: null,
    };

    componentDidUpdate(prevProps, prevState) {
        const { emailChanged, user } = this.props;
        //TODO: AR translation
        if (prevProps.emailChanged !== emailChanged && emailChanged) {
            message.success("A confirmation link has been sent to your currently registered email.");

            this.setState({
                showForm: false
            });
        }

        if (!prevState.showForm && this.state.showForm && user && user.parentUser) {
            const parentUserMessage = message.info('Note: if you update email record for the account it will be unlinked from your account. Please do not forget set the password for the account first', 0);

            this.setState({ parentUserMessage });
        }

        if (prevState.showForm && !this.state.showForm && user && user.parentUser) {
            this.clearMessages();
        }
    }

    componentWillUnmount() {
        this.clearMessages();
    }

    clearMessages() {
        if (this.state.parentUserMessage === null) {
            return;
        }

        this.state.parentUserMessage();
        this.setState({ parentUserMessage: null });
    }

    toggleForm = () => {
        this.setState(({ showForm }) => {
            return {
                showForm: !showForm
            };
        });
    };

    onSubmit = () => {
        const { onSubmit } = this.props;

        this.clearMessages();

        onSubmit();
    }

    render() {
        const {
            intl,
            emailChanging,
            emailChangingError,
            newEmail,
            newEmailConfirmation,
            onChange
        } = this.props;

        const { showForm } = this.state;
        const error = emailChangingError
            ? get(emailChangingError, "response.data.message")
            : [];

        return (
            <>
                {showForm && (
                    <Form
                        layout="vertical"
                        style={{ marginTop: 24 }}
                        initialValues={{
                            newEmail,
                            newEmailConfirmation,
                        }}
                        onFinish={this.onSubmit}
                        onValuesChange={(fields) => {
                            onChange(fields);
                        }}
                    >
                        <Form.Item
                            name="newEmail"
                            label={intl.formatMessage({ id: 'profile.newEmail' })}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    type: "email",
                                    message: intl.formatMessage({ id: 'profile.emailRequirement' }),
                                },
                            ]}
                        >
                            <Input
                                type="email"
                                autoComplete="new-password"
                                disabled={emailChanging}
                            />
                        </Form.Item>
                        <Form.Item
                            name="newEmailConfirmation"
                            dependencies={["newEmail"]}
                            hasFeedback
                            label={intl.formatMessage({ id: 'profile.confirmNewEmail' })}
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newEmail') === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error(
                                            intl.formatMessage({ id: 'profile.newEmailMatch' })
                                        ));
                                    },
                                }),
                                {
                                    required: true,
                                    message: intl.formatMessage({ id: 'profile.newEmailMatch' }),
                                }
                            ]}
                        >
                            <Input
                                type="email"
                                autoComplete="new-password"
                                disabled={emailChanging}
                            />
                        </Form.Item>
                        <Form.Item>
                            <ButtonWrapper>
                                {
                                    error && (
                                        <div className="ant-typography ant-typography-danger">
                                            {error}
                                        </div>
                                    )
                                }
                                <Button
                                    onClick={this.toggleForm}
                                    disabled={emailChanging}
                                >
                                    <FormattedMessage id="shared.cancel" />
                                </Button>

                                <Button
                                    htmlType="submit"
                                    disabled={emailChanging}
                                >
                                    <FormattedMessage id="shared.apply" />
                                    &nbsp;
                                    {emailChanging && (
                                        <Spin
                                            indicator={
                                                <LoadingOutlined
                                                    style={{ fontSize: 14 }}
                                                    spin
                                                />
                                            }
                                        />
                                    )}
                                </Button>
                            </ButtonWrapper>
                        </Form.Item>
                    </Form>
                )}
                {!showForm && (
                    <InlineButtonWrapper>
                        <Button
                            onClick={this.toggleForm}
                            disabled={emailChanging}
                        >
                            <FormattedMessage id="shared.changeEmail" />
                        </Button>
                    </InlineButtonWrapper>
                )}
            </>
        );
    }
}

export default injectIntl(ChangeEmailForm);
