import matchRoutes from "@educhain/utils/src/helpers/matchRoutes";
import routes, { NAMED_ROUTES } from "../routes";

export const getNamedPath = () => {
    const match = typeof window !== 'undefined' ? matchRoutes(routes, window.location.pathname)[0].route.path : null;

    for (let [key, value] of Object.entries(NAMED_ROUTES)) {
        if (value === match) {
            return key;
        }
    }
    return "";
};
