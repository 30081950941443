import React from "react";
import { List, Card, Spin } from "antd";
import styled from "styled-components";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { Link } from "@educhain/utils/lang";
import { getImagePath } from '@educhain/utils/theme/getImagePath';

const StyledCard = styled(Card)`
    border-radius: 15px;
    cursor: pointer;
    border: none;
    transition: all 0.3s ease-in-out;

    &:hover {
        border: none;
    }

    .ant-card-cover {
        margin: -2px;
    }

    .ant-card-body {
        padding: 0;
        .ant-card-meta-detail > div:not(:last-child) {
            margin-bottom: 0px;
        }
    }
`;

const Category = ({
    category,
    intl
}) => {
    return (
        <Spin spinning={false}>
            <List.Item>
                <Link to={category.url}>
                    <StyledCard
                        key={category.url}
                        selected={false}
                        style={{ minHeight: 300 }}
                    >
                        <div style={{ textAlign: 'center' }}>
                            <img src={getImagePath(category.image)} alt={category.title} style={{ height: '300px' }} />
                        </div>
                        {category.title && <Card.Meta title={intl.formatMessage({ id: category.title })} />}
                    </StyledCard>
                </Link>
            </List.Item>
        </Spin>
    );
};

export default compose(injectIntl, withRouter)(Category);
