// TODO: to delete once backend handles translation
export const translateType = type => {
    const typeMap = {
        diploma: "myc.params.diploma",
        letter: "myc.params.letter",
        certificate: "myc.params.certificate",
        transcript: "myc.params.transcript",
        'moe attested': "myc.params.transcript_moe",
        badge: "myc.params.badge",
        other: "myc.params.other",
    };

    return typeMap[type.toLowerCase()];
};
