import React from "react";
import { Link } from "@educhain/utils/lang";
import { Button } from "antd";
import styled, { withTheme } from "styled-components";
import { FormattedMessage } from "react-intl";

const Wrapper = styled.div`
    padding: 25px;
    text-align: center;
`;

//TODO remove uaeu
const NoCredentials = ({ theme }) => {
    return (
        <Wrapper>
            <p>
                {!!theme.missingCredential ? (
                    <FormattedMessage id={theme.missingCredential} />
                ) : (
                    <FormattedMessage id="myc.params.missing" />
                )}{" "}
            </p>
            <Link to="/">
                <Button>
                    <FormattedMessage id="shared.returnHome" />
                </Button>
            </Link>
        </Wrapper>
    );
};

export default withTheme(NoCredentials);
