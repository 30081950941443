import React from "react";
import { connect } from "react-redux";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { Row, Col, Layout as AntLayout } from "antd";
import Navbar from "../components/Navbar";
import { Footer } from "@educhain/ui/src/footer/Footer";
import renderRoutes from "@educhain/utils/src/helpers/renderRoutes";
import { alertClear } from "../store/alerts/actions";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { isRTLEnabled } from "@educhain/utils/lang";
import { getNamedPath } from "../services/path";

const Wrapper = styled.span`
    .footer {
        margin: 20px 0;
    }
`;

class Layout extends React.Component {
    componentDidUpdate(prevProps) {
        const { dispatch, location } = this.props;
        if (location !== prevProps.location) {
            dispatch(alertClear());
        }
    }
    render() {
        const { route, intl } = this.props;
        return (
            <Wrapper>
                <Row className="content">
                    <Col
                        md={{ span: 22, offset: 1 }}
                        lg={{ span: 20, offset: 2 }}
                        xl={{ span: 18, offset: 3 }}
                        sm={24}
                        flex="auto"
                    >
                        <AntLayout>
                            <Navbar />
                            {renderRoutes(route.routes)}
                        </AntLayout>
                    </Col>
                </Row>
                <Row>
                    <Col
                        md={{ span: 22, offset: 1 }}
                        lg={{ span: 20, offset: 2 }}
                        xl={{ span: 18, offset: 3 }}
                        sm={24}
                        flex="auto"
                    >
                        <Footer
                            className="footer"
                            style={{ marginTop: 20 }}
                            redirectName={intl.formatMessage({
                                id: "myc.verifyCredential"
                            })}
                            redirectPath={process.env.REACT_APP_VP_BASE_URL}
                            isRTLEnabled={isRTLEnabled(getNamedPath())}
                        />
                    </Col>
                </Row>
            </Wrapper>
        );
    }
}

export default injectIntl(withRouter(connect()(Layout)));
