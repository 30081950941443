import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CaptchaWrapper = styled.div`
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Captcha = ({ onChange, active }) => {
    return active ? (
        <CaptchaWrapper>
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={onChange}
            />
        </CaptchaWrapper>
    ) : null;
};

Captcha.propTypes = {
    onChange: PropTypes.func.isRequired,
    active: PropTypes.bool
};

Captcha.defaultProps = {
    active: true
};

export default Captcha;
