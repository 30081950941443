import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Spin } from "antd";
import ShareForm from "./ShareForm";
import ShareComplete from "./ShareComplete";
import PageContent from "../../components/PageContent";
import Alert from "../../components/Alert";
import credentialSelectors from "../../store/credential/selectors";
import { loadCredentials } from "../../store/credential/actions";
import { alertClear } from "../../store/alerts/actions";
import moment from "moment";
import PropTypes from "prop-types";
import NoCredentials from "./NoCredentials";
import ProgressBar from "./ProgressBar";
import {
    createShared,
    sharedCreateIsAllowedCheck
} from "../../store/sharing/actions";
import sharingSelectors from "../../store/sharing/selectors";
import { FormattedMessage } from "react-intl";

class ShareView extends Component {
    static propTypes = {
        credentials: PropTypes.array,
        isFetching: PropTypes.bool,
        isCredentials: PropTypes.bool,
        loadCredentials: PropTypes.func,
        clearAlerts: PropTypes.func,
        isCreateAllowedCheck: PropTypes.func,
        isCreateAllowed: PropTypes.bool
    };

    static defaultProps = {
        credentials: [],
        isFetching: false,
        isCredentials: false,
        loadCredentials: () => {},
        clearAlerts: () => {},
        isCreateAllowedCheck: () => {},
        isCreateAllowed: true
    };
    stepSize = 4;

    steps = [
        <FormattedMessage id="share.subheader" />,
        <FormattedMessage id="share.selectCredentials" />,
        <FormattedMessage id="share.additionalOptions" />
    ];

    state = {
        step: 0,
        hash: null,
        fields: {
            name: { value: null },
            message: { value: null },
            password: { value: null },
            confirmPassword: { value: null },
            credentials: { value: [] },
            expiryDate: { value: moment().add(1, "days") },
            expiryTime: { value: moment() }
        },
        selectedCredentials: []
    };

    componentDidMount() {
        const {
            loadCredentials,
            isCredentials,
            isCreateAllowedCheck
        } = this.props;
        isCredentials && loadCredentials();
        isCreateAllowedCheck();
    }

    componentDidUpdate({ hash: oldHash }) {
        const { hash } = this.props;
        const { step } = this.state;
        if (oldHash !== hash && hash) {
            this.nextStep(step + 1);
        }
    }

    nextStep = newStep => {
        this.props.clearAlerts();
        // move to top of page
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        this.setState({ step: newStep });
    };

    combineExpireDates = () => {
        const { fields } = this.state;

        if (fields.expiryDate.value) {
            return moment(
                fields.expiryDate.value.format("YYYY-MM-DD") +
                " " +
                fields.expiryTime.value.format("HH:mm:ss")
            ).format()
        } else {
            return null;
        }
    };

    handleSubmit = () => {
        const { fields } = this.state;
        const { createShared } = this.props;
        const expiredAt = this.combineExpireDates();

        createShared({
            name: fields.name.value,
            password: fields.password.value,
            message: fields.message.value,
            expiredAt,
            credentials: fields.credentials.value
        });
    };

    handleFormChange = changedFields => {
        this.setState(({ fields }) => ({
            fields: { ...fields, ...changedFields }
        }));
    };

    render() {
        const { step, fields } = this.state;
        const { credentials, isFetching, hash, isCreateAllowed } = this.props;
        return (
            <PageContent title={<FormattedMessage id="share.title" />}>
                <Spin spinning={isFetching}>
                    {credentials.length > 0 || isFetching ? (
                        <>
                            <ProgressBar step={step} steps={this.steps} />
                            <Row>
                                <Col sm={24} md={{ span: 16, offset: 4 }}>
                                    <Alert />

                                    {step !== this.stepSize - 1 &&
                                        isCreateAllowed && (
                                            <ShareForm
                                                {...fields}
                                                credentialsList={credentials}
                                                step={step}
                                                stepSize={this.stepSize}
                                                onNextStep={this.nextStep}
                                                onChange={this.handleFormChange}
                                                onSubmit={this.handleSubmit}
                                            />
                                        )}

                                    {step === this.stepSize - 1 && (
                                        <ShareComplete hash={hash} />
                                    )}
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <NoCredentials />
                    )}
                </Spin>
            </PageContent>
        );
    }
}

const mapStateToProps = state => ({
    credentials: credentialSelectors.all(state),
    isFetching: credentialSelectors.isFetching(state),
    isCredentials: credentialSelectors.exists(state),
    hash: sharingSelectors.getHash(state),
    isCreateAllowed: sharingSelectors.isAllowed(state)
});

const mapDispatchToProps = dispatch => ({
    loadCredentials: () => dispatch(loadCredentials()),
    createShared: data => dispatch(createShared(data)),
    clearAlerts: () => dispatch(alertClear()),
    isCreateAllowedCheck: () => dispatch(sharedCreateIsAllowedCheck())
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareView);
