import styled from "styled-components";

const Content = styled.div`
    padding: 100px 0;

    @media only screen and (max-width: 660px) {
        padding: 60px 0;
    }

    h2 {
        font-size: 2.45em;
        margin-bottom: 25px;
        line-height: 1.36;
        color: ${({ theme: { colors } }) => colors.primaryDark};
        font-weight: 700;

        @media only screen and (max-width: 660px) {
            font-size: 1.8em;
            font-weight: 600;
        }
    }

    p {
        font-size: 1.125em;
        font-weight: 300;
        color: black;
        line-height: 2;
    }

    .contact-list {
        padding-left: 0;
        list-style: none;
        margin-top: 0;
        margin-bottom: 10px;

        .anticon {
            font-size: 16px;
            padding-right: 3px;
        }

        li {
            padding: 10px 0px;
        }

        a {
            transition: all 0.3s ease-in-out;
            text-decoration: none;
            color: #2a2a2a;
        }

        a:hover,
        a:focus,
        a:active,
        a.active {
            color: ${({ theme: { colors } }) => colors.primaryDark};
        }
    }
`;

export default Content;
