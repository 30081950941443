import React from "react";
import PropTypes from "prop-types";
import { hasExpired } from "../../helpers";
import styled from "styled-components";
import { Link } from "@educhain/utils/lang";
import { withSize } from "@educhain/ui/src/hoc/withSize";
import decode from "unescape";
import { FormattedMessage, injectIntl } from "react-intl";

const HistoryHeader = styled.div`
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0%;

    h3 {
        margin: 0px;
        flex-grow: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 10px;
    }

    .details {
        font-size: 12px;
        opacity: 0.7;
        padding: 0px 15px;
        margin-left: auto;
        min-width: 160px;
    }
`;

const MobileHistoryHeader = ({
    hash,
    name,
    isProtected,
    isExpired,
    isDeleted,
    headerWidth,
    details = null
}) => (
    <HistoryHeader>
        <h3 className="credential-item-title" style={{ maxWidth: headerWidth }}>{decode(name)}</h3>
        {details}
        {!isDeleted ? (
            <Link to={`/credentials/shared/${hash}`} target="_blank">
                {isProtected && !isExpired && (
                    <HistoryStatus
                        color="#52c41a"
                        text={<FormattedMessage id="shared.protected" />}
                    />
                )}
                {!isProtected && !isExpired && (
                    <HistoryStatus
                        color="#faad14"
                        text={<FormattedMessage id="shared.open" />}
                    />
                )}
                {isExpired && (
                    <HistoryStatus
                        color="#f5222d"
                        text={<FormattedMessage id="shared.expired" />}
                    />
                )}
            </Link>
        ) : (
            <HistoryStatus
                color="lightgray"
                text={<FormattedMessage id="shared.deleted" />}
            />
        )}
    </HistoryHeader>
);

const Status = styled.div`
    color: white;
    background-color: ${({ color }) => color};
    height: 32px;
    padding: 0px 10px;
    line-height: 32px;
    border-radius: 5px;
    min-width: 100px;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 1px;
    text-align: center;
    transition: all 0.7s;

    &:hover {
        opacity: 0.7;
    }
`;

const HistoryStatus = ({ color, text }) => (
    <Status color={color}>{text}</Status>
);

const PanelHeader = ({
    name,
    hash,
    isProtected,
    expiredAt,
    deletedAt,
    createdAt,
    md,
    sm,
    intl
}) => {
    const headerWidth = sm ? '160px' : (md ? '500px' : null);

    return (
        <MobileHistoryHeader
            name={name}
            hash={hash}
            isProtected={isProtected}
            isExpired={hasExpired(expiredAt)}
            isDeleted={!!deletedAt}
            headerWidth={headerWidth}
            details={
                !md && (
                    <div className="details">
                        {!deletedAt &&
                            !hasExpired(expiredAt) &&
                            intl.formatMessage(
                                { id: "share.createdAt" },
                                {
                                    time: intl.formatTime(createdAt),
                                    date: intl.formatDate(createdAt)
                                }
                            )}
                        {!deletedAt &&
                            hasExpired(expiredAt) &&
                            intl.formatMessage(
                                { id: "share.alreadyExpiredAt" },
                                {
                                    time: intl.formatTime(expiredAt),
                                    date: intl.formatDate(expiredAt)
                                }
                            )}
                        {deletedAt &&
                            intl.formatMessage(
                                { id: "share.deletedAt" },
                                {
                                    time: intl.formatTime(deletedAt),
                                    date: intl.formatDate(deletedAt)
                                }
                            )}
                    </div>
                )
            }
        />
    );
};

PanelHeader.propTypes = {
    id: PropTypes.number,
    hash: PropTypes.string,
    isProtected: PropTypes.bool,
    expiredAt: PropTypes.string,
    createdAt: PropTypes.string,
    deletedAt: PropTypes.string,
    md: PropTypes.bool
};

PanelHeader.defaultProps = {
    id: 0,
    hash: "",
    isProtected: false,
    expiredAt: "",
    createdAt: "",
    deletedAt: "",
    md: false
};

export default injectIntl(withSize(PanelHeader));
