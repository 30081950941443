import React from "react";
import styled from "styled-components";

const HamburgerWrapper = styled.div`
    order: -1;
    @media (min-width: 576px) {
        position: static;
        flex-grow: 1;
        order: 0;
    }
`;
const Hamburger = styled.div`
    cursor: pointer;
    transition: opacity 0.5s;
    padding: 5px;
    background-color: ${({ theme: { colors } }) => colors.primaryLight};
    height: 30px;
    width: 35px;
    margin: 0 15px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    &:hover {
        opacity: 0.7;
    }

    .bar {
        width: 10px;
        height: 1px;
        background-color: white;
        margin: 1px 0;

        &:first-child {
            margin-top: 0px;
        }

        &:last-child {
            margin-bottom: 0px;
        }
    }
`;

const HambugerMenu = ({ onClick }) => (
    <HamburgerWrapper>
        <Hamburger id="hamburgerMenu" onClick={onClick}>
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
        </Hamburger>
    </HamburgerWrapper>
);

export default HambugerMenu;
