import React from "react";
import { injectIntl } from "react-intl";
import styled, { withTheme } from "styled-components";
import PublicLayout from "../../components/PublicLayout";
import { getImagePath } from "@educhain/utils/theme/getImagePath";
import { Spin } from "antd";
import { Link } from '@educhain/utils/lang';
import { resetEmail } from "../../services/api";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { removeToken } from "../../services/storage";

const Logo = styled.div`
    text-align: center;
    margin: 15px;
    img {
        height: 60px;

        @media (min-width: 768px) {
            height: 75px;
        }
    }
`;

const LoginWrapper = styled.div`
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    margin: auto;

    h1 {
        font-size: 2.15em;
    }
`;

const StyledContainer = styled.div`
    padding: 20px 24px;
    text-align: center;
`;

class ResetEmailView extends React.Component {
    state = {
        loading: false,
        tokenError: null,
        tokenIsChecked: false,
    };

    componentDidMount() {
        const { match } = this.props;
        const { token } = match.params;

        this.handleLoading(true);

        resetEmail(token)
            .then(() => {
                this.onSuccess();
            })
            .catch((error) => {
                this.onFail(error.response ? error.response.data.error : null);
            });
    }

    onSuccess() {
        this.setState({
            loading: false,
            tokenError: null,
            tokenIsChecked: true,
        });
        removeToken('tokens');
    }

    onFail(error) {
        this.setState({
            loading: false,
            tokenError: error,
            tokenIsChecked: true,
        });
    }

    handleLoading = value => {
        this.setState({ loading: value });
    };

    render() {
        const { theme, intl } = this.props;
        const { loading, tokenIsChecked, tokenError } = this.state;

        const content = tokenIsChecked
            ? (
                <Spin spinning={loading}>
                    {tokenError === null
                        ? intl.formatMessage({ id: "profile.emailChanged" })
                        : intl.formatMessage({ id: `profile.token.${tokenError}` })
                    }
                    <StyledContainer>
                        <Link to="/login">
                            {intl.formatMessage({ id: "shared.back" })}
                        </Link>
                    </StyledContainer>
                </Spin>
            ) : null;

        return (
            <PublicLayout>
                <LoginWrapper>
                    <Logo>
                        <img
                            src={getImagePath(theme.assets.logo)}
                            alt="branded logo"
                        />
                    </Logo>
                    {content}
                </LoginWrapper>
            </PublicLayout>
        );
    }
}

export default withRouter(withTheme(injectIntl(ResetEmailView)));
