import React, { useState } from "react";
import { List, Skeleton, Spin } from "antd";
import styled, { withTheme } from "styled-components";
import { FormattedMessage } from "react-intl";
import MediaFile from './MediaFile';

const StyledSkeleton = styled(Skeleton)`
    min-height: 210px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    align-items: center;
`;

const NoMediaFiles = styled.div`
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    justify-content: center;
    min-height: 210px;

    .no-credentials {
        text-align: center;
        display: inline-block;
        max-width: 500px;
        margin: 50px;
    }
`;

const MediaFileList = ({
                           mediaFiles,
    onView,
    onDownload,
    isFetching,
    theme
}) => {
    const [mediaFileId, setMediaFileId] = useState(null);

    return (
        <div className="media-files-wrapper">
            {(mediaFiles && mediaFiles.length !== 0) || isFetching ? (
                <Spin spinning={isFetching}>
                    <StyledSkeleton
                        loading={isFetching}
                        paragraph={{ rows: 6 }}
                        title={false}
                        avatar={false}
                        active
                    >
                        <List
                            grid={{
                                gutter: 16,
                                column: 4,
                                xl: 4,
                                lg: 3,
                                md: 2,
                                sm: 2,
                                xs: 1
                            }}
                            loading={isFetching}
                            dataSource={mediaFiles}
                            renderItem={mediaFile => (
                                <MediaFile
                                    key={mediaFile.id}
                                    selected={mediaFile.id === mediaFileId}
                                    setSelected={() =>
                                        setMediaFileId(mediaFile.id)
                                    }
                                    mediaFile={mediaFile}
                                    onView={onView}
                                    onDownload={onDownload}
                                />
                            )}
                        />
                    </StyledSkeleton>
                </Spin>
            ) : (
                <NoMediaFiles>
                    <span className="no-media-files">
                        <p>
                            {!!theme.brand ? (
                                <FormattedMessage
                                    id="myc.params.missing.media.brand"
                                    values={{ institution: theme.brand }}
                                />
                            ) : (
                                <FormattedMessage
                                    id={theme.missingMediaFile}
                                />
                            )}
                        </p>
                    </span>
                </NoMediaFiles>
            )}
        </div>
    );
};

export default withTheme(MediaFileList);
