import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)
// College	الكلية
// Degree	الدرجة العلمية
// Type of Credential	نوع الوثيقة
// Date of Issue	تاريخ الإصدار
// Details	التفاصيل
// Other document	الوثائق الأخرى
// Diploma	الشهادة
// Transcript	بطاقة الأعداد
// Letter	الشهادة
const resources = {
    en: {
        translation: {
            college: 'College',
            degree: 'Degree',
            type: 'Credential Type',
            dateOfIssue: 'Date of Issue',
            details: 'Details',
            other: 'Other Documents',
            diploma: 'Diploma',
            transcript: 'Transcript',
            certificate: 'Certificate',
            letter: 'Letter'
        }
    },
    ar: {
        translation: {
            college: 'الكلية',
            degree: 'الدرجة العلمية',
            type: 'نوع الوثيقة',
            dateOfIssue: 'تاريخ الإصدار',
            details: 'التفاصيل',
            other: 'الوثائق الأخرى',
            diploma: 'الشهادة',
            transcript: 'السجلات الأكاديمية',
            certificate: 'شهادة',
            letter: 'الرسائل'
        }
    }
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en',
        preload: ['en', 'ar'],
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
