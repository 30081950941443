import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Link } from "@educhain/utils/lang";
import moment from "moment";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const StyledNotification = styled.div`
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;

    h5 {
        margin: 0px;
    }

    .view {
        padding-left: 10px;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }
`;

const Notification = ({ id, notification, markAsSeen, locale }) => (
    <StyledNotification>
        <h5>
            <Link to="/" onClick={() => markAsSeen(id)}>
                {notification[locale]}
            </Link>
        </h5>
        <span className="view" onClick={() => markAsSeen(id)}>
            <CloseOutlined />
        </span>
    </StyledNotification>
);

const ShowMore = styled.div`
    padding-top: 10px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    font-size: 12px;

    &:hover {
        opacity: 0.7;
    }
`;

const NotificationList = ({
    notifications = [],
    showAll,
    onShowAll,
    markAsSeen,
    locale
}) => {
    const filtered = notifications
        .sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))
        .slice(0, showAll ? notifications.length : 3);

    return (
        <span>
            {notifications.length !== 0 ? (
                <span>
                    <div>
                        {filtered.map(obj => (
                            <Notification
                                key={obj.id}
                                {...obj}
                                markAsSeen={markAsSeen}
                                locale={locale}
                            />
                        ))}
                    </div>
                    {notifications.length > 3 && (
                        <ShowMore onClick={onShowAll}>
                            {showAll ? (
                                <FormattedMessage id="notification.hide" />
                            ) : (
                                <FormattedMessage id="notification.showMore" />
                            )}
                        </ShowMore>
                    )}
                </span>
            ) : (
                <p>
                    <FormattedMessage id="notification.noNewNotifications" />
                </p>
            )}
        </span>
    );
};

export default NotificationList;
