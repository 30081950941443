import { API_REQUEST } from "../constants";
import { getCredentialBlob as getCredentialBlobRequest } from "../../services/api";
import { BLOB_PREFIX } from "./constants";

export const loadCredentialBlob = credentialId => ({
    type: BLOB_PREFIX + API_REQUEST,
    filter: API_REQUEST,
    meta: {
        prefix: BLOB_PREFIX,
        request: () => getCredentialBlobRequest(credentialId),
        credentialId
    }
});
