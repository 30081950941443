import { Menu } from 'antd';
import { getImagePath } from '@educhain/utils/theme/getImagePath';
import React from 'react';
import styled from 'styled-components';
import { isParentUserAuthenticated } from '../helpers/user';

const UserMenuWrapper = styled.div`
    img {
        border: 2px solid ${({ theme: { colors } }) => colors.border};
        height: 20px;
        width: 20px;
        object-fit: cover;
        border-radius: 50%;
        background: ${({ theme: { colors } }) => colors.border};
        display: inline-block;
        margin-right: 10px;
    }

    .user-title {
        color: rgba(0, 0, 0, 0.65);
    }
`;

const ProfileMenuItem = ({ user, theme, onClick }) => {
    return (
        <Menu.Item onClick={() => onClick(user.username)}>
            <UserMenuWrapper>
                <img
                    src={
                        user.logoBlob ||
                        getImagePath(theme.assets.user)
                    }
                    alt=""
                />
                <span className="user-title">{user.email}</span>
            </UserMenuWrapper>
        </Menu.Item>
    );
}

const ProfileMenu = ({ user, theme, onChangeAccount }) => {
    if (!user) {
        return null;
    }

    return (
        <Menu>
            {
                isParentUserAuthenticated(user) &&
                <ProfileMenuItem key={user.parentUser.username} user={user.parentUser} theme={theme} onClick={onChangeAccount} />
            }
            {
                !!user.childrenUsers && user.childrenUsers.map((childUser) => {
                    return <ProfileMenuItem key={childUser.username} user={childUser} theme={theme} onClick={onChangeAccount} />;
                })
            }
        </Menu>
    );
}

export default ProfileMenu;
