import { USER_PREFIX, USER_IMAGE, USER_IMAGE_DELETE, USER_PASSWORD_CHANGE, USER_EMAIL_CHANGE } from './constants';
import { API_ERROR, API_REQUEST, API_SUCCESS } from '../constants';

const initialState = {
    data: {
        profile: {}
    },
    count: undefined,
    uploaded: false,
    uploading: false,
    isFetching: false,
    passwordChanged: false,
    passwordChanging: false,
    passwordChangingError: null,
    emailChanged: false,
    emailChanging: false,
    emailChangingError: null
};

export const userReducer = (state = initialState, { type, payload, meta }) => {
    switch (type) {
        case USER_PREFIX + API_REQUEST:
            return { ...state, isFetching: true };
        case USER_PREFIX + API_SUCCESS:
            return {
                ...state,
                data: { ...state.data, ...payload.data },
                count: 1,
                isFetching: false
            };
        case USER_PREFIX + API_ERROR:
            return { ...state, isFetching: false };
        case USER_IMAGE + API_REQUEST:
            return { ...state, uploading: true, uploaded: false, error: null };
        case USER_IMAGE + API_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    logoBlob: meta.image
                },
                uploading: false,
                uploaded: true,
                error: null
            };
        case USER_IMAGE + API_ERROR:
            return { ...state, uploading: false, uploaded: false, error: payload.data };
        case USER_IMAGE_DELETE + API_REQUEST:
            return { ...state, uploading: true, uploaded: false };
        case USER_IMAGE_DELETE + API_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    logoBlob: undefined
                },
                uploading: false,
                uploaded: true
            };
        case USER_IMAGE_DELETE + API_ERROR:
            return { ...state, uploading: false, uploaded: false };
        case USER_PASSWORD_CHANGE + API_REQUEST:
            return { ...state, passwordChanging: true, passwordChanged: false, passwordChangingError: null };
        case USER_PASSWORD_CHANGE + API_SUCCESS:
            return { ...state, passwordChanging: false, passwordChanged: true, passwordChangingError: null };
        case USER_PASSWORD_CHANGE + API_ERROR:
            return { ...state, passwordChanging: false, passwordChanged: false, passwordChangingError: payload.data };
        case USER_EMAIL_CHANGE + API_REQUEST:
            return { ...state, emailChanging: true, emailChanged: false, emailChangingError: null };
        case USER_EMAIL_CHANGE + API_SUCCESS:
            return { ...state, emailChanging: false, emailChanged: true, emailChangingError: null };
        case USER_EMAIL_CHANGE + API_ERROR:
            return { ...state, emailChanging: false, emailChanged: false, emailChangingError: payload.data };
        default:
            return state;
    }
};
