import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import { injectIntl } from "react-intl";

const StyleWrapper = styled.div`
    letter-spacing: 0.05em;
    font-size: 16px;
`;

const FooterWrapper = styled.footer`
    width: 100%;
    background: ${({ theme: { colors } }) => colors.primaryDark};
    color: #fff;
    padding: 18px 0;
    text-align: center;
    font-size: 0.875em;
`;
const FooterContent = styled.div`
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    ul {
        padding: 0;
        margin: 0;
        font-weight: 300;
        li {
            list-style: none;
            display: inline-block;
            margin: 0 5px;
            padding-left: 10px;
            padding-right: 10px;
            position: relative;
            a {
                color: #fff;
                display: inline-block;
                vertical-align: middle;
                line-height: 20px;
                transition: all 0.3s ease-in-out;
                text-decoration: none;

                &:hover {
                    color: ${({ theme: { colors } }) => colors.primaryLight};
                }
            }
            span {
                
                color: #fff;
                display: inline-block;
                vertical-align: middle;
                line-height: 20px;
                text-decoration: none;

            }
        }

        li:not(:last-child)::after {
        ${({ theme: { rtl } }) =>
            !rtl
                ? "content: '|'; display: inline-block; position: absolute; right: -8px; top: 0;"
                : "content: '';"}
    }

      li:not(:last-child)::before {
        ${({ theme: { rtl } }) =>
            rtl
                ? "content: '|'; display: inline-block; position: absolute; left: -8px; top: 0;"
                : "content: '';"}
    }
`;

const BaseFooter = ({ langSwitch, intl }) => {
    return (
        <StyleWrapper>
            <FooterWrapper>
                <FooterContent>
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.uaeu.ac.ae/en/website/survey.shtml"
                            >
                                {intl.formatMessage({
                                    id: "uaeuFooter.feedback"
                                })}
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.uaeu.ac.ae/en/website/privacy_policy.shtml"
                            >
                                {intl.formatMessage({
                                    id: "uaeuFooter.privacy"
                                })}
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.uaeu.ac.ae/en/website/terms_of_use.shtml"
                            >
                                {intl.formatMessage({
                                    id: "uaeuFooter.terms"
                                })}
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.uaeu.ac.ae/en/website/copyright.shtml"
                            >
                                {intl.formatMessage({
                                    id: "uaeuFooter.copy"
                                })}
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.uaeu.ac.ae/en/website/disclaimer.shtml"
                            >
                                {intl.formatMessage({
                                    id: "uaeuFooter.disclaimer"
                                })}
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.uaeu.ac.ae/en/website/customer_charter.shtml"
                            >
                                {intl.formatMessage({
                                    id: "uaeuFooter.charter"
                                })}
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.uaeu.ac.ae/en/website/sitemap.shtml"
                            >
                                {intl.formatMessage({
                                    id: "uaeuFooter.sitemap"
                                })}
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.uaeu.ac.ae/en/website/analytics.shtml"
                            >
                                {intl.formatMessage({
                                    id: "uaeuFooter.stats"
                                })}
                            </a>
                        </li>
                        <li>
                            {/* eslint-disable-next-line */}
                            <span>
                                {intl.formatMessage({
                                    id: "uaeuFooter.copyTM"
                                })}
                            </span>
                        </li>
                    </ul>
                </FooterContent>
            </FooterWrapper>
        </StyleWrapper>
    );
};

export const UAEUFooter = withTheme(injectIntl(BaseFooter));
