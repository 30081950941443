import React, { Component } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
    Input,
    Button,
    Checkbox,
    DatePicker,
    TimePicker,
    Row,
    Col
} from "antd";
import moment from "moment";
import styled from "styled-components";
import { injectIntl, FormattedMessage } from "react-intl";
import { isRTLEnabledAtPath } from "../../helpers/getRTLAndNamedPath";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { EN_LOCALE } from "@educhain/utils";

const NavWrapper = styled.div`
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    justify-content: center;

    .nav-btn {
        margin-left: 15px;
        margin-right: 15px;
    }
`;

const TextArea = Input.TextArea;

class ShareForm extends Component {
    handleSubmit = e => {
        e.preventDefault();
        const { form, onSubmit } = this.props;
        form.validateFields((err, values) => {
            if (!err) {
                onSubmit();
            }
        });
    };

    nextStep = next => {
        const { onNextStep, step, form } = this.props;
        if (next < 0) {
            onNextStep(step - 1);
        } else {
            form.validateFields((err, values) => {
                if (!err) {
                    onNextStep(step + 1);
                }
            });
        }
    };

    combineExpireDates = () => {
        const { getFieldValue } = this.props.form;
        const expiryDate = getFieldValue("expiryDate");
        const expiryTime = getFieldValue("expiryTime");
        if (expiryDate && expiryTime) {
            return {
                date: moment(expiryDate.format("YYYY-MM-DD")),
                time: moment(expiryTime)
            };
        } else {
            return null;
        }
    };

    render() {
        const {
            credentialsList,
            step = 0,
            stepSize,
            intl,
            match: {
                params: { locale }
            }
        } = this.props;
        const { getFieldDecorator, getFieldValue } = this.props.form;

        return (
            <Form layout="vertical" style={{ marginTop: 24 }}>
                <input type="password" style={{ display: "none" }} />
                {step === 0 && (
                    <span>
                        <p>
                            <FormattedMessage id="share.description1" />
                        </p>
                        <p>
                            <FormattedMessage id="share.description2" />
                        </p>
                        <Form.Item label={<FormattedMessage id="share.link" />}>
                            {getFieldDecorator("name", {
                                rules: [
                                    {
                                        required: true,
                                        message: (
                                            <FormattedMessage id="shared.specifyATitle" />
                                        )
                                    },
                                    {
                                        whitespace: true,
                                        message: (
                                            <FormattedMessage id="shared.titleNotEmpty" />
                                        )
                                    },
                                    {
                                        max: 100,
                                        message: (
                                            <FormattedMessage id="share.titleLimit" />
                                        )
                                    }
                                ]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item
                            label={
                                <FormattedMessage id="share.optionalDisplay" />
                            }
                        >
                            {getFieldDecorator("message", {
                                rules: [
                                    {
                                        required: false,
                                        max: 400,
                                        message: (
                                            <FormattedMessage id="share.messageLimit" />
                                        )
                                    }
                                ]
                            })(<TextArea rows={4} style={{ width: "100%" }} />)}
                        </Form.Item>
                    </span>
                )}

                {step === 1 && (
                    <span>
                        <Form.Item>
                            {getFieldDecorator("credentials", {
                                rules: [
                                    {
                                        validator: (rule, value, callback) => {
                                            if (value && value.length === 0) {
                                                callback(
                                                    <FormattedMessage id="share.credentialMinimum" />
                                                );
                                            }
                                            callback();
                                        }
                                    }
                                ]
                            })(
                                <Checkbox.Group style={{ width: "100%" }}>
                                    {credentialsList.map(
                                        ({ id, credentialTitles }) => (
                                            <Row
                                                key={id}
                                                style={{ paddingBottom: 10 }}
                                            >
                                                <Col span={24} flex="auto">
                                                    <Checkbox value={id}>
                                                        <strong>
                                                            {
                                                                credentialTitles[
                                                                    isRTLEnabledAtPath()
                                                                        ? locale
                                                                        : EN_LOCALE
                                                                ]
                                                            }
                                                        </strong>
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                        )
                                    )}
                                </Checkbox.Group>
                            )}
                        </Form.Item>
                    </span>
                )}

                {step === 2 && (
                    <span>
                        <Form.Item
                            label={
                                <FormattedMessage id="share.passwordProtection" />
                            }
                        >
                            {getFieldDecorator("password", {
                                rules: [
                                    {
                                        validator: (rule, value, callback) => {
                                            var isValid = /^(?=.*[A-z])(?=.*[0-9]).{8,16}$/.test(
                                                value
                                            );
                                            if (value && !isValid) {
                                                callback(
                                                    <FormattedMessage id="shared.passwordRequirement" />
                                                );
                                            }
                                            callback();
                                        }
                                    }
                                ]
                            })(
                                <Input
                                    type="password"
                                    autoComplete="new-password"
                                />
                            )}
                        </Form.Item>
                        {getFieldValue("password") && (
                            <Form.Item
                                label={
                                    <FormattedMessage id="shared.confirmPassword" />
                                }
                            >
                                {getFieldDecorator("confirmPassword", {
                                    rules: [
                                        {
                                            validator: (
                                                rule,
                                                value,
                                                callback
                                            ) => {
                                                const {
                                                    getFieldValue
                                                } = this.props.form;
                                                if (
                                                    value &&
                                                    getFieldValue(
                                                        "password"
                                                    ) !== value
                                                ) {
                                                    callback(
                                                        <FormattedMessage id="shared.passwordMatch" />
                                                    );
                                                }
                                                callback();
                                            }
                                        },
                                        {
                                            required: true,
                                            message: (
                                                <FormattedMessage id="shared.passwordMatch" />
                                            )
                                        }
                                    ]
                                })(
                                    <Input
                                        type="password"
                                        autoComplete="new-password"
                                    />
                                )}
                            </Form.Item>
                        )}

                        <Form.Item
                            label={<FormattedMessage id="share.expiryDate" />}
                        >
                            {getFieldDecorator("expiryDate", {
                                rules: [
                                    {
                                        required: false,
                                        message: (
                                            <FormattedMessage id="share.expiryDateRequirement" />
                                        )
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (
                                                value &&
                                                value.diff(moment(), "days") < 0
                                            ) {
                                                callback(
                                                    <FormattedMessage id="share.expiryPast" />
                                                );
                                            }
                                            callback();
                                        }
                                    }
                                ]
                            })(<DatePicker />)}
                        </Form.Item>
                        <Form.Item
                            label={<FormattedMessage id="share.expiryTime" />}
                        >
                            {getFieldDecorator("expiryTime", {
                                rules: [
                                    {
                                        required: false,
                                        message: intl.formatMessage({
                                            id: "shared.specifyExpiryTime"
                                        })
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            const {
                                                getFieldValue
                                            } = this.props.form;
                                            const expiryDate = getFieldValue(
                                                "expiryDate"
                                            );
                                            let date = null;

                                            // make sure dates exist
                                            if (value && expiryDate) {
                                                date = moment(
                                                    expiryDate.format(
                                                        "YYYY-MM-DD"
                                                    ) +
                                                        " " +
                                                        value.format("HH:mm:ss")
                                                );
                                            }

                                            if (
                                                date &&
                                                date.diff(moment(), "hours") < 1
                                            ) {
                                                callback(
                                                    <FormattedMessage id="share.expiryOneHour" />
                                                );
                                            }
                                            callback();
                                        }
                                    }
                                ]
                            })(<TimePicker />)}
                        </Form.Item>
                        {this.combineExpireDates() && (
                            <Form.Item>
                                {intl.formatMessage(
                                    { id: "shared.linkExpireAtTime" },
                                    {
                                        time: intl.formatTime(
                                            this.combineExpireDates().time
                                        ),
                                        date: intl.formatDate(
                                            this.combineExpireDates().date
                                        )
                                    }
                                )}
                            </Form.Item>
                        )}
                    </span>
                )}

                <div className="step-wrapper">
                    <NavWrapper>
                        {step > 0 && step !== stepSize - 1 && (
                            <Button
                                id="backButton"
                                className="nav-btn"
                                onClick={() => this.nextStep(-1)}
                            >
                                <FormattedMessage id="shared.back" />
                            </Button>
                        )}
                        {step < stepSize - 2 && (
                            <Button
                                id="nextBtn"
                                className="nav-btn"
                                onClick={() => this.nextStep(1)}
                            >
                                <FormattedMessage id="shared.next" />
                            </Button>
                        )}
                        {step === stepSize - 2 && (
                            <Button
                                id="nextBtn"
                                className="nav-btn"
                                onClick={e => this.handleSubmit(e)}
                            >
                                <FormattedMessage id="shared.finish" />
                            </Button>
                        )}
                    </NavWrapper>
                </div>
            </Form>
        );
    }
}

export default Form.create({
    name: "share_form",
    onFieldsChange(props, changedFields) {
        props.onChange(changedFields);
    },
    mapPropsToFields(props) {
        return {
            name: Form.createFormField({
                ...props.name,
                value: props.name.value
            }),
            message: Form.createFormField({
                ...props.message,
                value: props.message.value
            }),
            credentials: Form.createFormField({
                ...props.credentials,
                value: props.credentials.value
            }),
            password: Form.createFormField({
                ...props.password,
                value: props.password.value
            }),
            confirmPassword: Form.createFormField({
                ...props.confirmPassword,
                value: props.confirmPassword.value
            }),
            expiryDate: Form.createFormField({
                ...props.expiryDate,
                value: props.expiryDate.value
            }),
            expiryTime: Form.createFormField({
                ...props.expiryTime,
                value: props.expiryTime.value
            })
        };
    }
})(withRouter(injectIntl(ShareForm)));
