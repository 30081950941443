import { API_REQUEST } from "../constants";
import {
    getNotifications as getNotificationsRequest,
    markNotificationAsViewed as markNotificationAsViewedRequest
} from "../../services/api";
import { NOTIFICATIONS_PREFIX, NOTIFICATION_VIEWED } from "./constants";

export const loadNotifications = () => ({
    type: NOTIFICATIONS_PREFIX + API_REQUEST,
    filter: API_REQUEST,
    meta: {
        prefix: NOTIFICATIONS_PREFIX,
        request: () => getNotificationsRequest()
    }
});

export const markNotificationAsViewed = notificationId => ({
    type: NOTIFICATION_VIEWED + API_REQUEST,
    filter: API_REQUEST,
    meta: {
        prefix: NOTIFICATION_VIEWED,
        request: () => markNotificationAsViewedRequest(notificationId),
        notificationId
    }
});
