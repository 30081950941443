import { API_ERROR, API_REQUEST_DEFERRED, API_SUCCESS } from './constants';

export const apiResponse = (data, meta) => {
    const { prefix, success } = meta;

    return {
        type: prefix + (success ? API_SUCCESS : API_ERROR),
        filter: !success && API_ERROR,
        payload: { data },
        meta
    };
};
export const apiRequestDeferred = (action, meta) => {
    const { prefix } = meta;
    return {
        type: prefix + API_REQUEST_DEFERRED,
        filter: API_REQUEST_DEFERRED,
        payload: { action },
        meta
    };
};
