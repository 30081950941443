import React from "react";
import { Link } from "@educhain/utils/lang";
import { CheckCircleOutlined, CopyOutlined } from "@ant-design/icons";
import { Input, Button, message } from 'antd';
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CompleteWrapper = styled.div`
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    p {
        text-align: center;
    }

    .copy-block {
        margin: auto;
        max-width: 100%;
    }

    .ant-input-group-wrapper {
        margin-top: 10px;
        width: 300px;
        max-width: 100%;
    }
`;

const Success = styled.span`
    .anticon {
        font-size: 50px;
        color: rgb(82, 196, 26);
        padding: 20px;
    }
`;

const ButtonWrapper = styled.div`
    margin: auto;
    margin-top: 30px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    justify-content: space-between;

    @media (max-width: 576px) {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        button:first-child {
            margin-bottom: 25px;
        }
    }

    @media (min-width: 576px) {
        width: 400px;
    }
`;

const ShareComplete = ({
    hash,
    intl,
    match: {
        params: { locale }
    }
}) => {
    let inputRef = React.createRef();
    return (
        <CompleteWrapper>
            <Success>
                <CheckCircleOutlined />
            </Success>
            <p>
                <strong>
                    <FormattedMessage id="shared.congrats" />
                </strong>
                <br />
                <br />
                <FormattedMessage id="share.uniqueLink1" />
                <br />
                <FormattedMessage id="share.uniqueLink2" />
            </p>
            <div className="copy-block">
                <Input
                    id="shared-url-copy-input"
                    ref={inputRef}
                    value={`${window.location.origin}/${locale}/credentials/shared/${hash}`}
                    addonBefore={
                        <CopyToClipboard
                            text={`${window.location.origin}/${locale}/credentials/shared/${hash}`}
                            onCopy={() => {
                                message.success(intl.formatMessage({
                                    id: "shared.linkCopied"
                                }));
                            }}
                        >
                            <CopyOutlined style={{ cursor: "pointer" }} />
                        </CopyToClipboard>
                    }
                />
            </div>

            <ButtonWrapper>
                <Link to="/">
                    <Button className="nav-btn">
                        <FormattedMessage id="shared.returnHome" />
                    </Button>
                </Link>
                <Link to={`/credentials/shared/${hash}`} target="_blank">
                    <Button
                        id="view_shared_credentials_btn"
                        className="nav-btn"
                    >
                        <FormattedMessage id="share.viewSharedCredentials" />
                    </Button>
                </Link>
            </ButtonWrapper>
        </CompleteWrapper>
    );
};

export default withRouter(injectIntl(ShareComplete));
