import React from 'react';
import { connect } from 'react-redux';
import { isAuthenticated } from '../store/auth/selectors';
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { setLastVisitedUrl } from './lastVisitedUrl';

export default ComposedComponent => {
    class Authenticate extends React.Component {
        componentDidMount() {
            this._checkAndRedirect();
        }

        componentDidUpdate() {
            this._checkAndRedirect();
        }

        _checkAndRedirect() {
            const { isAuthenticated, navigate, location: from, match } = this.props;

            if (!isAuthenticated) {
                const location = { pathname: `/${match.params.locale}/login`, state: { from } };
                setLastVisitedUrl();
                navigate(location);
            }
        }

        render() {
            const isAuthenticated = this.props.isAuthenticated;

            return isAuthenticated ? (
                <ComposedComponent {...this.props} />
            ) : null;
        }
    }

    const mapStateToProps = state => {
        return {
            isAuthenticated: isAuthenticated(state)
        };
    };

    return withRouter(connect(mapStateToProps)(Authenticate));
};
