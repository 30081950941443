import { createSelector } from 'reselect';

export const isAuthenticated = createSelector(
    state => state.auth,
    auth => auth.isAuthenticated
);

export const error = createSelector(
    state => state.auth,
    auth => auth.error.response.data
);

export default {
    isAuthenticated,
    error
};
