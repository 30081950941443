import React, { Component } from "react";
import { connect } from "react-redux";
import MediaFileList from "./MediaFileList";
import PageContent from "../../components/PageContent";
import { FormattedMessage } from "react-intl";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import { withTheme } from "styled-components";
import { Redirect } from 'react-router-dom';
import mediaFilesSelectors from '../../store/mediaFiles/selectors';
import { loadMediaFiles } from "../../store/mediaFiles/actions";
import MediaFileViewer from './MediaFileViewer';
import CategoriesList from "../dashboard/CategoriesList";
import { getCategory } from "../../helpers/category";

class MediaFileView extends Component {
    state = {};

    componentDidMount() {
        this.loadMediaFiles();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.url !== this.props.match.url) {
            this.loadMediaFiles();
        }
    }

    loadMediaFiles() {
        const { dispatch, match, theme } = this.props;
        const category = getCategory(theme.dashboard, match);

        dispatch(loadMediaFiles(category ? category.name : null))
    }

    handleView = mediaFileId => {
        const { mediaFiles } = this.props;

        this.setState({ selectedMediaFile: mediaFiles.find(({ id }) => id === mediaFileId) });
        document.body.style.overflow = "hidden";
    };

    handleDownload = mediaFileId => {
        const { mediaFiles } = this.props;
        const mediaFile = mediaFiles.find(({ id }) => id === mediaFileId);

        if (mediaFile) {
            window.open(mediaFile.downloadUrl);
        }
    };

    handleClose = () => {
        this.setState({ selectedMediaFile: null });
        document.body.style.overflow = "auto";
    };

    render() {
        const { isFetching, mediaFiles, theme } = this.props;
        const { selectedMediaFile } = this.state;

        if (!theme.media) {
            return <Redirect to="/" />;
        }

        return (
            <>
                <PageContent
                    title={<FormattedMessage id="menu.mediaFiles" />}
                >
                    <CategoriesList />
                    <MediaFileList
                        isFetching={isFetching}
                        mediaFiles={mediaFiles}
                        onView={this.handleView}
                        onDownload={this.handleDownload}
                    />
                    <MediaFileViewer
                        onClose={this.handleClose}
                        mediaFile={selectedMediaFile}
                        onDownload={() => selectedMediaFile && this.handleDownload(selectedMediaFile.id)}
                        visible={!!selectedMediaFile && !isFetching}
                    />
                </PageContent>
            </>
        );
    }
}

const mapStateToProps = state => ({
    mediaFiles: mediaFilesSelectors.all(state),
    isFetching: mediaFilesSelectors.isFetching(state),
    isMediaFiles: mediaFilesSelectors.exists(state),
});

export default connect(mapStateToProps)(withTheme(withRouter(MediaFileView)));
