import { API_ERROR, API_REQUEST, API_SUCCESS } from '../constants';
import { NOTIFICATIONS_PREFIX, NOTIFICATION_VIEWED } from './constants';

const initialState = {
    data: [],
    isFetching: false,
    count: undefined
};

export const notificationReducer = (
    state = initialState,
    { type, payload, meta }
) => {
    switch (type) {
        case NOTIFICATIONS_PREFIX + API_REQUEST:
            return { ...state, isFetching: true };
        case NOTIFICATIONS_PREFIX + API_SUCCESS:
            return {
                ...state,
                data: payload.data,
                isFetching: false,
                count: payload.data.length
            };
        case NOTIFICATIONS_PREFIX + API_ERROR:
            return {
                ...state,
                error: payload.error,
                isFetching: false,
                count: 0
            };
        case NOTIFICATION_VIEWED + API_REQUEST:
            return { ...state, isFetching: true };
        case NOTIFICATION_VIEWED + API_SUCCESS:
            return {
                ...state,
                data: state.data.map(obj =>
                    obj.id === meta.notificationId
                        ? {
                              ...obj,
                              isViewed: true
                          }
                        : obj
                ),
                isFetching: false,
                count: payload.data.length
            };
        case NOTIFICATION_VIEWED + API_ERROR:
            return {
                ...state,
                error: payload.error,
                isFetching: false,
                count: 0
            };
        default:
            return state;
    }
};

export default notificationReducer;
