import React, { Component } from "react";
import PropTypes from "prop-types";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Col, Input, Row, Select, Switch } from "antd";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const HistorySearch = styled.div`
    margin-bottom: 30px;

    @media (max-width: 569px) {
        .spacing {
            margin-top: 25px;
        }
    }
`;

const FlexWrapper = styled.div`
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    justify-content: space-between;

    button {
        margin: auto 15px;
    }
`;

const { Option } = Select;

class Filters extends Component {
    static propTypes = {
        search: PropTypes.string,
        sort: PropTypes.string,
        onOrderDirectionChange: PropTypes.func,
        onSortChange: PropTypes.func,
        onSearchChange: PropTypes.func
    };

    static defaultProps = {
        search: "",
        sort: "created_at",
        onOrderDirectionChange: () => null,
        onSortChange: () => null,
        onSearchChange: () => null
    };

    render() {
        const {
            search,
            sort,
            order,
            onOrderDirectionChange,
            onSortChange,
            onSearchChange,
            onSearch
        } = this.props;
        return (
            <HistorySearch>
                <Row gutter={24}>
                    <Col lg={12} md={12} sm={24}>
                        <FormattedMessage id="history.searchByName">
                            {placeholder => (
                                <Input.Search
                                    placeholder={placeholder}
                                    enterButton
                                    onChange={onSearchChange}
                                    value={search}
                                    onSearch={onSearch}
                                />
                            )}
                        </FormattedMessage>
                    </Col>
                    <Col lg={12} md={12} sm={24} className="spacing">
                        <FlexWrapper>
                            <Select
                                placeholder="Sort By"
                                value={sort}
                                style={{ flexGrow: 1 }}
                                onChange={onSortChange}
                            >
                                <Option value="name">
                                    <FormattedMessage id="history.sortByName" />
                                </Option>
                                <Option value="createdAt">
                                    <FormattedMessage id="history.sortByCreation" />
                                </Option>
                                <Option value="expiredAt">
                                    <FormattedMessage id="history.sortByExpiration" />
                                </Option>
                            </Select>
                            <Switch
                                unCheckedChildren={<CaretUpOutlined />}
                                checkedChildren={<CaretDownOutlined />}
                                checked={order}
                                onChange={onOrderDirectionChange}
                            />
                        </FlexWrapper>
                    </Col>
                </Row>
            </HistorySearch>
        );
    }
}

export default Filters;
