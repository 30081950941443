import { API_REQUEST } from "../constants";
import { getMediaFiles } from "../../services/api";
import { MEDIA_FILES_PREFIX } from './constants';

export const loadMediaFiles = (category) => ({
    type: MEDIA_FILES_PREFIX + API_REQUEST,
    filter: API_REQUEST,
    meta: {
        prefix: MEDIA_FILES_PREFIX,
        request: () => getMediaFiles(category)
    }
});
