import React from "react";
import { isRTLEnabledAtPath } from "./getRTLAndNamedPath";
import { AR_LOCALE, EN_LOCALE } from "@educhain/utils";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';

const BaseComponentRTLSwitcher = ({
    comp1,
    comp2,
    children,
    match: {
        params: { locale }
    }
}) => {
    let availableLocale = isRTLEnabledAtPath() ? locale : EN_LOCALE;

    return (
        <>
            {availableLocale === AR_LOCALE ? (
                <>
                    {comp2}
                    {children}
                    {comp1}
                </>
            ) : (
                <>
                    {comp1}
                    {children}
                    {comp2}
                </>
            )}
        </>
    );
};

export const ComponentRTLSwitcher = withRouter(BaseComponentRTLSwitcher);
