import React from "react";
import { THEME_UAEU, ThemeComponentSwitcher } from "@educhain/themes/src";
import { LayoutWrapper } from "@educhain/ui/src/layout/LayoutWrapper";
import { Footer } from "@educhain/ui/src/footer/Footer";
import { Header } from "@educhain/ui/src/header/Header";
import { UAEUFooter } from "@educhain/themes/src/uaeu/components/UAEUFooter";
import { isRTLEnabled } from "@educhain/utils/lang";
import { getNamedPath } from "../services/path";
import { LanguageSelectorMenu } from "@educhain/ui/src/footer/LanguageSelector";
import { injectIntl } from "react-intl";

const components = {
    [THEME_UAEU]: UAEUFooter,
};

const PublicLayout = ({
    children,
    showHeader = false,
    intl,
    headerTitle = ""
}) => {
    return (
        <LayoutWrapper>
            {showHeader && <Header title={headerTitle} />}
            <div className="content">{children}</div>
            <ThemeComponentSwitcher
                components={components}
                defaultComponent={Footer}
                childProps={{
                    className: "footer",
                    redirectName: intl.formatMessage({
                        id: "myc.verifyCredential"
                    }),
                    redirectPath: process.env.REACT_APP_VP_BASE_URL,
                    isRTLEnabled: isRTLEnabled(getNamedPath()),
                    langSwitch: <LanguageSelectorMenu />
                }}
            />
        </LayoutWrapper>
    );
};

export default injectIntl(PublicLayout);
