import { ALERT_PREFIX, ALERT_CLEAR } from './constants';

export const alertSuccess = message => ({
    type: ALERT_PREFIX + 'success',
    message
});
export const alertWarning = message => ({
    type: ALERT_PREFIX + 'warning',
    message
});
export const alertInfo = message => ({ type: ALERT_PREFIX + 'info', message });
export const alertError = message => ({
    type: ALERT_PREFIX + 'error',
    message
});
export const alertClear = () => ({ type: ALERT_CLEAR });
