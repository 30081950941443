import React from "react";
import { Col, Progress, Row } from "antd";
import styled, { withTheme } from "styled-components";

const ProgressWrapper = styled.div`
    text-align: center;
`;

const ProgressBar = ({ steps, step, theme }) => {
    const { colors } = theme;
    return step !== steps.length - 1 ? (
        <Row>
            <Col sm={24} md={{ span: 18, offset: 3 }} flex="auto">
                <ProgressWrapper>
                    <h4>{steps[step]}</h4>
                    <Progress
                        percent={((step + 1) / steps.length) * 100}
                        showInfo={false}
                        status="active"
                        strokeColor={colors.primary}
                    />
                </ProgressWrapper>
            </Col>
        </Row>
    ) : null;
};

export default withTheme(ProgressBar);
