import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Alert } from "antd";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from 'react-intl';
import { checkPasswordResettingToken, resetPassword } from "../../services/api";
import { Link } from "@educhain/utils/lang";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';

const Wrapper = styled.div`
    max-width: 335px;

    p {
        text-align: center;
        padding: 0 0 15px 0;
    }
`;

const BaseForgotPasswordForm = ({ intl, onLoading, match }) => {
    const [response, setResponse] = useState(null);
    const [isTokenValid, setTokenValid] = useState(true);
    const [error, setError] = useState(null);
    const { token } = match.params;

    useEffect(() => {
        onLoading(true);
        checkPasswordResettingToken(token)
            .then(res => {
                setTokenValid(res);
            })
            .catch(() => {
                setTokenValid(false);
            })
            .finally(() => {
                onLoading(false);
            });
    }, [onLoading, token]);

    const onFinish = values => {
        onLoading(true);
        resetPassword(token, values.newPassword, values.newPasswordConfirmation)
            .then(res => {
                setResponse(res);
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                onLoading(false);
            });
    };

    const initialMessageOrError = error ? (
        <Alert
            message={intl.formatMessage({ id: "forgotPassword.userNotFound" })}
            type="error"
            style={{ margin: "0 0 15px 0" }}
        />
    ) : null;

    const tokenInvalidMessage = !isTokenValid ? (
        <Alert
            message={intl.formatMessage({ id: "forgotPassword.tokenIsInvalid" })}
            type="error"
            style={{ margin: "0 0 15px 0" }}
        />
    ) : null;

    if (!!response) {
        if (response.status === 200) {
            return (
                <>
                    <Alert
                        message={intl.formatMessage({
                            id: "forgotPassword.passwordUpdated"
                        })}
                        type="success"
                        style={{ margin: "50px 0" }}
                    />
                    <div style={{ textAlign: "center" }}>
                        <Link to="/login">
                            {intl.formatMessage({ id: "shared.back" })}
                        </Link>
                    </div>
                </>
            );
        } else {
            return (
                <Alert
                    message={intl.formatMessage({
                        id: "forgotPassword.serverError"
                    })}
                    type="error"
                    style={{ margin: "50px 0" }}
                />
            );
        }
    }

    return (
        <Wrapper>
            {initialMessageOrError}
            {tokenInvalidMessage}
            {tokenInvalidMessage === null
                ? <Form
                    name="forgot_password"
                    className="forgotpassword-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 24 },
                    }}
                >
                    <Form.Item
                        name="newPassword"
                        rules={[
                            () => ({
                                validator(rule, value, callback) {
                                    const isValid = /^(?=.*[A-z])(?=.*[0-9]).{8,16}$/.test(
                                        value
                                    );

                                    if (value && !isValid) {
                                        callback(
                                            <FormattedMessage id="shared.passwordRequirement" />
                                        );
                                    }

                                    callback();
                                },
                            }),
                            {
                                required: true,
                                message: `${intl.formatMessage({
                                    id: "forgotPassword.pleaseInput"
                                })}`
                            }
                        ]}
                    >
                        <Input.Password
                            placeholder={intl.formatMessage({
                                id: "profile.newPassword"
                            })}
                        />
                    </Form.Item>

                    <Form.Item
                        name="newPasswordConfirmation"
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(rule, value, callback) {
                                    if (
                                        value &&
                                        getFieldValue("newPassword") !== value
                                    ) {
                                        callback(
                                            <FormattedMessage id="profile.newPasswordMatch" />
                                        );
                                    }

                                    callback();
                                },
                            }),
                            {
                                required: true,
                                message: `${intl.formatMessage({
                                    id: "forgotPassword.pleaseInput"
                                })}`
                            }
                        ]}
                    >
                        <Input.Password
                            placeholder={intl.formatMessage({
                                id: "profile.confirmNewPassword"
                            })}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                        >
                            {intl.formatMessage({
                                id: "forgotPassword.updatePassword"
                            })}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <div>
                            <Link to="/login">
                                {intl.formatMessage({ id: "shared.back" })}
                            </Link>
                        </div>
                    </Form.Item>
                </Form>
                : null
            }
        </Wrapper>
    );
};

export const ResetPasswordForm = withRouter(injectIntl(BaseForgotPasswordForm));
