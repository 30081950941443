import {
    SHARED_CREATE,
    SHARED_CREATE_IS_ALLOWED,
    SHARED_DELETE,
    SHARED_PREFIX
} from "./constants";
import { API_REQUEST } from "../constants";
import {
    createSharing,
    deleteSharing,
    getSharings,
    getCreateSharingIsAllowed
} from "../../services/api";

export const loadShared = filters => ({
    type: SHARED_PREFIX + API_REQUEST,
    filter: API_REQUEST,
    meta: {
        prefix: SHARED_PREFIX,
        request: () => getSharings(filters)
    }
});

export const sharedCreateIsAllowedCheck = () => ({
    type: SHARED_CREATE_IS_ALLOWED + API_REQUEST,
    filter: API_REQUEST,
    meta: {
        prefix: SHARED_CREATE_IS_ALLOWED,
        request: getCreateSharingIsAllowed
    }
});

export const createShared = data => ({
    type: SHARED_CREATE + API_REQUEST,
    filter: API_REQUEST,
    meta: {
        prefix: SHARED_CREATE,
        request: () => createSharing(data),
        data // needed in the reducer if backend doesn't return it
    }
});

export const deleteShared = sharingId => ({
    type: SHARED_DELETE + API_REQUEST,
    filter: API_REQUEST,
    meta: {
        prefix: SHARED_DELETE,
        request: () => deleteSharing(sharingId),
        sharingId // needed in the reducer if backend doesn't return it
    }
});
