import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Col, Row } from "antd";
import { isEqual, omit } from "lodash";
import PageContent from "../../components/PageContent";
import { deleteShared, loadShared } from "../../store/sharing/actions";
import shareSelectors from "../../store/sharing/selectors";
import { withSize } from "@educhain/ui/src/hoc/withSize";
import Filters from "./Filters";
import HistoryResults from "./HistoryResults";
import { Spin } from "antd";
import { Skeleton } from "antd";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const StyledSkeleton = styled(Skeleton)`
    .ant-skeleton-paragraph {
        padding: 0;
    }
`;

const SpinWrapper = styled.div`
    flex-grow: 1;
`;

class HistoryView extends Component {
    state = {
        search: "",
        sort: "createdAt",
        order: true,
        page: 1,
        size: 5
    };

    pagination = {
        showSizeChanger: true
    };

    handleSearchChange = event => {
        this.setState({ search: event.target.value });
    };

    handleSharedDelete = sharingId => {
        const { dispatch } = this.props;
        dispatch(deleteShared(sharingId));
    };

    handleSortChange = value => {
        this.setState({ sort: value, page: 1 });
    };

    handleOrderChange = value => {
        this.setState({ order: !!value, page: 1 });
    };

    handlePaginationChange = (page, pageSize) => {
        this.setState({ page });
    };

    componentDidMount() {
        this.handleFilterChange();
    }

    handleFilterChange = () => {
        const { dispatch } = this.props;
        const { sort, search, order, ...filters } = this.state;
        dispatch(
            loadShared({
                ...filters,
                sort: `sharing.${sort}`,
                paginated: true,
                direction: order ? "desc" : "asc",
                filterField: search && "sharing.name",
                filterValue: search && search
            })
        );
    };

    handleOnSearch = () => {
        this.setState({ page: 1 }, this.handleFilterChange);
    };

    componentDidUpdate(_, prevState) {
        if (!isEqual(omit(prevState, "search"), omit(this.state, "search"))) {
            this.handleFilterChange();
        }
    }

    render() {
        const { search, sort, page, size, order } = this.state;
        const { shared, isFetching } = this.props;

        return (
            <PageContent title={<FormattedMessage id="history.history" />}>
                <Row>
                    <Col md={24} lg={{ span: 18, offset: 3 }}>
                        <SpinWrapper>
                            <Spin spinning={isFetching}>
                                <Filters
                                    search={search}
                                    sort={sort}
                                    order={order}
                                    onOrderDirectionChange={
                                        this.handleOrderChange
                                    }
                                    onSortChange={this.handleSortChange}
                                    onSearchChange={this.handleSearchChange}
                                    onSearch={this.handleOnSearch}
                                />

                                <StyledSkeleton
                                    paragraph={{ rows: 5 }}
                                    title={false}
                                    avatar={false}
                                    active
                                    loading={
                                        isFetching && shared.data.length === 0
                                    }
                                >
                                    <HistoryResults
                                        rows={shared.data}
                                        total={shared.totalItems}
                                        page={page}
                                        size={size}
                                        onPageChange={
                                            this.handlePaginationChange
                                        }
                                        onDelete={this.handleSharedDelete}
                                    />
                                </StyledSkeleton>
                            </Spin>
                        </SpinWrapper>
                    </Col>
                </Row>
            </PageContent>
        );
    }
}

const mapStateToProps = state => {
    return {
        shared: shareSelectors.all(state),
        isFetching: shareSelectors.isFetching(state)
    };
};

export default compose(connect(mapStateToProps), withSize)(HistoryView);
