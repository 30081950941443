import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import api from "./middleware/api";
import auth from "./middleware/auth";
import reducers from "./rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import refreshToken from "./middleware/refreshToken";

const configureStore = preloadedState => {
    const store = createStore(
        reducers,
        preloadedState,
        composeWithDevTools(
            applyMiddleware(thunkMiddleware, api, auth, refreshToken)
        )
    );

    if (module.hot && process.env.NODE_ENV === "development") {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept("./rootReducer", () => {
            store.replaceReducer(reducers);
        });
    }

    return store;
};

export default configureStore;
