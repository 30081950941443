import { createSelector } from 'reselect';

export const exists = createSelector(
    state => state.notifications.count,
    count => typeof count === 'undefined'
);
export const all = createSelector(
    state => state.notifications.data,
    data => data
);

export const unviewed = createSelector(
    state => state.notifications.data,
    notifications => notifications.filter(obj => !obj.isViewed)
);

export const viewed = createSelector(
    state => state.notifications.data,
    notifications => notifications.filter(obj => obj.isViewed)
);

export default {
    exists,
    all,
    unviewed,
    viewed
};
