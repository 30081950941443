import { API_REQUEST_DEFERRED } from "../constants";
import { refreshToken } from "../../services/api";
import { getCurrentUserToken, setToken } from "../../services/storage";
import { logout } from "../auth/actions";
import { alertError } from "../alerts/actions";
import { FormattedMessage } from "react-intl";
import React from "react";

let isRefreshing = false;
let pendingActions = [];

// expired token:  eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE1NTAyNTIyMzIsImV4cCI6MTU1MDI1NTgzMiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sInVzZXJuYW1lIjoidHJhX2ZhY3VsIn0.7fxZS0YdI-LdeikK-RtORjHSgHPE5J2anPAOBJr266vFAQzQmdlLx-3JB83PtVUyPAhM-ER66YI4gc6nVuiZ5XHEiXIC8K01Xh7DDKqs3aXPznwBM6AhMSg3qSKlDjEs5CQ5pYRq4PaQKmLM3U4L3vhsWla3ZKqANLm8-nBX_Roty9UFczCzDyDMWtCuw04pejMvrYqlo0y627w9t35n0Hez-FnZvKETnvxDj3SBvOgg7WKRspE6V5fDdVhofhQf9_dP-6c1YsV5r3KMwOgLGycXvbFYTQxkRGVUjc506Ji7d4djUtcD5ontoMHrqk6lGx_lv_56zFnVh1beHimlL6-suZcahm0IVkDOuB7ugwk7FF_kxColL1TBsJReIY57YRM2A99fGCJb8iscKQVwncqcefij5G096VOiJDFKia17666IhI2aJsI-UfU-836enICwiWNzJGVVi3i9nvsiFde-JUHTTbNTrevmjY1FVKflF4ecKMaPNZOlCkNJfRqZw264BGkQYeyKqIgFLr-94gRcjNR0r1kcYUNxT9wZiZxu3_N0uCG9PBY1MrhRwerhFrVZz0TWqKKA0Yhj-Ko0G6jp8mlnT5Ho-Xo_ay3qwxjNmiKpalTdBxjRFLzwdJzriuyTGo3wIGDbCZb-zd2NpJfWXVXKb3xeOCo2Y9Rb7TU
// example response:  {"code":401,"message":"Expired JWT Token"}

const refreshTokenMiddleware = ({ dispatch }) => next => currentAction => {
    next(currentAction); // pass action to next middleware / all reducers

    if (currentAction.filter && currentAction.filter === API_REQUEST_DEFERRED) {
        const {
            payload: { action }
        } = currentAction;
        pendingActions.push(action);
        if (!isRefreshing) {
            isRefreshing = true;
            refreshToken({ refresh_token: getCurrentUserToken("refreshTokens") })
                .then(response => {
                    const {
                        data: { token, refreshToken, username }
                    } = response;
                    setToken("tokens", username, token);
                    setToken("refreshTokens", username, refreshToken);
                    pendingActions.forEach(dispatch);
                    pendingActions = [];
                    isRefreshing = false;
                })
                .catch(error => {
                    pendingActions = [];
                    isRefreshing = false;
                    dispatch(logout());
                    dispatch(
                        alertError(<FormattedMessage id="shared.timedOut" />)
                    );
                });
        }
    }
};

export default refreshTokenMiddleware;
