import React from "react";
import PDFViewer from "./PDFViewer";
import styled from "styled-components";

const Container = styled.div`
    width: ${({ width }) => (width ? width : "100%")};
    height: ${({ height }) => (height ? height : "100%")};
`;

class ResponsivePDFViewer extends React.Component {
    state = {
        box: {}
    };

    ref = null;

    handleResize = () => {
        this.setState({
            box: this.ref.getBoundingClientRect()
        });
    };

    componentDidMount() {
        if (this.ref) {
            this.setState({ box: this.ref.getBoundingClientRect() });
        }
        window.addEventListener("resize", this.handleResize);
        window.addEventListener("orientationchange", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        window.removeEventListener("orientationchange", this.handleResize);
    }

    render() {
        const {
            files,
            onDownload,
            onPageChange,
            originalWidth,
            originalHeight,
            fileIndex
        } = this.props;
        const { width, height } = this.state.box;
        return (
            <Container
                ref={node => (this.ref = node)}
                width={originalWidth}
                height={originalHeight}
            >
                <PDFViewer
                    fileIndex={fileIndex}
                    files={files}
                    onDownload={onDownload}
                    onPageChange={onPageChange}
                    width={width}
                    height={height}
                />
            </Container>
        );
    }
}

export default ResponsivePDFViewer;
