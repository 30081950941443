import {Component} from "react"
import { withRouter } from '@educhain/utils/src/helpers/withRouter';
import qs from "querystring"
import { getCurrentUsername, setToken } from '../../services/storage';

class OauthController extends Component {
    componentDidMount(){
        const { location:{ search } } = this.props;
        const params = qs.parse(search.replace("?",""));
        const { token, refresh_token, redirectTo } = params;
        const username = getCurrentUsername();

        token && setToken("tokens", username, token)
        refresh_token && setToken("refreshTokens", username, refresh_token)

        window.location.replace(redirectTo || process.env.REACT_APP_OAUTH_REDIRECT_URL);
    }

    render(){
        return null
    }

}

export default withRouter(OauthController)
