import React, { useState } from "react";
import { Form, Input, Button, Alert } from "antd";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { forgotPassword } from "../../services/api";
import { Link } from "@educhain/utils/lang";
import { withRouter } from '@educhain/utils/src/helpers/withRouter';

const Wrapper = styled.div`
    p {
        text-align: center;
        padding: 0 0 15px 0;
    }

    .login-form-button {
        text-align: center;
    }
`;

const BaseForgotPasswordForm = ({ intl, onLoading, match }) => {
    const [response, setResponse] = useState(null);
    const [error] = useState(null);
    const { locale } = match.params;

    const onFinish = values => {
        onLoading(true);
        forgotPassword(values.username, locale)
            .then(res => {
                setResponse(res);
                onLoading(false);
            })
            .catch(err => {
                setResponse(err.response || {});
                onLoading(false);
            });
    };

    const initialMessageOrError = error ? (
        <Alert
            message={intl.formatMessage({ id: "forgotPassword.userNotFound" })}
            type="error"
            style={{ margin: "0 0 15px 0" }}
        />
    ) : (
        <p>{intl.formatMessage({ id: "forgotPassword.userOrEmail" })}</p>
    );

    if (!!response) {
        return (
            <>
                <Alert
                    message={intl.formatMessage({
                        id: "forgotPassword.success"
                    })}
                    type="success"
                    style={{ margin: "50px 0" }}
                />
                <div style={{ textAlign: "center" }}>
                    <Link to="/login">
                        {intl.formatMessage({ id: "shared.back" })}
                    </Link>
                </div>
            </>
        );
    }

    return (
        <Wrapper>
            {initialMessageOrError}
            <Form
                name="forgot_password"
                className="forgotpassword-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: `${intl.formatMessage({
                                id: "forgotPassword.pleaseInput"
                            })}`
                        }
                    ]}
                >
                    <Input
                        placeholder={intl.formatMessage({
                            id: "forgotPassword.emailOrUserInput"
                        })}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                    >
                        {intl.formatMessage({
                            id: "forgotPassword.resetPassword"
                        })}
                    </Button>
                </Form.Item>
                <Form.Item>
                    <div>
                        <Link to="/login">
                            {intl.formatMessage({ id: "shared.back" })}
                        </Link>
                    </div>
                </Form.Item>
            </Form>
        </Wrapper>
    );
};

export const ForgotPasswordForm = withRouter(injectIntl(BaseForgotPasswordForm));
