import React from "react";
import { Link } from "@educhain/utils/lang";
import { Button } from "antd";
import PageContent from "../../components/PageContent";
import styled from "styled-components";
import { getImagePath } from "@educhain/utils/theme/getImagePath";

const Error = styled.div`
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .error-description {
        font-weight: bolder;
        font-size: 3em;
        color: ${({ theme: { colors } }) => colors.primaryLight};
    }

    .home {
        padding: 15px;
    }

    img {
        margin-bottom: -40px;
        max-height: 200px;
    }
`;

const ErrorView = ({ home = true }) => (
    <PageContent>
        <Error>
            <div className="white" />
            <h1 className="error-description">Page Not Found!</h1>
            <h4>Seems like we can't find the page you're looking for.</h4>
            {home && (
                <div className="home">
                    <Link to="/">
                        <Button>Home</Button>
                    </Link>
                </div>
            )}
            <img src={getImagePath("404.svg")} alt="" />
        </Error>
    </PageContent>
);

export default ErrorView;
