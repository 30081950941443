import { combineReducers } from "redux";
import { credentialReducer } from "./credential/reducers";
import { mediaFilesReducer } from "./mediaFiles/reducers";
import { blobReducer } from "./blobs/reducers";
import { notificationReducer } from "./notifications/reducers";
import { sharingReducer } from "./sharing/reducers";
import { userReducer } from "./user/reducers";
import { alertReducer } from "./alerts/reducers";
import { authReducer } from "./auth/reducers";
import { AUTH_LOGOUT } from "./auth/constants";

const appReducer = combineReducers({
    credentials: credentialReducer,
    blobs: blobReducer,
    notifications: notificationReducer,
    auth: authReducer,
    user: userReducer,
    sharing: sharingReducer,
    alert: alertReducer,
    mediaFiles: mediaFilesReducer,
});

const rootReducer = (state, action) => {
    if (action.type === AUTH_LOGOUT) {
        state = {
            auth: {
                authCheckDone: true
            }
        };
    }

    return appReducer(state, action);
};

export default rootReducer;
